import React, { useEffect, useState } from 'react'
import { AllScriptRestart, getScriptList, getScriptRunHistory } from '../../Commoncomponet/Utils'
import { toast } from 'react-toastify'
import GoogleLoader from '../../Commoncomponet/Loaders/GoogleLoader'
import {  Lock } from 'react-feather'
import {Link} from 'react-router-dom'
import { BootstrapTooltip } from '../../Commoncomponet/common'
import { Modal, Spin } from 'antd'
import moment from 'moment'

export default function ScripList() {

	const [Scriptjson, setScriptjson] = useState([])
	const [FilterScriptData, setFilterScriptData] = useState([])
	const [isloader,setIsloader]=useState(false)
	const [TableData,setTableData]=useState([])
	const [ModalLoad,setModalLoad]=useState(false)
	const [isModalVisible,setisModalVisible]=useState(false)
	const [ModalTitle,setModalTitle]=useState('')
	const ongetScriptList = async () => {
		setIsloader(true)
		const data = await getScriptList()
		if (data.success == true) {
			setScriptjson(data?.data?.data)
			setFilterScriptData(data?.data?.data)
		setIsloader(false)
		} else {
			toast.error('Data fetch Error')
		setIsloader(false)
		}
	}

	useEffect(() => {
		ongetScriptList()
	}, [])

	const handleDropdown=(e)=>{
		const filteData = FilterScriptData && FilterScriptData.filter((item) => (e.target.value !== '' ?e.target.value=='All'?true: item?.status == e.target.value : []))
		console.log(filteData);
		setScriptjson(filteData)
	}

	const ongetScriptRunHistory=async(type,script_id,title)=>{
		setModalTitle(title)
		setisModalVisible(true)
		setModalLoad(true)
		const data=await getScriptRunHistory({type:type,script_id:script_id})
		if(data.success==true){
			setTableData(data.data.data)
		setModalLoad(false)
		}else{
		setModalLoad(false)
		}
	}
	const handleCancel=()=>{
		setModalLoad(false)
		setTableData([])
		setisModalVisible(false)
	}
	// [{Name:'amreumsgsm_createtable',time:'consumer insert and update time'},{Name:'amreumsgsm_dailyload ',time:'Daily 12:30 AM'},{Name:'amreumsgsm_downlink',time:'Device Response Time'},{Name:'amreumsgsm_events',time:'Device Response Time'},{Name:'amreumsgsm_instantaneous_snapshot',time:'Daily 12:01 AM'},{Name:'amreumsgsm_loadsurvey',time:'every hour at 31st minute'},{Name:'amreumsgsm_month_sanpshot',time:' 6:30 AM of 1st date'},{Name:'amreumsgsm_monthlybill',time:'12:15 AM of 1st date of month'},{Name:'amreumsgsm_uplink',time:'Device Response Time'},{Name:'amreumsgsm_webdecode',time:'Device Response Time'}]
	return (
		<>
		<Modal title={<div className="d-flex"><h5 >{ModalTitle}</h5></div>} maskClosable={false} className="my-modal-class" visible={isModalVisible} footer='' onCancel={handleCancel} width={1000} style={{overlay: {zIndex: 1000}}}>
				<div>
					<div className="modal-table">
					{ModalLoad?(
                                        <div className="example">
                                            <Spin size="large" />
                                        </div>
                                    ):<table className="table border table-borderless text-center" id="table-1">
							<thead style={{ position: 'sticky', top: '0', background: '#fff' }}>
								<tr>
									<th className="text-center">SR NO.</th>
									<th >Script Name</th>
									<th className="text-center">Start Time</th>
									<th>End Time</th>
									<th className="text-center">Trigger Source</th>
									<th className="text-center">Trigger By</th>
									<th className="text-center">Entry ts</th>
								</tr>
							</thead>
							<tbody id="tbody">
								<>
									{TableData.length > 0 ?
										TableData.map((data, id) => (
											<tr style={{ height: "4rem", cursor: 'pointer' }} className="trheigth even:tw-bg-gray-100 hover:tw-bg-[#D8D8D8]" key={id}>
												<td className="GSM_Link">{data?.srno}</td>
												<td>{data?.script_name}</td>
												<td>{data?.start_ts?moment.utc(data?.start_ts).format('DD-MM-YYYY hh:mm:ss A'):''}</td>
												<td>{data?.end_ts?moment.utc(data?.end_ts).format('DD-MM-YYYY hh:mm:ss A'):''}</td>
												<td>{data?.trigger_source==1?'Manual':'Schedule'}</td>
												<td>{data?.user_name}</td>
												<td>{data?.entry_ts?moment.utc(data?.entry_ts).format('DD-MM-YYYY hh:mm:ss A'):''}</td>
											</tr>
										)) : <tr></tr>}
								</>
							</tbody>
						</table>}
						{TableData.length ==0&& !ModalLoad && <h4 className="text-center">No Data Found</h4>}

					</div>
				</div>
			</Modal>
			<div className="main-content">
				<section className="section">
					<div className="section-body">
					<nav aria-label="breadcrumb">
					<ol className="breadcrumb">
						<li className="breadcrumb-item">
							<Link to="/all-dashboard">
								<i className="fas fa-tachometer-alt"></i> Home
							</Link>
						</li>
						<li className="breadcrumb-item">
							<Link >
								<Lock style={{ height: "16px" }} />Admin
							</Link>
						</li>
						<li className="breadcrumb-item">
							<Link >
								Script List
							</Link>
						</li>

					</ol>
				</nav>
						<div className="row">
							<div className="col-12">
								<div className="card">
									<div className="card-header">
										<h4 className='d-flex tw-items-center'><span className='mr-2 '>Script List</span> <BootstrapTooltip title={`Table Name is script_list`} placement='top'><span><i className='fas fa-info-circle info_circle'></i></span></BootstrapTooltip><div className='tw-w-[30%]'>
												<select
													id="inputState"
													class="tw-m-1 tw-p-2 tw-w-full tw-rounded-md"
													name="modem_name"
													onChange={(e) => handleDropdown(e)}
												style={{ width: '200px', height: '35px', padding: '0px 15px'}}

												>
													<option value={'All'}>All</option>
													<option value={1}>Active</option>
													<option value={0}>InActive</option>
												</select>
											</div></h4>
										<div className="card-header-action">
											<div id="filtergrm">

												<a class="btn btn-info text-white mr-1" data-toggle="tooltip" data-placement="bottom" title="Refresh" onClick={ongetScriptList}>
													<i class="fa fa-refresh" aria-hidden="true"></i>
												</a>
												<div className='btn btn-primary ml-2' onClick={()=>{ongetScriptRunHistory('All','','All Script History')}}><i className='fas fa-history'/></div>
												<div className='btn btn-primary ml-2' onClick={async()=>{await AllScriptRestart()}}>Run</div>
											</div>
										</div>
									</div>
									<div className="card-body">
									{isloader==true?<><div className='tw-h-screen tw-w-full tw-transition tw-ease-in-out tw-duration-300'>
                                <GoogleLoader msg={"Loading Script"} bodyClassName={'tw-h-[60%]'} />
                            </div> </>:
										<div className="table-responsive">
											<div id="table-1_wrapper" class="dataTables_wrapper no-footer">
												
												<table id="table_id" className="table border table-borderless table-striped font-10 text-center">
													<thead>
														<tr className="tw-border-b">
															<th className='text-center font-12'>No</th>
															<th className='text-center font-12'>Script Unique Id</th>
															<th className='text-center font-12'>Script Name</th>
															<th className='text-center font-12'>Start Time</th>
															<th className='text-center font-12'>Script Status</th>
															<th className='text-center font-12'>History</th>
														</tr>
													</thead>
													<tbody >
														{Scriptjson.map((item, id) => (<tr>
															<td className='text-center font-12'>{item?.srno}</td>
															<td className='text-center font-12'>{item?.unique_id}</td>
															<td className='text-center font-12'>{item?.script_name}</td>
															<td className='text-center font-12'>{item?.start_time}</td>
															<td className='text-center font-12'>{item?.status==1?<div className={`badge badge-success`}>Active</div>:<div className={`badge badge-danger`}>InActive</div>}</td>
															<td>{item?.history_maintain_status==1?<div className='btn btn-primary ml-2' onClick={()=>{ongetScriptRunHistory('Individual',item?.unique_id,item?.script_name)}}><i className='fas fa-history'/></div>:'-'}</td>
														</tr>))}
													</tbody>
												</table>
											</div>
										</div>
}
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		</>
	)
}
