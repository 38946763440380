import React from 'react';
import { Modal, Button, ProgressBar, Alert } from 'react-bootstrap';

const ExcelGenerationModal = ({
    showSuccessModal,
    closeSuccessModal,
    isLoading,
    progress,
    status,
    error,
    fileType,
    onCancel 
}) => {

    
    return (
        <>
            {/* Success Modal */}
            <Modal aria-labelledby="contained-modal-title-vcenter" show={showSuccessModal} onHide={closeSuccessModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" >Success!</Modal.Title>
                </Modal.Header>
                {
                    showSuccessModal &&
                    <Modal.Body>
                        Your {fileType} file has been generated successfully.
                    </Modal.Body>
                }

                <Modal.Footer>
                    <Button variant="primary" onClick={closeSuccessModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Loading Modal */}
            <Modal onHide={onCancel} aria-labelledby="contained-modal-title-vcenter" show={isLoading}  keyboard={false} centered> 
                <Modal.Header closeButton >
                    <Modal.Title id="contained-modal-title-vcenter" >Generating ...</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        isLoading && <p>Please wait while your {fileType} file is being generated <b>{progress} %</b>.</p>
                    }
                    
                    <ProgressBar now={progress} className="mb-2" />
                    <p className="text-center">{status}</p>
                </Modal.Body>
            </Modal>

            {/* Error Alert */}
            {error && (
                <Alert variant="danger" className="mt-4">
                    {error}
                </Alert>
            )}
        </>
    );
};

export default ExcelGenerationModal;