
import React, { useEffect, useRef, useState } from 'react'
import { CSVLink } from 'react-csv'
import { Users } from 'react-feather'
import { Link, useHistory, useParams } from 'react-router-dom'
import { BootstrapTooltip } from '../../Commoncomponet/common'
import * as $ from "jquery";
import * as XLSX from 'xlsx';
import dt from "datatables.net-dt";
import jsPDF from "jspdf";
import { activeandinactive, getAccount, getCategoryNameByUniqId, getCircleById, getDivisionById, getGaById, getProfilNameByAccountId, getRolesById, getSubdevision } from '../../Commoncomponet/Utils'
import { Modal } from 'antd'
import moment from 'moment'
$.fn.DataTable = dt;
export default function Subdevision() {
	const [SubdevisionData, setSubdevisionData] = useState([])
	const [filterrolesData, setfilterRolesData] = useState([])
	const [permision, setpermision] = useState({})
	const userInfo = JSON.parse(window.localStorage.getItem('user_info'))
	const [loader, setLoader] = useState(false)
	const [Allpermision, setAllpermision] = useState({})
	const [roleFilter, setRoleFilter] = useState({ name: '', account: '', status: '', ga: '', circle: '', division: '' })
	const [AccounData, setAccountData] = useState([])
	const [GaData, setGaData] = useState([])
	const [CircleData, setCircleData] = useState([])
	const [DivisionData, setDivisionData] = useState([])
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [profileName, setprofileName] = useState({})

	const params = useParams()

	const history = useHistory()
	const ongetRolesUserData = async () => {
		setLoader(true);
		$(".loader").fadeOut("slow");
		const data = await getSubdevision({ search: true, CATEGORY0: params.accountId, CATEGORY1: params.gaId, CATEGORY2: params.circleId, CATEGORY3: params.divisionId })
		if (data.success === true) {
			setSubdevisionData(data.data.data)
			setfilterRolesData(data.data.data)
		} else {
			console.log('somthing want wrong')
			setLoader(false)
		}
	}
	const onHandaleRoles = async () => {
		const data = await getRolesById(userInfo?.ROLE)
		if (data.success === true) {
			setpermision(JSON.parse(data.data.data[0].ROLES_PERMISSIONS_CATEGORY4 || '{}'))
			const obj = data.data.data[0]
			const Roles = { ...obj, ROLES_PERMISSIONS_CATEGORY0: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY0), ROLES_PERMISSIONS_CATEGORY5: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY5), ROLES_PERMISSIONS_CATEGORY2: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY2), ROLES_PERMISSIONS_CATEGORY3: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY3), ROLES_PERMISSIONS_CATEGORY1: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY1), ROLES_PERMISSIONS_CATEGORY4: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY4) }
			setAllpermision(Roles)
		} else {
			console.log('something want wrong')
		}
	}

	const ongetCategoryNameByUniqId = async () => {
		const data = await getCategoryNameByUniqId({ CATEGORY0: params.accountId, CATEGORY1: params.gaId, CATEGORY2: params.circleId, CATEGORY3: params.divisionId })
		if (data.success === true) {
			setprofileName(data.data.data)
		} else {
			console.log('somthing want wrong')
		}
	}

	const [NameObj, setNameObj] = useState({})

	const ongetProfilNameByAccountId = async () => {
		const data = await getProfilNameByAccountId({ ID: params.accountId })
		if (data.success === true) {
			setNameObj(JSON.parse(data?.data?.data[0]?.PROFILE_NAME || '{}'))
		} else {
			console.log('something want wrong')
		}
	}
	useEffect(() => {
		onHandaleRoles()
		ongetCategoryNameByUniqId()
		ongetProfilNameByAccountId()
		ongetRolesUserData().then(() =>
			setTimeout(function () {
				$("#table-1").DataTable({ "pageLength": 50, order: [[0, 'desc']] });
				setLoader(false);

			}, 700)
		);
	}, []);


	const UpdateRolesUser = (item) => {
		history.push({ pathname: `/add-subdivision/${params?.accountId}/${params?.gaId}/${params?.circleId}/${params?.divisionId}`, state: { ...item, subdivision_edit: permision.subdivision_edit, subdivision_active: permision.subdivision_active, Allpermision: Allpermision } })
	}

	const handleCancel = () => {
		setIsModalVisible(false);
	};

	const onhandleOk = () => {
		setIsModalVisible(false)
		if (roleFilter.name === '' && roleFilter.status === '' && roleFilter.account === '' && roleFilter.ga === '') {
			return setSubdevisionData(filterrolesData)
		}
		const filteData = filterrolesData && filterrolesData.filter((item) => (roleFilter.name !== '' ? item?.CATEGORY4_NAME?.includes(roleFilter.name) : []) && (roleFilter.account !== '' ? item?.CATEGORY0 == roleFilter.account : []) && (roleFilter.ga !== '' ? item?.CATEGORY1 == roleFilter.ga : []) && (roleFilter.status !== '' ? item?.CATEGORY4_STATUS == roleFilter.status : []) && (roleFilter.circle !== '' ? item?.CATEGORY2 == roleFilter.circle : []) && (roleFilter.division !== '' ? item?.CATEGORY3 == roleFilter.division : []))
		setSubdevisionData(filteData)
	}
	const onHandaleSelection = async () => {
		const data = await getAccount()
		if (data.success === true) {
			setAccountData(data.data.data)
		} else {
			console.log('somthing want wrong')
		}
	}
	const ongetGaById = async (id) => {
		const data1 = await getGaById(id)
		if (data1.success === true) {
			setGaData(data1.data.data)
		}
	}
	const ongetCircle = async (id) => {
		const data1 = await getCircleById(id)
		if (data1.success === true) {
			setCircleData(data1.data.data)
		}
	}
	useEffect(() => {
		if (roleFilter.account !== '') {
			ongetGaById(roleFilter.account)
		} else {
			setGaData([])
			setCircleData([])
		}
	}, [roleFilter.account])

	useEffect(() => {
		if (roleFilter.ga !== '') {
			ongetCircle(roleFilter.ga)
		} else {
			setCircleData([])
		}
	}, [roleFilter.ga])
	const ongetDivisionById = async (id) => {
		const data3 = await getDivisionById(id)
		console.log(data3)

		if (data3.success === true) {
			setDivisionData(data3.data.data)
		}
	}
	useEffect(() => {
		if (roleFilter.circle !== '') {
			ongetDivisionById(roleFilter.circle)
			// serAccountInput({...AccountInput,division:''})
		} else {
			//    serAccountInput({...AccountInput,division:''})
			setDivisionData([])
		}
	}, [roleFilter.circle])

	const csvLink = useRef();
	const [csvData, setCsvData] = useState([])

	const downloadReport = async (isCsv) => {
		if (SubdevisionData.length > 0) {
			const finalCsvData = await SubdevisionData.map((item) => ({ 'SRNO': item.SRNO, 'Unique ID': item.CATEGORY4_UNIQUE_ID, 'Created Time': moment(item.CATEGORY4_ADDED_TIME).format('DD-MM-YYYY'), 'Created By': item.CATEGORY4_ADDED_BY, 'Account': item.ACCOUNT_NAME, 'GA': item.GA_NAME, 'Circle': item.CIRCLE_NAME, 'Division': item.DIVISION_NAME, 'Sub Division': item.CATEGORY4_NAME, 'status': (item.CATEGORY4_STATUS === 1 ? 'Active' : 'InActive') }))
			setCsvData(finalCsvData)
			if (isCsv === true) {
				if (finalCsvData.length > 0) {
					csvLink.current.link.click();
				}
			} else {
				if (finalCsvData.length > 0) {
					const worksheet = XLSX.utils.json_to_sheet(finalCsvData);
					const workbook = XLSX.utils.book_new();
					XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
					XLSX.writeFile(workbook, `Sub-division-data.xlsx`);
				}
			}
		}
	}

	const [editFlage, setEditFlage] = useState(false)

	const onSubmitName = async () => {
		const data = await activeandinactive({ tableName: 'category0', colName: 'PROFILE_NAME', value: `'${JSON.stringify(NameObj)}'`, matchId: 'UNIQUE_ID' }, params.accountId)
		if (data.success === true) {
			ongetProfilNameByAccountId()
			setEditFlage(false)
		} else {
		}
	}

	const TableTrMaker=(item,id)=>{
		return <tr style={{ height: "4rem" }} className="trheigth hover:tw-bg-[#D8D8D8]" key={id} onMouseEnter={() => {
			var element = document.getElementById(item?.SRNO + 'GSM');
			element.classList.add('within-icon-sub');
			element.classList.remove('display-icon');
		}} onMouseLeave={() => {
			var element = document.getElementById(item?.SRNO + 'GSM');
			element.classList.add('display-icon');
			element.classList.remove('within-icon-sub');
		}}>
			<td className="text-center GSM_Link">{item?.SRNO}<Link id={item?.SRNO + 'GSM'} className='display-icon' to={`/area/${item?.CATEGORY0}/${item?.CATEGORY1}/${item?.CATEGORY2}/${item?.CATEGORY3}/${item?.CATEGORY4_UNIQUE_ID}`}><i className='fas fa-external-link-alt ' ></i></Link></td>
			<td><b>Account</b>: {item.ACCOUNT_NAME}<br /><b>{NameObj?.CATEGORY1 || 'CATEGORY1'}</b>: {item.GA_NAME}<br />
				<b>{NameObj?.CATEGORY2 || 'CATEGORY2'}</b>: {item.CIRCLE_NAME}<br />

				<b>{NameObj?.CATEGORY3 || 'CATEGORY3'}</b>: {item.DIVISION_NAME}</td>
			<td>{item?.CATEGORY4_NAME}</td>

			<td>
				<div className={`badge ${item?.CATEGORY4_STATUS == 1 ? 'badge-success' : 'badge-danger'} badge-shadow text-white`}>{item.CATEGORY4_STATUS == 1 ? 'Active' : 'Inactive'}</div>
			</td>
			<td className="text-center"><div class="btn btn-info text-white btnedit mr-2" onClick={() => (UpdateRolesUser(item))}><BootstrapTooltip title='edit'>
				<span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Edit" >
					<i class="fas fa-edit  text-white"></i>
				</span></BootstrapTooltip>
			</div></td>
		</tr>
	}
	return (
		<>
			<Modal title="Filter Division" visible={isModalVisible} footer="" onCancel={handleCancel} width={1000}>
				<div class="form-row">
					<div class="form-group col-md-4">
						<label for="inputEmail4">Account:</label>
						<select class="form-control form-select" name="accountname" id="accountname" onChange={(e) => (setRoleFilter({ ...roleFilter, account: e.target.value }))} value={roleFilter?.account} >
							<option value="">Select Account</option>
							{AccounData && AccounData.map((item) => (Allpermision?.ROLES_PERMISSIONS_CATEGORY0?.CATEGORY0_view?.filter((items) => (items === item.UNIQUE_ID)).length > 0 || userInfo?.ROLE === 'ROLE1650614500708' ? <option value={item?.UNIQUE_ID}>{item?.CATEGORY0}</option> : ''))}
						</select>
					</div>
					<div class="form-group col-md-4">
						<label for="inputEmail4">Circle:</label>
						<select class="form-control form-select" name="accountname" id="accountname" onChange={(e) => (setRoleFilter({ ...roleFilter, ga: e.target.value }))} value={roleFilter?.ga} >
							<option value="">Select GA</option>
							{GaData && GaData.map((item) => (Allpermision?.ROLES_PERMISSIONS_CATEGORY1?.CATEGORY1_view?.filter((items) => (items === item.CATEGORY1_UNIQUE_ID)).length > 0 || userInfo?.ROLE === 'ROLE1650614500708' ? <option value={item.CATEGORY1_UNIQUE_ID}>{item.CATEGORY1_NAME}</option> : ''))}
						</select>
					</div>
					<div class="form-group col-md-4">
						<label for="inputEmail4">Circle:</label>
						<select class="form-control form-select" name="accountname" id="accountname" onChange={(e) => (setRoleFilter({ ...roleFilter, circle: e.target.value }))} value={roleFilter?.circle} >
							<option value="">Select Circle</option>
							{CircleData && CircleData.map((item) => (Allpermision?.ROLES_PERMISSIONS_CATEGORY2?.CATEGORY2_view?.filter((items) => (items === item.CATEGORY2_UNIQUE_ID)).length > 0 || userInfo?.ROLE === 'ROLE1650614500708' ? <option value={item.CATEGORY2_UNIQUE_ID}>{item.CATEGORY2_NAME}</option> : ''))}
						</select>
					</div>
					<div class="form-group col-md-4">
						<label for="inputEmail4">Division:</label>
						<select class="form-control form-select" name="accountname" id="accountname" onChange={(e) => (setRoleFilter({ ...roleFilter, division: e.target.value }))} value={roleFilter?.division} >
							<option value="">Select division</option>
							{DivisionData && DivisionData.map((item) => (Allpermision?.ROLES_PERMISSIONS_CATEGORY3?.CATEGORY3_view?.filter((items) => (items === item.CATEGORY3_UNIQUE_ID)).length > 0 || userInfo?.ROLE === 'ROLE1650614500708' ? <option value={item.CATEGORY3_UNIQUE_ID}>{item.CATEGORY3_NAME}</option> : ''))}
						</select>
					</div>
					<div class="form-group col-md-4">
						<label for="inputState">Sub Division Name:</label>
						<input type="text" class="form-control" id="" onChange={(e) => (setRoleFilter({ ...roleFilter, name: e.target.value }))} value={roleFilter.name} name="FirstName" placeholder="Enter Circle Name" />
					</div>
					<div class="form-group col-md-4">
						<label for="inputPassword4">Sub Division Status:</label>
						<select
							id="inputState"
							class="form-select form-control"
							name="modem_name"
							onChange={(e) => (setRoleFilter({ ...roleFilter, status: e.target.value }))}
							value={roleFilter.status}
						>
							<option value="">Select Status</option>
							<option value={1}>Active</option>
							<option value={0}>InActive</option>
						</select>
					</div>
				</div>
				<div class="text-left mt-2">
					<input type="submit" class="btn btn-danger" value="Filter" name="filter_customer_form" onClick={onhandleOk} />
					<Link class="btn btn-light text-dark ml-2" to={`/subdivision`}>
						Reset
					</Link>
				</div>
			</Modal>
			<div className="main-content">
				{loader ? (
					<div className="main-content">
						<div class="loader"></div>
					</div>
				) : (
					""
				)}
				<nav aria-label="breadcrumb">
					<ol className="breadcrumb">
						<li className="breadcrumb-item">
							<Link to="/group-dashboard">
								<i className="fas fa-tachometer-alt"></i> Home
							</Link>
						</li>
						<li className="breadcrumb-item">
							<Link>
								<Users style={{ height: "16px" }} /> Profile
							</Link>
						</li>
						<li className="breadcrumb-item">
							<Link to={'/account'}>
								{profileName?.ACCOUNT_NAME}
							</Link>
						</li>
						<li className="breadcrumb-item">
							<Link to={`/ga/${params?.accountId}`}>
								{profileName?.GA_NAME}

							</Link>
						</li>
						<li className="breadcrumb-item">
							<Link to={`/circle/${params?.accountId}/${params?.gaId}`}>
								{profileName?.CIRCLE_NAME}

							</Link>
						</li>
						<li className="breadcrumb-item">
							<Link to={`/division/${params?.accountId}/${params?.gaId}/${params?.circleId}`}>
								{profileName?.DIVISION_NAME}

							</Link>
						</li>
						<li className="breadcrumb-item">
							<Link >
								{NameObj?.CATEGORY4 || 'CATEGORY4'}

							</Link>
						</li>
					</ol>
				</nav>
				<section className="section">
					<div className="section-body">
						<div className="row">
							<div className="col-12">
								<div className="card">
									<div className="card-header d-flex" style={{ justifyContent: 'space-between' }}>
										<div className='d-flex'>{editFlage === false ? <><h4>{NameObj?.CATEGORY4 || 'CATEGORY4'}</h4>{permision?.EDIT_NAME === true || userInfo?.ROLE === "ROLE1650614500708" ? <div class="btn btn-info text-white btnedit mr-2" onClick={() => (setEditFlage(!editFlage))}><BootstrapTooltip title='edit'>
											<span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Edit" >
												<i class="fas fa-edit  text-white"></i>
											</span></BootstrapTooltip>
										</div> : ''}<BootstrapTooltip title={`Table Name is category4`} placement='top'><span><i className='fas fa-info-circle info_circle'></i></span></BootstrapTooltip></> : <div class="form-row "><div class="col-md-12 d-flex">
											<input type="text" class="form-control" name="account_name" placeholder="Enter Account Name" required="" onChange={(e) => (setNameObj({ ...NameObj, CATEGORY4: e.target.value }))} value={NameObj?.CATEGORY4} />
											<a class={`btn btn-danger text-dark`} onClick={onSubmitName}>submit</a>
										</div></div>}</div>
										<div className="card-header-action">
											{permision.CATEGORY4_create === true || userInfo?.ROLE === "ROLE1650614500708" ? <Link to={`/add-subdivision/${params?.accountId}/${params?.gaId}/${params?.circleId}/${params?.divisionId}`} className="btn btn-info text-white mr-2">
												<BootstrapTooltip title="Add">
													<span>
														<i
															className="fas fa-plus text-white"
															style={{ fontSize: "16px", paddingLeft: "5px", paddingRight: "5px" }}
														></i>
													</span>
												</BootstrapTooltip>
											</Link> : ''}
											<button type="button" className="btn btn-info mr-2" onClick={() => {
												setIsModalVisible(true)
												onHandaleSelection()
											}}>
												<BootstrapTooltip title="Filter">
													<span>
														<i className="fa fa-filter text-white"></i>
													</span>
												</BootstrapTooltip>
											</button>
											<div className="dropdown d-inline mr-2">
												{permision.CATEGORY5_export === true || userInfo?.ROLE === "ROLE1650614500708" ? <button
													className="btn btn-info dropdown-toggle mr-2"
													type="button"
													id="dropdownMenuButton3"
													data-toggle="dropdown"
													aria-haspopup="true"
													aria-expanded="false"
													data-placement="bottom"
													title="Download"
												>
													<i className="fa fa-download mr-1"></i>
												</button> : ''}
												<div className="dropdown-menu">
													<a className="dropdown-item" >
														PDF
													</a>
													<a onClick={() => downloadReport(false)} className="dropdown-item" >
														Excel
													</a>
													<a onClick={() => downloadReport(true)} className="dropdown-item">
														CSV
													</a>
													<CSVLink data={csvData} filename={"sub-division-data.csv"} ref={csvLink} />
												</div>
											</div>
										</div>
									</div>
									<div className="card-body">
										<div className="table-responsive">
											<table className="table border stripe" id="table-1">
												<thead>
													<tr>
														<th className="text-center !tw-font-semibold" style={{ maxWidth: "100px" }}>Unique No</th>
														<th className='!tw-font-semibold'>Parent</th>
														<th className='!tw-font-semibold'>Sub Division Name</th>
														<th className='!tw-font-semibold' style={{ maxWidth: "100px" }}>Status</th>
														<th className='!tw-font-semibold text-center'>Edit</th>

													</tr>
												</thead>
												<tbody>
													{SubdevisionData &&
														SubdevisionData.map((item, id) => (
															userInfo?.ROLE === 'ROLE1650614500708' ? <tr style={{ height: "4rem" }} className="trheigth hover:tw-bg-[#D8D8D8]" key={id} onMouseEnter={() => {
																var element = document.getElementById(item?.SRNO + 'GSM');
																element.classList.add('within-icon-sub');
																element.classList.remove('display-icon');
															}} onMouseLeave={() => {
																var element = document.getElementById(item?.SRNO + 'GSM');
																element.classList.add('display-icon');
																element.classList.remove('within-icon-sub');
															}}>
																<td className="text-center GSM_Link">{item?.SRNO}<Link id={item?.SRNO + 'GSM'} className='display-icon' to={`/area/${item?.CATEGORY0}/${item?.CATEGORY1}/${item?.CATEGORY2}/${item?.CATEGORY3}/${item?.CATEGORY4_UNIQUE_ID}`}><i className='fas fa-external-link-alt ' ></i></Link></td>
																<td><b>Account</b>: {item.ACCOUNT_NAME}<br /><b>{NameObj?.CATEGORY1 || 'CATEGORY1'}</b>: {item.GA_NAME}<br />
																	<b>{NameObj?.CATEGORY2 || 'CATEGORY2'}</b>: {item.CIRCLE_NAME}<br />

																	<b>{NameObj?.CATEGORY3 || 'CATEGORY3'}</b>: {item.DIVISION_NAME}</td>
																<td>{item?.CATEGORY4_NAME}</td>

																<td>
																	<div className={`badge ${item?.CATEGORY4_STATUS == 1 ? 'badge-success' : 'badge-danger'} badge-shadow text-white`}>{item.CATEGORY4_STATUS == 1 ? 'Active' : 'Inactive'}</div>
																</td>
																<td className="text-center"><div class="btn btn-info text-white btnedit mr-2" onClick={() => (UpdateRolesUser(item))}><BootstrapTooltip title='edit'>
																	<span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Edit" >
																		<i class="fas fa-edit  text-white"></i>
																	</span></BootstrapTooltip>
																</div></td>
															</tr> :
															permision?.CATEGORY4_view.length>0?permision?.CATEGORY4_view?.filter((items) => (items === item.CATEGORY4_UNIQUE_ID)).length > 0?(item?.CATEGORY0?.includes(params.accountId) && item?.CATEGORY1?.includes(params.gaId) && item?.CATEGORY2?.includes(params.circleId) && item?.CATEGORY3?.includes(params.divisionId)) ?  TableTrMaker(item,id):'': '':TableTrMaker(item,id)
														))}
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		</>
	)
}

