import React, { useEffect, useRef } from 'react'
import { useState } from 'react'
import { UpdateModemFirmwarOTA, geModemIdWiseColumnName, getConfigretionCMD_IDANDTYPEWiseData, getConfigretionCMD_TYPEWiseData, getConfigretionCMD_TYPEWisePendding_Data_buffer, getConfigretionLetestDataByCmd_id, getConfigretionStatus, getHeatbeatLatestVDID, getLastGSMOTAData, getPenddingqueueCommandInBuffer, getScriptCodeIdWiseColumnName, getScriptCodeIdWiseConfigretionData, getScriptCodeIdWiseConfigretionQuery, onModemConfigByGSMConsumer } from '../../../../Commoncomponet/Utils'
import swal from 'sweetalert2';
import { DatePicker, Modal, Spin, TimePicker, InputNumber, Switch } from 'antd';
import moment from 'moment';
import useInterval from 'use-interval';
import Select from 'react-select';
import Pagination from '../../../../Commoncomponet/Pagination';
import { BootstrapTooltip, socket } from '../../../../Commoncomponet/common';
import clockPending from '../../../../assets/img/icons8-clock.gif'

window.Swal = swal;
export default function ModemConfigretion(props) {
    // {CMD:3,MSGID:new Date().valueOf(),CMD_TYPE:'HEARTBEAT_INTERVAL'},{CMD:4,MSGID:new Date().valueOf(),CMD_TYPE:"INSTANTANEOUS_INTERVAL"}
    const [Command, setCommand] = useState([])
    const [status, setStatus] = useState([])
    const [GetCmd, setGetCmd] = useState({})
    const [cmdValue, setCmdvalue] = useState({})
    const [CommandType, setCommandType] = useState('')
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModalVisible1, setIsModalVisible1] = useState(false);
    const [modal, setModal] = useState(false)
    const [MqttMsg, setMqttMsg] = useState('')
    const [time, setTime] = useState(60);
    const [run, setRun] = useState(false);
    const [modalMsg, setmodalMsg] = useState(false)
    const [FlagMsg, setFlagMsg] = useState(false)
    const [modelTitle, setmodelTitle] = useState('')
    const [modelID, setmodelID] = useState('')

    const [DetailConfig, setDetailConfig] = useState([])
    const [Lastfirmwarversion, setLastfirmwarversion] = useState({})
    const [modalDetails, setmodalDetails] = useState(false)
    const [PageSize, setPageSize] = useState(50)
    const [numberofPage, setnumberofPage] = useState(1)
    const [currentPage, setCurrentPage] = useState(1);
    const [BufferPageSize, setBufferPageSize] = useState(50)
    const [BuffernumberofPage, setBuffernumberofPage] = useState(0)
    const [BuffercurrentPage, setBufferCurrentPage] = useState(1);
    const [BufferPenddingData, setBufferPenddingData] = useState([])
    const [modalBufferDetails, setmodalBufferDetails] = useState(false)
    const [modalUI, setmodalUI] = useState(false)
    const [ShowLetestData, setShowLetestData] = useState({})
    const [cmdRefreshLoader, setcmdRefreshLoader] = useState(false)
    const [BufferQueue, setBufferQueue] = useState({})
    const ongetScriptCodeIdWiseConfigretionData = async () => {
        var commadArray = []
        var objLetest = {}
        const data = await getScriptCodeIdWiseConfigretionQuery({ SID: props?.GSMdata?.SCRIPTDECODE_ID, col: 'MODEM_CONFIG' })
        if (data.success === true) {
            var CMD = JSON.parse(data?.data?.data[0]?.MODEM_CONFIG || '{}')
            if (Object.keys(CMD).length == 0) {
                CMD = { "Get All Parameter": { "CMDID": 401, "CMD_TYPE": "Read", "buttons": { "Get": null } }, "EVC Type": { "CMDID": 409, "CMD_TYPE": "Read", "buttons": { "Get": null, "Set": { "VD": { "type": "INT", "desc": "VD" } } }, "Color": ["#0dcaf0", "#ffa426", "#6777ef"] } }
            }
            setStatus(CMD)
            let entries = Object.entries(CMD)
            var commadArray = []
            var CommandTypeAndID = []
            entries.map(([key, val]) => {
                CommandTypeAndID.push({ CMD_TYPE: val?.CMD_TYPE, CMDID: val?.CMDID })
                objLetest[val?.CMD_TYPE + val?.CMDID] = []
                commadArray.push({ ...val, CMD_TITLE: key })
                console.log('key, val', key, val);
            });
            setShowLetestData(objLetest)
            setCommand(commadArray)
            getPenddingqueueCommand(CommandTypeAndID)
        }
    }

    const getPenddingqueueCommand = async (CommandTypeAndID) => {
        const data = await getPenddingqueueCommandInBuffer({ tableName: props?.GSMdata?.UNIQUE_TABLE_NAME?.gsmbuffer, commandList: CommandTypeAndID })
        if (data.success == true) {
            setBufferQueue(data?.data?.data)
        }
    }
    const ongetLastGSMOTAData = async () => {
        const data = await getLastGSMOTAData(props?.GSMdata?.UNIQUE_ID)
        if (data.success == true) {
            setLastfirmwarversion(data?.data?.data[0] || {})
        }
    }

    const ArrayOfObject = {
        "CMDID": 504,
        "MMD_TS": 1674492622,
        "MMD_ID_VD": 3,
        "MSGID": "1674472965038",
        "MMD_ID_DRXTYPE": 1,
        "IMEI": "867542059710353",
        "POTP": "1234",
        "COTP": "5678",
        "MMD_ID_VERSTR": "CG4GC4REM1BSW_1.0.0",
        "MMD_STATUS_MSGDIR": 0,
        "CMD_TYPE": "SCHEDULE_EVENT",
        "MMD_CNT_SCH_CFG": 2,
        "SCHEDULES": [{
            "MMD_CFG_SCH_UID": 1668486090102,
            "MMD_CFG_SCH_FLAG": 0,
            "MMD_CFG_SCH_WEEK": 127,
            "MMD_CFG_SCH_START_HH": 10,
            "MMD_CFG_SCH_START_MM": 10,
            "MMD_CFG_SCH_END_HH": 11,
            "MMD_CFG_SCH_END_MM": 20,
            "MMD_STATUS_LOAD1": 1,
            "MMD_STATUS_LOAD2": 1,
            "MMD_STATUS_LOAD3": 1,
            "MMD_STATUS_DIMVAL": 0,
            "MMD_CFG_SCH_MONTH_OFFSET_MIN": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        }, {
            "MMD_CFG_SCH_UID": 1674219814123,
            "MMD_CFG_SCH_FLAG": 0,
            "MMD_CFG_SCH_WEEK": 127,
            "MMD_CFG_SCH_START_HH": 18,
            "MMD_CFG_SCH_START_MM": 0,
            "MMD_CFG_SCH_END_HH": 9,
            "MMD_CFG_SCH_END_MM": 10,
            "MMD_STATUS_LOAD1": 1,
            "MMD_STATUS_LOAD2": 1,
            "MMD_STATUS_LOAD3": 1,
            "MMD_STATUS_DIMVAL": 0,
            "MMD_CFG_SCH_MONTH_OFFSET_MIN": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        }],
        "SCRIPTDECODE_ID": "MODUID1672724470002_METEID1672724414727",
        "UNIQUE_ID": "G41673860745230"
    }
    var [CommandJson, setCommandJson] = useState({})
    var [CommandmsgJson, setCommandmsgJson] = useState({})
    const [CommandStatus, setCommandStatus] = useState({})
    const [CommandStaticmsgJson, setCommandStaticmsgJson] = useState({})
    const [Schedule, setSchedule] = useState({})
    const [modalAddSchedules, setmodalAddSchedules] = useState(false)
    const [ModalSche, setModalSche] = useState(false)

    var [CmdJsonInSched, setCmdJsonInSched] = useState({})
    const [CommandSchedStatus, setCommandSchedStatus] = useState({})
    var [CmdJsonmsgSche, setCmdJsonmsgSche] = useState({})
    var [ShecdualID, setShecdualID] = useState('')
    const [loaderShow, setloaderShow] = useState(false)
    const mytime = useRef(null)
    const mytimeSched = useRef(null)
    const [ColumnName, setColumnName] = useState({})
    const [inputDisable, setinputDisable] = useState(false)

    const ongetConfigretionLetestData = async (col_ID, CMD_TYPE) => {
        if (ShowLetestData[CMD_TYPE + col_ID].length === 0) {
            const data = await getConfigretionLetestDataByCmd_id({ tableName: props?.GSMdata?.UNIQUE_TABLE_NAME?.gsmmodemconfig, CMD_ID: col_ID == 101 ? 504 : col_ID, CMD_TYPE: CMD_TYPE })
            if (data.success === true) {
                setShowLetestData({ ...ShowLetestData, [CMD_TYPE + col_ID]: data.data.data })
            } else {
                console.log('something want wrong')
            }
        }
    }
    const ongetScriptCodeIdWiseColumnName = async () => {
        const data = await geModemIdWiseColumnName(props?.GSMdata?.MODEM_TYPE)
        if (data.success === true) {
            if (data?.data?.data?.length > 0) {
                setColumnName(JSON.parse(data?.data?.data[0]?.COLUMN_NAME || '{}'))
            }
        } else {
            console.log('something want wrong');
        }
    }

    useEffect(() => {
        ongetScriptCodeIdWiseColumnName()
        ongetScriptCodeIdWiseConfigretionData()
        ongetLastGSMOTAData()
        socket.on('mqttbufferresponse', (massges) => {
            console.log(massges);
            if (massges?.CMD_TYPE == "SCHEDULE_EVENT") {
                setCommandSchedStatus(massges)
            } else {
                setCommandStatus(massges)
            }

        })
        socket.on('mqttcommandresponse', (massges) => {
            console.log(massges);
            if (massges?.CMD_TYPE == "SCHEDULE_EVENT" && !massges?.SCHEDULES) {
                setCmdJsonmsgSche(massges)
            } else {
                setCommandmsgJson(massges)
            }
        })
    }, []);


    useEffect(() => {
        if (CommandSchedStatus?.STATUS != 'Added In Buffer' && Object.keys(CommandSchedStatus).length > 0) {
            BfferError(CommandSchedStatus)
            clearTimeout(mytime.current);
            mytime.current = null
            setcmdRefreshLoader(false)
        } else if (CommandSchedStatus?.STATUS == 'Added In Buffer') {
            setTime(60)
            setRun(true);
            clearTimeout(mytime.current);
            mytime.current = null
        }
    }, [CommandSchedStatus])

    useEffect(() => {
        if (CommandStatus?.STATUS != 'Added In Buffer' && Object.keys(CommandStatus).length > 0) {
            BfferError(CommandStatus)
            clearTimeout(mytime.current);
            mytime.current = null
        } else if (CommandStatus?.STATUS == 'Added In Buffer') {
            setTime(60)
            setRun(true);
            clearTimeout(mytime.current);
            mytime.current = null
        }
    }, [CommandStatus])

    const BfferError = (msg) => {
        console.log(msg.MSGID, CommandJson.MSGID);
        if (msg.MSGID == CommandJson.MSGID) {
            swal.fire('', msg?.STATUS, 'error');
            setCommandSchedStatus({})
            setFlagMsg(true)
            setRun(false);
            setTime(0)
            setModal(false)
        }
    }
    useEffect(() => {
        if (Object.keys(CmdJsonmsgSche).length > 0) {
            getPopdataSchedul(CmdJsonmsgSche)
        }
    }, [CmdJsonmsgSche])

    useEffect(() => {
        if (Object.keys(CommandmsgJson).length > 0) {
            getPopdata(CommandmsgJson)
        }
    }, [CommandmsgJson])

    const getPopdataSchedul = (massges) => {
        console.log(massges);
        if (massges.MSGID == CmdJsonInSched.MSGID && massges.SCRIPTDECODE_ID == CmdJsonInSched.SCRIPTDECODE_ID && massges.UNIQUE_ID == CmdJsonInSched.UNIQUE_ID && massges.CMD_TYPE == CmdJsonInSched.CMD_TYPE) {
            setTime(0)
            setRun(false);
            setcmdRefreshLoader(false)
            setModalSche(false)
            setCommandObj({ "MMD_CFG_SCH_START_OPT": 1, "MMD_CFG_SCH_START_ASTRO_OPT": 0, "MMD_CFG_SCH_END_OPT": 1, "MMD_CFG_SCH_END_ASTRO_OPT": 0, "MMD_CFG_SCH_WEEK": [], "MMD_CFG_SCH_START_HH": 0, "MMD_CFG_SCH_START_MM": 0, "MMD_CFG_SCH_END_HH": 0, "MMD_CFG_SCH_END_MM": 0, "MMD_STATUS_LOAD1": 0, "MMD_STATUS_LOAD2": 0, "MMD_STATUS_LOAD3": 0 })
            setstartType('startastro')
            setendType('endastro')
            setmonthArray([{ key: 'January', Startval: '', Endval: '' }, { key: 'February', Startval: '', Endval: '' }, { key: 'March', Startval: '', Endval: '' }, { key: 'April', Startval: '', Endval: '' }, { key: 'May', Startval: '', Endval: '' }, { key: 'June', Startval: '', Endval: '' }, { key: 'July', Startval: '', Endval: '' }, { key: 'August', Startval: '', Endval: '' }, { key: 'Sepetember', Startval: '', Endval: '' }, { key: 'October', Startval: '', Endval: '' }, { key: 'November', Startval: '', Endval: '' }, { key: 'December', Startval: '', Endval: '' }])
            setShecdualID('')
            if (FlagMsg === false) {
                onLoadChangeState()
                setmodalMsg(true)
                setMqttMsg(JSON.stringify(massges))
            }
        }
    }

    const getPopdata = (massges) => {
        console.log(massges, CommandJson, CommandStaticmsgJson);
        if (massges.MSGID == CommandJson.MSGID && massges.SCRIPTDECODE_ID == CommandJson.SCRIPTDECODE_ID && massges.UNIQUE_ID == CommandJson.UNIQUE_ID && massges.CMD_TYPE == CommandJson.CMD_TYPE && massges?.SCHEDULES?.length > 0) {
            setloaderShow(false)
            setcmdRefreshLoader(false)
            setSchedule(massges)
            clearTimeout(mytimeSched.current);
            mytimeSched.current = null
        } else {
            if (massges.MSGID == CommandStaticmsgJson.MSGID && massges.SCRIPTDECODE_ID == CommandStaticmsgJson.SCRIPTDECODE_ID && massges.UNIQUE_ID == CommandStaticmsgJson.UNIQUE_ID && massges.CMD_TYPE == CommandStaticmsgJson.CMD_TYPE) {
                if (massges.MSGID == CommandStaticmsgJson.MSGID && massges.SCRIPTDECODE_ID == CommandStaticmsgJson.SCRIPTDECODE_ID && massges.UNIQUE_ID == CommandStaticmsgJson.UNIQUE_ID && massges.CMD_TYPE == CommandStaticmsgJson.CMD_TYPE && CommandStaticmsgJson.CMD_TYPE) {
                    console.log(massges, CommandStaticmsgJson);
                    responUpdate(massges?.CMDID, massges.CMD_TYPE)
                    setTime(0)
                    setRun(false);
                    setModal(false)

                    if (FlagMsg === false) {
                        setmodalMsg(true)
                        setMqttMsg(JSON.stringify(massges))
                    }
                    setcmdRefreshLoader(false)
                } else {
                    setTime(0)
                    setRun(false);
                    setModal(false)

                    if (FlagMsg === false) {
                        setmodalMsg(true)
                        setMqttMsg(JSON.stringify(massges))
                    }
                    setcmdRefreshLoader(false)
                }
            }
        }
    }

    const ongetConfigretionStatus = async (id) => {
        var array = []

    }

    const responUpdate = async (CMDID, CMD_TYPE) => {
        const data = await getConfigretionLetestDataByCmd_id({ tableName: props?.GSMdata?.UNIQUE_TABLE_NAME?.gsmmodemconfig, CMD_ID: CMDID == 101 ? 504 : CMDID, CMD_TYPE: CMD_TYPE })
        if (data.success === true) {
            setShowLetestData({ ...ShowLetestData, [CMD_TYPE + CMDID]: data.data.data })
            const objLetest = JSON.parse(data?.data?.data[0]?.SCR_INFO_RAWDATA || '{}')
            var obj = {}
            Object.entries(cmdValue).map(([key, val]) => {
                var Check = typeof val
                Check === 'number' ? Check === 'boolean' ? obj[key] = objLetest[key] ? objLetest[key] == 0 ? false : true : false : obj[key] = objLetest[key] || 0 : obj[key] = objLetest[key] || ''
            })
            console.log(obj, data?.data?.data[0]);
            setCmdvalue(obj)
        } else {
            console.log('something want wrong')
        }
    }

    const onloadRefreshData = async () => {
        const obj = { ...CommandStaticmsgJson, MSGID: new Date().getTime() + 1 }
        if (props?.GSMdata?.MODEM_TYPE == 'MODUID1671705090942') {
            const VD_ID = await getHeatbeatLatestVDID({ tableName: props.GSMdata.UNIQUE_TABLE_NAME.heartbeatdata })
            obj["VD"] = VD_ID?.data?.data?.length > 0 && VD_ID?.data?.data[0]?.VD || null
        }
        setCommandStaticmsgJson(obj)
        setcmdRefreshLoader(true)
        const data = await onModemConfigByGSMConsumer({ config_info: JSON.stringify(obj) })
        if (data.success === true) {

            // swal.fire('', 'Command Add In Buffer', 'success');
        } else {
        }
    }
    const onSubmitGetCommand = async (payload, key, val, commandFlage) => {
        console.log(payload, key, val);
        setCommandType(payload?.CMD_TITLE)
        setGetCmd(val)
        setmodelID(payload?.CMDID)
        if (val?.UI?.type != 'Costom UI') {
            if (commandFlage === true) {
                const CMDobj = { CMD_TYPE: payload?.CMD_TYPE, UNIQUE_ID: props?.GSMdata?.UNIQUE_ID, IMEI: props?.GSMdata?.IMEI, SCRIPTDECODE_ID: props?.GSMdata?.SCRIPTDECODE_ID, MSGID: new Date().getTime() + 1, gsmbuffer: props.GSMdata.UNIQUE_TABLE_NAME.gsmbuffer, "POTP": "1234", "COTP": "5678" }
                Object.entries(payload).map(([key, val]) => (
                    key !== 'buttons' ? CMDobj[key] = val : ''
                ))
                if (Object.entries(payload['buttons'][key]).every(([key, val]) => (val?.type === "CONSTANT"))) {
                    onSubmitCommandDirectBuffer(payload?.CMD_TITLE, key, val)
                } else {
                    const data = await getConfigretionLetestDataByCmd_id({ tableName: props?.GSMdata?.UNIQUE_TABLE_NAME?.gsmmodemconfig, CMD_ID: payload?.CMDID == 101 ? 504 : payload?.CMDID, CMD_TYPE: payload?.CMD_TYPE })
                    if (data.success === true) {
                        setShowLetestData({ ...ShowLetestData, [payload?.CMD_TYPE + payload?.CMDID]: data.data.data })
                        const objLetest = JSON.parse(data?.data?.data[0]?.SCR_INFO_RAWDATA || '{}')
                        var obj = {}
                        Object.entries(val).map(([key, val]) => {
                            if (key != 'Color') {

                                val === 'INT' ? obj[key] = objLetest[key] || '' : val === 'REDIO' ? obj[key] = objLetest[key] ? objLetest[key] == 0 ? false : true : false : obj[key] = objLetest[key] || 0

                                if (val === 'CONSTANT') {
                                    obj[key] = obj[key]['val']
                                }
                            }
                        })
                        console.log(obj, data?.data?.data[0]);
                        setCmdvalue(obj)
                        setIsModalVisible1(true)
                    } else {
                        console.log('something want wrong')
                    }
                    setCommandStaticmsgJson(CMDobj)
                }
            } else {
                var obj = {}
                if (payload?.CMDID == 409) {
                    if (props?.GSMdata?.METER_TYPE) {
                        obj['VD'] = props?.MeterInfo?.VD_ID
                    } else {
                        obj['VD'] = 255
                    }
                } else {
                    Object.entries(val).map(([key, val]) => {

                        val === 'INT' || val === 'REDIO' ? obj[key] = 0 : obj[key] = ''
                    })
                }
                setCmdvalue(obj)
                setIsModalVisible(true)
            }
        } else {
            setmodalUI(true)
            setloaderShow(true)
            const cmd = { "CMDID": 504, "SYNCTS": 0, "MSGID": new Date().getTime() + 1, "CMD_TYPE": "SCHEDULE_EVENT", "POTP": "1234", "COTP": "5678", gsmbuffer: props.GSMdata.UNIQUE_TABLE_NAME.gsmbuffer, UNIQUE_ID: props?.GSMdata?.UNIQUE_ID, IMEI: props?.GSMdata?.IMEI, SCRIPTDECODE_ID: props?.GSMdata?.SCRIPTDECODE_ID }
            setCommandJson(cmd)
            const data = await onModemConfigByGSMConsumer({ config_info: JSON.stringify(cmd) })
            if (data.success === true) {
                mytimeSched.current = setTimeout(() => {
                    if (Object.keys(CommandJson).length === 0) {
                        setloaderShow(false)
                    }
                }, 5000)
            } else {

            }
        }
    }

    useEffect(() => {
        ongetConfigretionStatus()
    }, []);

    const handleCancel = () => {
        setIsModalVisible(false)
        setCmdvalue({})
    }

    const handleCancel1 = () => {
        setIsModalVisible1(false)
        setCmdvalue({})
        setcmdRefreshLoader(false)
    }

    const onSubmitCommand = async () => {
        const CMDobj = { ...cmdValue, CMD_TYPE: CommandType, UNIQUE_ID: props?.GSMdata?.UNIQUE_ID, IMEI: props?.GSMdata?.IMEI, SCRIPTDECODE_ID: props?.GSMdata?.SCRIPTDECODE_ID, MSGID: new Date().getTime() + 1, gsmbuffer: props.GSMdata.UNIQUE_TABLE_NAME.gsmbuffer, "POTP": "1234", "COTP": "5678" }
        if (props?.GSMdata?.MODEM_TYPE == 'MODUID1671705090942') {
            const VD_ID = await getHeatbeatLatestVDID({ tableName: props.GSMdata.UNIQUE_TABLE_NAME.heartbeatdata })
            CMDobj["VD"] = VD_ID?.data?.data?.length > 0 && VD_ID?.data?.data[0]?.VD || null
        }
        Object.entries(status[CommandType]).map(([key, val]) => {
            console.log(key);
            if (key !== 'buttons' && key !== 'Color') {
                CMDobj[key] = val
            } else {
                if (key !== 'Color') {
                    console.log(val);
                    Object.entries(val).map(([keyIn, valIn]) => {
                        if (valIn) {
                            Object.entries(valIn).map(([keys, vals]) => {
                                if (vals.type == "CONSTANT") {
                                    CMDobj[keys] = vals?.val
                                }
                            })
                        }
                    })
                }
            }
        })
        // alert(JSON.stringify(CMDobj))
        swal.fire({
            title: "Are you sure?",
            text: status[CommandType]?.CMD_TYPE == 'METER_ASN' ? "If You Update Meter ASN Then Again Configuration Required" : "This will initiate downlink",
            icon: "warning",
            showCancelButton: true,
        }).then(async (willDelete) => {
            if (willDelete.isConfirmed) {
                setModal(true)
                setCommandStaticmsgJson(CMDobj)

                const data = await onModemConfigByGSMConsumer({ config_info: JSON.stringify(CMDobj) })
                if (data.success === true) {
                    setIsModalVisible(false)
                    mytime.current = setTimeout(() => {
                        if (Object.keys(CommandStatus).length === 0) {
                            setCommandStatus({ STATUS: 'Time Out' })
                        }
                    }, 5000)
                } else {

                }
            }
        })
    }

    const onSubmitCommandDirectBuffer = async (CommandType, key_Up) => {
        const CMDobj = { CMD_TYPE: CommandType, "SYNCTS": 0, UNIQUE_ID: props?.GSMdata?.UNIQUE_ID, IMEI: props?.GSMdata?.IMEI, SCRIPTDECODE_ID: props?.GSMdata?.SCRIPTDECODE_ID, MSGID: new Date().getTime() + 1, gsmbuffer: props.GSMdata.UNIQUE_TABLE_NAME.gsmbuffer, "POTP": "1234", "COTP": "5678" }
        if (props?.GSMdata?.MODEM_TYPE == 'MODUID1671705090942') {
            const VD_ID = await getHeatbeatLatestVDID({ tableName: props.GSMdata.UNIQUE_TABLE_NAME.heartbeatdata })
            CMDobj["VD"] = VD_ID?.data?.data?.length > 0 && VD_ID?.data?.data[0]?.VD || null
        }
        Object.entries(status[CommandType]).map(([key, val]) => {
            console.log(key);
            if (key !== 'buttons' && key !== 'Color') {
                CMDobj[key] = val
            } else {
                if (key !== 'Color') {
                    console.log(val);
                    Object.entries(val).map(([keyIn, valIn]) => {
                        if (key_Up == keyIn) {
                            if (valIn) {
                                Object.entries(valIn).map(([keys, vals]) => {
                                    if (vals.type == "CONSTANT") {
                                        CMDobj[keys] = vals?.val
                                    }
                                })
                            }
                        } else {

                        }
                    })
                }
            }
        })
        // alert(JSON.stringify(CMDobj))
        swal.fire({
            title: "Are you sure?",
            text: "This will initiate downlink",
            icon: "warning",
            showCancelButton: true,
        }).then(async (willDelete) => {
            if (willDelete.isConfirmed) {
                setModal(true)
                setCommandStaticmsgJson(CMDobj)
                const data = await onModemConfigByGSMConsumer({ config_info: JSON.stringify(CMDobj) })
                if (data.success === true) {
                    mytime.current = setTimeout(() => {
                        if (Object.keys(CommandStatus).length === 0) {
                            setCommandStatus({ STATUS: 'Time Out' })
                        }
                    }, 5000)
                } else {
                }
            }
        })
    }
    useInterval(
        () => {
            if (time <= 1) {
                setRun(false);
                setModal(false)
                // swal.fire('', 'Command Add In Buffer', 'success');
            }
            setTime((preState) => preState - 1);
        },
        time && run ? 1000 : null
    );

    function zeroPad(num) {
        var str = String(num);
        if (str.length < 2) {
            return "0" + str;
        }

        return str;
    }

    const CloseModel = () => {
        setFlagMsg(true)
        setRun(false);
        setTime(0)
        setModal(false)
        setCommandSchedStatus({})
        setCmdJsonmsgSche({})
        setCommandObj({ "MMD_CFG_SCH_START_OPT": 1, "MMD_CFG_SCH_START_ASTRO_OPT": 0, "MMD_CFG_SCH_END_OPT": 1, "MMD_CFG_SCH_END_ASTRO_OPT": 0, "MMD_CFG_SCH_WEEK": [], "MMD_CFG_SCH_START_HH": 0, "MMD_CFG_SCH_START_MM": 0, "MMD_CFG_SCH_END_HH": 0, "MMD_CFG_SCH_END_MM": 0, "MMD_STATUS_LOAD1": 0, "MMD_STATUS_LOAD2": 0, "MMD_STATUS_LOAD3": 0 })
    }

    const CloseModelShedul = () => {
        setFlagMsg(true)
        setRun(false);
        setTime(0)
        setModalSche(false)
        setCommandSchedStatus({})
        setCmdJsonmsgSche({})
        setCommandObj({ "MMD_CFG_SCH_START_OPT": 1, "MMD_CFG_SCH_START_ASTRO_OPT": 0, "MMD_CFG_SCH_END_OPT": 1, "MMD_CFG_SCH_END_ASTRO_OPT": 0, "MMD_CFG_SCH_WEEK": [], "MMD_CFG_SCH_START_HH": 0, "MMD_CFG_SCH_START_MM": 0, "MMD_CFG_SCH_END_HH": 0, "MMD_CFG_SCH_END_MM": 0, "MMD_STATUS_LOAD1": 0, "MMD_STATUS_LOAD2": 0, "MMD_STATUS_LOAD3": 0 })
        setmonthArray([{ key: 'January', Startval: '', Endval: '' }, { key: 'February', Startval: '', Endval: '' }, { key: 'March', Startval: '', Endval: '' }, { key: 'April', Startval: '', Endval: '' }, { key: 'May', Startval: '', Endval: '' }, { key: 'June', Startval: '', Endval: '' }, { key: 'July', Startval: '', Endval: '' }, { key: 'August', Startval: '', Endval: '' }, { key: 'Sepetember', Startval: '', Endval: '' }, { key: 'October', Startval: '', Endval: '' }, { key: 'November', Startval: '', Endval: '' }, { key: 'December', Startval: '', Endval: '' }])
    }

    const onCloseAddCommand = () => {
        setinputDisable(false)
        setmodalAddSchedules(false)
        setCommandObj({ "MMD_CFG_SCH_START_OPT": 1, "MMD_CFG_SCH_START_ASTRO_OPT": 0, "MMD_CFG_SCH_END_OPT": 1, "MMD_CFG_SCH_END_ASTRO_OPT": 0, "MMD_CFG_SCH_WEEK": [], "MMD_CFG_SCH_START_HH": 0, "MMD_CFG_SCH_START_MM": 0, "MMD_CFG_SCH_END_HH": 0, "MMD_CFG_SCH_END_MM": 0, "MMD_STATUS_LOAD1": 0, "MMD_STATUS_LOAD2": 0, "MMD_STATUS_LOAD3": 0 })
        setstartType('startastro')
        setendType('endastro')
        setmonthArray([{ key: 'January', Startval: '', Endval: '' }, { key: 'February', Startval: '', Endval: '' }, { key: 'March', Startval: '', Endval: '' }, { key: 'April', Startval: '', Endval: '' }, { key: 'May', Startval: '', Endval: '' }, { key: 'June', Startval: '', Endval: '' }, { key: 'July', Startval: '', Endval: '' }, { key: 'August', Startval: '', Endval: '' }, { key: 'Sepetember', Startval: '', Endval: '' }, { key: 'October', Startval: '', Endval: '' }, { key: 'November', Startval: '', Endval: '' }, { key: 'December', Startval: '', Endval: '' }])
        setShecdualID('')

    }

    const onResetAddCommand = () => {
        setCommandObj({ "MMD_CFG_SCH_START_OPT": 1, "MMD_CFG_SCH_START_ASTRO_OPT": 0, "MMD_CFG_SCH_END_OPT": 1, "MMD_CFG_SCH_END_ASTRO_OPT": 0, "MMD_CFG_SCH_WEEK": [], "MMD_CFG_SCH_START_HH": 0, "MMD_CFG_SCH_START_MM": 0, "MMD_CFG_SCH_END_HH": 0, "MMD_CFG_SCH_END_MM": 0, "MMD_STATUS_LOAD1": 0, "MMD_STATUS_LOAD2": 0, "MMD_STATUS_LOAD3": 0 })
        setstartType('startastro')
        setendType('endastro')
        setmonthArray([{ key: 'January', Startval: '', Endval: '' }, { key: 'February', Startval: '', Endval: '' }, { key: 'March', Startval: '', Endval: '' }, { key: 'April', Startval: '', Endval: '' }, { key: 'May', Startval: '', Endval: '' }, { key: 'June', Startval: '', Endval: '' }, { key: 'July', Startval: '', Endval: '' }, { key: 'August', Startval: '', Endval: '' }, { key: 'Sepetember', Startval: '', Endval: '' }, { key: 'October', Startval: '', Endval: '' }, { key: 'November', Startval: '', Endval: '' }, { key: 'December', Startval: '', Endval: '' }])
    }

    const onClickDataInConfig = async (type, col, id) => {
        console.log(type, col, id);
        setmodelTitle(type)

        setmodalDetails(true)
        const firstPageIndex = (currentPage - 1) * PageSize;
        const data = await getConfigretionCMD_IDANDTYPEWiseData({ tableName: props.GSMdata.UNIQUE_TABLE_NAME.gsmmodemconfig, CMD_TYPE: id == 101 ? 'SCHEDULE_EVENT' : col, CMD_ID: id == 101 ? [505, 502, 503, 504] : [id], start: firstPageIndex, end: PageSize })
        if (data.success === true) {
            setDetailConfig(data.data.data)
        } else {
            console.log('something want wrong')
        }
    }

    useEffect(() => {
        if (DetailConfig.length > 0) {
            onClickDataInConfig()
        }
    }, [currentPage, PageSize])

    const [modelCMDType, setmodelCMDType] = useState('')

    const onBufferPenddingData = async (col, CMDID, CMD_TYPE) => {
        setmodelTitle(col)
        setmodelID(CMDID)
        setmodelCMDType(CMD_TYPE)
        setmodalBufferDetails(true)
        const firstPageIndex = (BuffercurrentPage - 1) * BuffercurrentPage;

        const data = await getConfigretionCMD_TYPEWisePendding_Data_buffer({ CONSUMER_ID: props.GSMdata.UNIQUE_ID, CMD_TYPE: CMD_TYPE, CMDID: CMDID, start: firstPageIndex, end: BufferPageSize, tableName: props.GSMdata.UNIQUE_TABLE_NAME.gsmbuffer })
        if (data.success === true) {
            setBuffernumberofPage(data.data.Total)
            setBufferPenddingData(data.data.data)
        } else {
            console.log('something want wrong')

        }
    }

    useEffect(() => {
        if (BufferPenddingData.length > 0) {
            onBufferPenddingData(modelTitle, modelID, modelCMDType)
        }
    }, [BuffercurrentPage, BuffercurrentPage])


    const [monthArray, setmonthArray] = useState([{ key: 'January', Startval: '', Endval: '' }, { key: 'February', Startval: '', Endval: '' }, { key: 'March', Startval: '', Endval: '' }, { key: 'April', Startval: '', Endval: '' }, { key: 'May', Startval: '', Endval: '' }, { key: 'June', Startval: '', Endval: '' }, { key: 'July', Startval: '', Endval: '' }, { key: 'August', Startval: '', Endval: '' }, { key: 'Sepetember', Startval: '', Endval: '' }, { key: 'October', Startval: '', Endval: '' }, { key: 'November', Startval: '', Endval: '' }, { key: 'December', Startval: '', Endval: '' }])
    const [startType, setstartType] = useState('startastro')
    const [endType, setendType] = useState('endastro')
    const [CommandObj, setCommandObj] = useState({ "MMD_CFG_SCH_START_OPT": 0, "MMD_CFG_SCH_START_ASTRO_OPT": 0, "MMD_CFG_SCH_END_OPT": 0, "MMD_CFG_SCH_END_ASTRO_OPT": 0, "MMD_CFG_SCH_WEEK": [], "MMD_CFG_SCH_START_HH": 0, "MMD_CFG_SCH_START_MM": 0, "MMD_CFG_SCH_END_HH": 0, "MMD_CFG_SCH_END_MM": 0, "MMD_STATUS_LOAD1": 0, "MMD_STATUS_LOAD2": 0, "MMD_STATUS_LOAD3": 0 })

    const daysArray = [
        { value: "SUN", label: "S" },
        { value: "MON", label: 'M' },
        { value: "TUE", label: "T" },
        { value: "WED", label: "W" },
        { value: "THU", label: "T" },
        { value: "FRI", label: "F" },
        { value: "SAT", label: "S" }
    ]

    const onLoadChangeState = async () => {
        const cmd = { "CMDID": 504, "SYNCTS": 0, "MSGID": new Date().getTime() + 1, "CMD_TYPE": "SCHEDULE_EVENT", "POTP": "1234", "COTP": "5678", gsmbuffer: props.GSMdata.UNIQUE_TABLE_NAME.gsmbuffer, UNIQUE_ID: props?.GSMdata?.UNIQUE_ID, IMEI: props?.GSMdata?.IMEI, SCRIPTDECODE_ID: props?.GSMdata?.SCRIPTDECODE_ID }
        setCommandJson(cmd)
        setcmdRefreshLoader(true)
        const data = await onModemConfigByGSMConsumer({ config_info: JSON.stringify(cmd) })
        if (data.success === true) {
            //    mytime.current=setTimeout(() => {
            //    if(Object.keys(CommandStatus).length===0)
            // 						{
            // 							setCommandStatus({STATUS:'Time Out'})
            // 						}
            // 						},5000)
            // swal.fire('', 'Command Add In Buffer', 'success');
        } else {

        }
    }

    const onClickCommand = async () => {
        var obj = {
            "CMDID": ShecdualID ? 503 : 502,
            "SYNCTS": 0,
            "MSGID": new Date().getTime() + 1,
            "CMD_TYPE": "SCHEDULE_EVENT",
            "POTP": "1234",
            "COTP": "5678",
            "MMD_CFG_SCH_UID": ShecdualID ? ShecdualID : new Date().getTime() + 1,
            "MMD_CFG_SCH_START_OPT": startType == 'startastro' ? 1 : 0,
            "MMD_CFG_SCH_START_ASTRO_OPT": CommandObj?.MMD_CFG_SCH_START_ASTRO_OPT,
            "MMD_CFG_SCH_END_OPT": endType == 'endastro' ? 1 : 0,
            "MMD_CFG_SCH_END_ASTRO_OPT": CommandObj?.MMD_CFG_SCH_END_ASTRO_OPT,
            "MMD_CFG_SCH_WEEK": CommandObj?.MMD_CFG_SCH_WEEK,
            "MMD_CFG_SCH_START_HH": CommandObj?.MMD_CFG_SCH_START_HH,
            "MMD_CFG_SCH_START_MM": CommandObj?.MMD_CFG_SCH_START_MM,
            "MMD_CFG_SCH_END_HH": CommandObj?.MMD_CFG_SCH_END_HH,
            "MMD_CFG_SCH_END_MM": CommandObj?.MMD_CFG_SCH_END_MM,
            "MMD_STATUS_LOAD1": CommandObj?.MMD_STATUS_LOAD1,
            "MMD_STATUS_LOAD2": CommandObj?.MMD_STATUS_LOAD2,
            "MMD_STATUS_LOAD3": CommandObj?.MMD_STATUS_LOAD3,
            "MMD_STATUS_DIMVAL": 0,
            "MMD_CFG_SCH_MONTH_OFFSET_MIN": [monthArray[0]?.Startval || 0, monthArray[0]?.Endval || 0, monthArray[1]?.Startval || 0, monthArray[1]?.Endval || 0, monthArray[2]?.Startval || 0, monthArray[2]?.Endval || 0, monthArray[3]?.Startval || 0, monthArray[3]?.Endval || 0, monthArray[4]?.Startval || 0, monthArray[4]?.Endval || 0, monthArray[5]?.Startval || 0, monthArray[5]?.Endval || 0, monthArray[6]?.Startval || 0, monthArray[6]?.Endval || 0, monthArray[7]?.Startval || 0, monthArray[7]?.Endval || 0, monthArray[8]?.Startval || 0, monthArray[8]?.Endval || 0, monthArray[9]?.Startval || 0, monthArray[9]?.Endval || 0, monthArray[10]?.Startval || 0, monthArray[10]?.Endval || 0, monthArray[11]?.Startval || 0, monthArray[11]?.Endval || 0], gsmbuffer: props.GSMdata.UNIQUE_TABLE_NAME.gsmbuffer, UNIQUE_ID: props?.GSMdata?.UNIQUE_ID, IMEI: props?.GSMdata?.IMEI, SCRIPTDECODE_ID: props?.GSMdata?.SCRIPTDECODE_ID
        }

        // alert(JSON.stringify(obj))
        swal.fire({
            title: "Are you sure?",
            text: "This will initiate downlink",
            icon: "warning",
            showCancelButton: true,
        }).then(async (willDelete) => {
            if (willDelete.isConfirmed) {
                setCmdJsonInSched(obj)

                const data = await onModemConfigByGSMConsumer({ config_info: JSON.stringify(obj) })
                if (data.success === true) {
                    setmodalAddSchedules(false)
                    setModalSche(true)


                    mytime.current = setTimeout(() => {
                        if (Object.keys(CommandStatus).length === 0) {
                            setCommandSchedStatus({ STATUS: 'Time Out' })
                        }
                    }, 60000)
                    // swal.fire('', 'Command Add In Buffer', 'success');
                } else {

                }
            }
        })


    }

    const onClickDeleteShedule = async (dataInfo, id) => {
        const dataObj = { "CMDID": 505, "SYNCTS": 0, "MSGID": new Date().getTime() + 1, "CMD_TYPE": "SCHEDULE_EVENT", "POTP": "1234", "COTP": "5678", "MMD_CFG_SCH_UID": dataInfo?.MMD_CFG_SCH_UID, gsmbuffer: props.GSMdata.UNIQUE_TABLE_NAME.gsmbuffer, UNIQUE_ID: props?.GSMdata?.UNIQUE_ID, IMEI: props?.GSMdata?.IMEI, SCRIPTDECODE_ID: props?.GSMdata?.SCRIPTDECODE_ID }
        setCmdJsonInSched(dataObj)
        swal.fire({
            title: "Are you sure?",
            text: "This will Delete Schedule",
            icon: "warning",
            showCancelButton: true,
        }).then(async (willDelete) => {
            if (willDelete.isConfirmed) {
                const data = await onModemConfigByGSMConsumer({ config_info: JSON.stringify(dataObj) })
                setmodelTitle(id + '.' + "Delete Schedule" + `(${dataInfo?.MMD_CFG_SCH_UID})`)
                if (data.success === true) {
                    setModalSche(true)
                    mytime.current = setTimeout(() => {
                        if (Object.keys(CommandStatus).length === 0) {
                            setCommandSchedStatus({ STATUS: 'Time Out' })
                        }
                    }, 5000)
                    // swal.fire('', 'Command Add In Buffer', 'success');
                } else {

                }
            }
        })
    }

    const oneditHandle = (dataInfo) => {
        console.log('dataInfo', dataInfo);
        setinputDisable(true)
        setmodalAddSchedules(true)
        setmonthArray([{ key: 'January', Startval: dataInfo?.MMD_CFG_SCH_MONTH_OFFSET_MIN[0], Endval: dataInfo?.MMD_CFG_SCH_MONTH_OFFSET_MIN[1] }, { key: 'February', Startval: dataInfo?.MMD_CFG_SCH_MONTH_OFFSET_MIN[2], Endval: dataInfo?.MMD_CFG_SCH_MONTH_OFFSET_MIN[3] }, { key: 'March', Startval: dataInfo?.MMD_CFG_SCH_MONTH_OFFSET_MIN[4], Endval: dataInfo?.MMD_CFG_SCH_MONTH_OFFSET_MIN[5] }, { key: 'April', Startval: dataInfo?.MMD_CFG_SCH_MONTH_OFFSET_MIN[6], Endval: dataInfo?.MMD_CFG_SCH_MONTH_OFFSET_MIN[7] }, { key: 'May', Startval: dataInfo?.MMD_CFG_SCH_MONTH_OFFSET_MIN[8], Endval: dataInfo?.MMD_CFG_SCH_MONTH_OFFSET_MIN[9] }, { key: 'June', Startval: dataInfo?.MMD_CFG_SCH_MONTH_OFFSET_MIN[10], Endval: dataInfo?.MMD_CFG_SCH_MONTH_OFFSET_MIN[11] }, { key: 'July', Startval: dataInfo?.MMD_CFG_SCH_MONTH_OFFSET_MIN[12], Endval: dataInfo?.MMD_CFG_SCH_MONTH_OFFSET_MIN[13] }, { key: 'August', Startval: dataInfo?.MMD_CFG_SCH_MONTH_OFFSET_MIN[14], Endval: dataInfo?.MMD_CFG_SCH_MONTH_OFFSET_MIN[15] }, { key: 'Sepetember', Startval: dataInfo?.MMD_CFG_SCH_MONTH_OFFSET_MIN[16], Endval: dataInfo?.MMD_CFG_SCH_MONTH_OFFSET_MIN[17] }, { key: 'October', Startval: dataInfo?.MMD_CFG_SCH_MONTH_OFFSET_MIN[18], Endval: dataInfo?.MMD_CFG_SCH_MONTH_OFFSET_MIN[19] }, { key: 'November', Startval: dataInfo?.MMD_CFG_SCH_MONTH_OFFSET_MIN[20], Endval: dataInfo?.MMD_CFG_SCH_MONTH_OFFSET_MIN[21] }, { key: 'December', Startval: dataInfo?.MMD_CFG_SCH_MONTH_OFFSET_MIN[22], Endval: dataInfo?.MMD_CFG_SCH_MONTH_OFFSET_MIN[23] }])

        setCommandObj(dataInfo)
        setShecdualID(dataInfo?.MMD_CFG_SCH_UID)
        if (dataInfo?.MMD_CFG_SCH_START_OPT == 1) {
            setstartType('startastro')
        } else if (dataInfo?.MMD_CFG_SCH_START_OPT == 0) {
            setstartType('startmanual')
        }
        if (dataInfo?.MMD_CFG_SCH_END_OPT == 1) {
            setendType('endastro')
        } else if (dataInfo?.MMD_CFG_SCH_END_OPT == 0) {
            setendType('endmanual')
        }
    }

    const onCancelSchedulList = () => {
        setmodalUI(false)
        setSchedule({})
    }

    const onSetQueueCommand = async () => {
        if(props?.GSMdata?.HEARTBEAT_LAST_DATA?.VERSTR == props?.GSMdata?.MODEM_FIRMWARE_NAME||Lastfirmwarversion?.status==-1){
            return
        }
        swal.fire({
            title: "Are you sure?",
            text: "Update Firmware Version ",
            icon: "warning",
            showCancelButton: true,
        }).then(async (willDelete) => {
            if (willDelete.isConfirmed) {
                const data = await UpdateModemFirmwarOTA({ consumer_id: props?.GSMdata?.UNIQUE_ID, imei: props?.GSMdata?.IMEI })
                if (data.success === true) {
                    swal.fire('', 'Command Added In Queue', 'success');
                } else {

                }
            }
        })
    }
    return (
        <>
            <Modal visible={ModalSche} footer='' maskClosable={false} onCancel={() => (setModalSche(false))} width={500}>
                <h5 class="modal-title text-center" id="meter_data_mqtt_info_txt">Getting {modelTitle} Command</h5>
                <h5 class="modal-title text-center mt-2 " id="meter_data_mqtt_info_txt">{!CommandSchedStatus?.STATUS && CommandSchedStatus?.STATUS != 'Added In Buffer' ? <img className='mr-2' src={clockPending} height='20px' width={'20px'}></img> : CommandSchedStatus?.STATUS != 'Added In Buffer' ? <i className='fas fa-times mr-2' style={{ color: 'red' }}></i> : <i className='fas fa-check mr-2' style={{ color: 'green' }}></i>}{!CommandSchedStatus?.STATUS ? 'Command Pending' : CommandSchedStatus?.STATUS}</h5>
                <div class="">
                    {CommandSchedStatus?.STATUS == 'Added In Buffer' ? <div class="text-center" style={{ marginTop: "10px", fontSize: "20px" }}>
                        <label style={{ fontWeight: "400" }}>Waiting For Respose</label>
                        <div id="meter_data_mqtt_timer_status" style={{ fontSize: "18px" }}>{`${zeroPad(time)}`} seconds left</div>
                    </div> : ''}
                </div>
                <div class="text-center" style={{ marginTop: "20px" }}>
                    <input type="button" class="btn btn-danger " value="Close" id="meter_data_mqtt_cancel_button" name="meter_data_mqtt_cancel_button" onClick={CloseModelShedul} />
                </div>
            </Modal>
            <Modal visible={modal} footer='' maskClosable={false} onCancel={() => (setModal(false))} width={500}>
                <h5 class="modal-title text-center" id="meter_data_mqtt_info_txt">Getting {modelTitle} Command</h5>
                <h5 class="modal-title text-center mt-2 " id="meter_data_mqtt_info_txt">{!CommandStatus?.STATUS && CommandStatus?.STATUS != 'Added In Buffer' ? <img className='mr-2' src={clockPending} height='20px' width={'20px'}></img> : CommandStatus?.STATUS != 'Added In Buffer' ? <i className='fas fa-times mr-2' style={{ color: 'red' }}></i> : <i className='fas fa-check mr-2' style={{ color: 'green' }}></i>}{!CommandStatus?.STATUS ? 'Command Pending' : CommandStatus?.STATUS}</h5>
                <div class="">
                    {CommandStatus?.STATUS == 'Added In Buffer' ? <div class="text-center" style={{ marginTop: "10px", fontSize: "20px" }}>
                        <label style={{ fontWeight: "400" }}>Waiting For Respose</label>
                        <div id="meter_data_mqtt_timer_status" style={{ fontSize: "18px" }}>{`${zeroPad(time)}`} seconds left</div>
                    </div> : ''}
                </div>
                <div class="text-center" style={{ marginTop: "20px" }}>
                    <input type="button" class="btn btn-danger " value="Close" id="meter_data_mqtt_cancel_button" name="meter_data_mqtt_cancel_button" onClick={CloseModel} />
                </div>
            </Modal>
            <Modal visible={modalMsg} footer='' maskClosable={false} onCancel={() => (setmodalMsg(false))} width={500}>
                {MqttMsg}
            </Modal>
            <Modal title={'Add Setup Schedules'} visible={modalUI} footer='' maskClosable={false} onCancel={onCancelSchedulList} width={1300}>

                <div style={{ float: 'right' }}>
                    <BootstrapTooltip title='Refresh'><span className='tools'> <a class={`btn btn-icon btn-info mb-2 mr-2 text-ligth ${cmdRefreshLoader == true ? 'disabled btn-progress' : ''}`} onClick={onLoadChangeState}><i class="fas fa-redo"></i></a></span></BootstrapTooltip>

                    <a class={`btn btn-icon icon-left btn-outline-primary  mb-2`} onClick={() => (setmodalAddSchedules(true))}><i class="fas fa-plus"></i> Add Schedule</a>
                </div>

                <div className="table-responsive ">
                    {loaderShow && (
                        <div className="example">
                            <Spin size="large" />
                        </div>
                    )}
                    {!Schedule?.SCHEDULES && !loaderShow && <h4 className="text-center">No Data Found</h4>}
                    {Schedule?.SCHEDULES?.length > 0 && !loaderShow && <div id="table-1_wrapper" class="dataTables_wrapper no-footer">
                        <table id="table_id" className="tableCustom table table-striped">
                            <thead>
                                <tr>
                                    <th className="text-center" style={{ width: '100px' }}>SCHEDULE NO</th>
                                    <th >Schedule ID</th>
                                    <th >Days</th>
                                    <th>Start Time</th>
                                    <th>End Time</th>
                                    <th>Load Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Schedule?.SCHEDULES?.length > 0 && Schedule?.SCHEDULES.map((item, id) => (
                                    <tr className="trheigth" >
                                        <td>{id + 1}</td>
                                        <td>{item?.MMD_CFG_SCH_UID}</td>
                                        <td>{daysArray.map((day) => (<a className={`btn ${item?.MMD_CFG_SCH_WEEK?.filter((info) => (info == day.value)).length > 0 ? 'btn-primary text-light' : 'btn-light text-dark'}    ml-2`} data-original-title="Edit" id='btnedit'>{day?.label}</a>))
                                        }
                                        </td>
                                        <td>{item?.MMD_CFG_SCH_START_OPT == 0 ? moment(`${item?.MMD_CFG_SCH_START_HH}:${item?.MMD_CFG_SCH_START_MM}`, 'HH:mm').format('hh:mm A') : item?.MMD_CFG_SCH_START_ASTRO_OPT == 1 ? 'At Sunset' : 'At Sunrise'}</td>
                                        <td>{item?.MMD_CFG_SCH_END_OPT == 0 ? moment(`${item?.MMD_CFG_SCH_END_HH}:${item?.MMD_CFG_SCH_END_MM}`, 'HH:mm').format('hh:mm A') : item?.MMD_CFG_SCH_END_ASTRO_OPT == 1 ? 'At Sunset' : 'At Sunrise'}</td>
                                        <td><BootstrapTooltip title='Relay 1'><span class={`${item?.MMD_STATUS_LOAD1 == 1 ? "text-success" : "text-danger"}`}><span class={`${item?.MMD_STATUS_LOAD1 == 1 ? "text-success" : "text-danger"}`}><i class={`fas fa-square `}></i></span></span></BootstrapTooltip> <BootstrapTooltip title='Relay 2'><span class={`${item?.MMD_STATUS_LOAD2 == 1 ? "text-success" : "text-danger"}`}><span class={`${item?.MMD_STATUS_LOAD2 == 1 ? "text-success" : "text-danger"}`}><i class={` fas fa-square`}></i></span></span></BootstrapTooltip> <BootstrapTooltip title='Relay 3'><span class={`${item?.MMD_STATUS_LOAD3 == 1 ? "text-success" : "text-danger"}`}><span class={`${item?.MMD_STATUS_LOAD3 == 1 ? "text-success" : "text-danger"}`}><i class={` fas fa-square `}></i></span></span></BootstrapTooltip></td>
                                        <td><BootstrapTooltip title='Settings'><a className='btn btn-primary text-light  ml-2' data-original-title="Edit" id='btnedit' onClick={() => (oneditHandle(item))}><i class="fas fa-cog gridbtn"></i></a></BootstrapTooltip><BootstrapTooltip title='Delete'><a data-original-title="Delete" id='btnedit' className='btn btn-danger text-light  ml-2' onClick={() => onClickDeleteShedule(item, id + 1)}> <i class="fa fa-close gridbtn"></i></a></BootstrapTooltip></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>}
                </div>
            </Modal>
            <Modal title={<><span>Command</span>{ShecdualID != '' ? <div className='btn btn-primary text-light  ml-2' onClick={() => setinputDisable(false)}><i class="fa fa-pencil gridbtn"></i></div> : ''}</>} maskClosable={false} visible={modalAddSchedules} footer="" onCancel={onCloseAddCommand} width={800}>
                <div class="row">
                    <div class="col-6" style={{ borderRight: '1px solid' }}>
                        <h5 class="col-sm-12">Start Time</h5>
                        <div class="col-sm-12">
                            <div class="col-sm-12 d-flex">
                                <div class="radio radio-primary " style={{ width: "25%" }}>
                                    <input type="radio" id="startastro" name="startastrotype" onClick={() => setstartType('startastro')} value={'1'} checked={startType == 'startastro' ? true : false} disabled={inputDisable} />
                                    <label for="startastro" className='ml-2'> Astro </label>
                                </div>
                                <div class="radio radio-primary radio-inline" style={{ width: "25%" }}>
                                    <input type="radio" id="startmanual" name="startastrotype" checked={startType == 'startmanual' ? true : false} onClick={() => setstartType('startmanual')} disabled={inputDisable} />
                                    <label for="startmanual" className='ml-2' > Manual </label>
                                </div>
                            </div>
                        </div>
                        {startType == 'startmanual' ? <><div class="col-sm-12 startmanualdiv">
                            <div class="col-8">
                                <label class="">Start Time</label>
                                <TimePicker showTime={{ use12Hours: true, format: "hh:mm A", defaultValue: CommandObj?.MMD_CFG_SCH_START_HH == 0 && CommandObj?.MMD_CFG_SCH_START_MM == 0 ? moment('00:00', 'HH:mm') : moment(CommandObj?.MMD_CFG_SCH_START_HH + ':' + CommandObj?.MMD_CFG_SCH_START_MM, 'HH:mm') }} format="hh:mm A" onChange={(e, dateString) => (setCommandObj({ ...CommandObj, MMD_CFG_SCH_START_HH: dateString ? moment(dateString, 'hh:mm A').format('HH') : 0, MMD_CFG_SCH_START_MM: dateString ? moment(dateString, 'hh:mm A').format('mm') : 0 }))} defaultOpenValue={CommandObj?.MMD_CFG_SCH_START_HH == 0 && CommandObj?.MMD_CFG_SCH_START_MM == 0 ? '' : moment(CommandObj?.MMD_CFG_SCH_START_HH + ':' + CommandObj?.MMD_CFG_SCH_START_MM, 'HH:mm')} disabled={inputDisable} />
                            </div>
                        </div></> : ''}
                        {startType == 'startastro' ? <>
                            <div class="col-sm-12 d-flex">
                                <div class="col-sm-12 d-flex">
                                    <div class="radio radio-primary radio-inline" style={{ width: "25%" }}>
                                        <input type="radio" id="startsunrise" value="1" name="startsunrise" checked={CommandObj?.MMD_CFG_SCH_START_ASTRO_OPT == 0 ? true : false} onChange={(e) => (setCommandObj({ ...CommandObj, MMD_CFG_SCH_START_ASTRO_OPT: e.target.checked ? 0 : 1 }))} disabled={inputDisable} />
                                        <label for="startsunrise" className='ml-2'> Sunrise </label>
                                    </div>

                                    <div class="radio radio-primary radio-inline" style={{ width: "25%" }}>
                                        <input type="radio" id="startsunset" value="2" name="startsunrise" checked={CommandObj?.MMD_CFG_SCH_START_ASTRO_OPT == 1 ? true : false} onChange={(e) => (setCommandObj({ ...CommandObj, MMD_CFG_SCH_START_ASTRO_OPT: e.target.checked ? 1 : 0 }))} disabled={inputDisable} />
                                        <label for="startsunset" className='ml-2'> Sunset </label>
                                    </div>
                                </div>
                            </div>
                            <div class=" d-flex">
                                <table className='col-12'>
                                    <thead>
                                        <tr>
                                            <th >Month</th>
                                            <th>Offset(min)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {monthArray.map((item, id) => (
                                            <tr>
                                                <td>{item.key}</td>
                                                <td>
                                                    <InputNumber min={-127} max={128} onChange={(e) => setmonthArray(monthArray.map((match) => match?.key == item.key ? { ...match, Startval: e } : match))} value={monthArray[id]?.Startval} disabled={inputDisable} />
                                                    {/* <input type="number" min="-127" max="128" placeholder="Enter Offset" class="form-control valid" id={item.key} name={item.key} value={item.Startval} onChange={(e)=>(Number(e.target.max)>Number(e.target.value)||Number(e.target.min)<Number(e.target.value)?setmonthArray(monthArray.map((match)=>match?.key==e.target.id?{...match,Startval:e.target.value}:match)):'')}/> */}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>

                            </div>
                        </>
                            : ''}

                    </div>
                    <div class="col-6">
                        <h5 class="col-sm-12">End Time</h5>
                        <div class="col-sm-12">
                            <div class="col-sm-12 d-flex">
                                <div class="radio radio-primary " style={{ width: "25%" }}>
                                    <input type="radio" id="endastro" value="1" name="endastro" checked={endType == 'endastro' ? true : false} onClick={() => setendType('endastro')} disabled={inputDisable} />
                                    <label for="endastro" className='ml-2' > Astro </label>
                                </div>

                                <div class="radio radio-primary radio-inline" style={{ width: "25%" }}>
                                    <input type="radio" id="endmanual" value="2" name="endmanual" onClick={() => setendType('endmanual')} checked={endType == 'endmanual' ? true : false} disabled={inputDisable} />
                                    <label for="endmanual" className='ml-2'  > Manual </label>
                                </div>
                            </div>
                        </div>
                        {endType == 'endmanual' ? <><div class="col-sm-12 startmanualdiv">
                            <div class="col-8">
                                <label class="">End Time</label>
                                <TimePicker showTime={{ use12Hours: true, format: "hh:mm A", defaultValue: CommandObj?.MMD_CFG_SCH_END_HH == 0 && CommandObj?.MMD_CFG_SCH_END_MM == 0 ? moment("00:00", "hh:mm A") : moment(CommandObj?.MMD_CFG_SCH_END_HH + ':' + CommandObj?.MMD_CFG_SCH_END_MM, 'HH:mm') }} format="hh:mm A" onChange={(e, dateString) => (dateString ? setCommandObj({ ...CommandObj, MMD_CFG_SCH_END_HH: moment(dateString, 'hh:mm A').format('HH'), MMD_CFG_SCH_END_MM: moment(dateString, 'hh:mm A').format('mm') }) : setCommandObj({ ...CommandObj, MMD_CFG_SCH_END_HH: moment('00:00', 'HH:mm').format('HH'), MMD_CFG_SCH_END_MM: moment('00:00', 'HH:mm').format('mm') }))} defaultOpenValue={CommandObj?.MMD_CFG_SCH_END_HH == 0 && CommandObj?.MMD_CFG_SCH_END_MM == 0 ? '' : moment(CommandObj?.MMD_CFG_SCH_END_HH + ':' + CommandObj?.MMD_CFG_SCH_END_MM, 'HH:mm')} disabled={inputDisable} />
                            </div>
                        </div></> : ''}
                        {endType == 'endastro' ? <>
                            <div class="col-sm-12 d-flex">
                                <div class="col-sm-12 d-flex">
                                    <div class="radio radio-primary radio-inline" style={{ width: "25%" }}>
                                        <input type="radio" id="sendsunrise" value="1" name="sendsunrise" checked={CommandObj?.MMD_CFG_SCH_END_ASTRO_OPT == 0 ? true : false} onChange={(e) => (setCommandObj({ ...CommandObj, MMD_CFG_SCH_END_ASTRO_OPT: e.target.checked ? 0 : 1 }))} disabled={inputDisable} />
                                        <label for="sendsunrise" className='ml-2'> Sunrise </label>
                                    </div>

                                    <div class="radio radio-primary radio-inline" style={{ width: "25%" }}>
                                        <input type="radio" id="endsunset" value="2" name="endsunrise" checked={CommandObj?.MMD_CFG_SCH_END_ASTRO_OPT == 1 ? true : false} onChange={(e) => (setCommandObj({ ...CommandObj, MMD_CFG_SCH_END_ASTRO_OPT: e.target.checked ? 1 : 0 }))} disabled={inputDisable} />
                                        <label for="endsunset" className='ml-2'> Sunset </label>
                                    </div>
                                </div>
                            </div>
                            <div class=" d-flex">
                                <table className='col-12'>
                                    <thead>
                                        <tr>
                                            <th >Month</th>
                                            <th>Offset(min)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {monthArray.map((item, id) => (
                                            <tr>
                                                <td>{item.key}</td>
                                                <td>
                                                    <InputNumber min={-127} max={128} onChange={(e) => setmonthArray(monthArray.map((match) => match?.key == item.key ? { ...match, Endval: e } : match))} value={monthArray[id]?.Endval} disabled={inputDisable} />
                                                    {/* <input type="text" placeholder="Enter Offset" class="form-control valid" id={item.key} name={item.key} value={item?.Endval} onChange={(e)=>(setmonthArray(monthArray.map((match)=>match?.key==e.target.id?{...match,Endval:e.target.value}:match)))}/> */}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>

                            </div>

                        </>
                            : ''}
                    </div>
                </div>
                <div class='row mt-4'>
                    <div class="col-6">
                        <label className="" for="checktextCS">Select Days</label>
                        <div className="selectgroup selectgroup-pills sidebar-color">
                            {daysArray.map((item) => (<label className="selectgroup-item ">
                                <input type="checkbox" name="icon-input" value={item?.value} id={item?.value} className="selectgroup-input select-sidebar" checked={CommandObj?.MMD_CFG_SCH_WEEK?.filter((itemInfo) => (itemInfo == item?.value)).length > 0 ? true : false} onChange={(e) => (!inputDisable ? setCommandObj({ ...CommandObj, MMD_CFG_SCH_WEEK: e.target.checked ? [...CommandObj?.MMD_CFG_SCH_WEEK, e.target.value] : CommandObj?.MMD_CFG_SCH_WEEK?.filter((itemInfo) => (itemInfo != e.target.value)) }) : '')} />
                                <span className="selectgroup-button selectgroup-btn-border selectgroup-button-icon " data-toggle="tooltip"
                                    data-original-title="Light Sidebar">{item?.label}</span>
                            </label>))
                            }

                        </div>
                    </div>
                </div>
                <div class="form-group d-flex mt-3">
                    <div className="selectgroup selectgroup-pills sidebar-color">
                        <label className="selectgroup-item">
                            <input type="checkbox" name="icon-input" className="selectgroup-input select-sidebar" onChange={(e) => (!inputDisable ? setCommandObj({ ...CommandObj, MMD_STATUS_LOAD1: e.target.checked == true ? 1 : 0 }) : '')} checked={CommandObj?.MMD_STATUS_LOAD1 == 1 ? true : false} />
                            <span className="selectgroup-button selectgroup-btn-border selectgroup-button-icon" data-toggle="tooltip"
                                data-original-title="Light Sidebar">Relay 1</span>
                        </label>
                        <label className="selectgroup-item">
                            <input type="checkbox" name="icon-input" className="selectgroup-input select-sidebar" onChange={(e) => (!inputDisable ? setCommandObj({ ...CommandObj, MMD_STATUS_LOAD2: e.target.checked == true ? 1 : 0 }) : '')} checked={CommandObj?.MMD_STATUS_LOAD2 == 1 ? true : false} />
                            <span className="selectgroup-button selectgroup-btn-border selectgroup-button-icon" data-toggle="tooltip"
                                data-original-title="Light Sidebar">Relay 2</span>
                        </label>
                        <label className="selectgroup-item">
                            <input type="checkbox" name="icon-input" className="selectgroup-input select-sidebar" onChange={(e) => (!inputDisable ? setCommandObj({ ...CommandObj, MMD_STATUS_LOAD3: e.target.checked == true ? 1 : 0 }) : '')} checked={CommandObj?.MMD_STATUS_LOAD3 == 1 ? true : false} />
                            <span className="selectgroup-button selectgroup-btn-border selectgroup-button-icon" data-toggle="tooltip"
                                data-original-title="Light Sidebar">Relay 3</span>
                        </label>
                    </div>
                </div>
                <div class="text-center" style={{ marginTop: "20px" }}>
                    {!inputDisable ? <input type="button" class="btn btn-danger " value={ShecdualID ? 'Update' : "Submit"} id="meter_data_mqtt_cancel_button" name="meter_data_mqtt_cancel_button" onClick={onClickCommand} /> : ''}
                    {!inputDisable ? <input type="button" class="btn btn-light ml-2" value="Reset" id="meter_data_mqtt_cancel_button" name="meter_data_mqtt_cancel_button" onClick={onResetAddCommand} /> : ''}
                </div>
            </Modal>
            <Modal title={CommandType} visible={isModalVisible} footer="" onCancel={handleCancel} width={800}>
                {modelID == 409 && <div className='mb-4'>
                    <span><b>Evc Type : </b>{props?.GSMdata?.METER_TYPE_NAME}</span>
                </div>}
                <div class="form-row">
                    {GetCmd && Object.entries(GetCmd).map(([key, val]) => (
                        val?.type === 'INT' ? <div class="form-group col-md-4">
                            <label for="inputState">{val.desc}:</label>
                            <input type="number" class="form-control" id={key} name="FirstName" placeholder={key} onChange={(e) => (setCmdvalue({ ...cmdValue, [e.target.id]: e.target.value ? parseInt(e.target.value) : 0 }))} value={cmdValue[key]} />
                        </div> : val?.type === 'DATETIME' ? <div class="form-group col-md-4">
                            <label for="inputState">{val.desc}:</label><br />
                            <DatePicker onChange={(e, dateString) => (setCmdvalue({ ...cmdValue, [key]: dateString ? parseInt(moment(dateString, 'DD-MM-YYYY').format("X")) : 0 }))} format='DD-MM-YYYY'
                                disabledDate={(current) => {
                                    return current.isAfter(moment())
                                }} style={{ width: '100%', padding: '8px' }} />
                        </div> : val?.type === "CONSTANT" ? <div class="form-group col-md-4">
                            <label for="inputState">{key}:</label><br />
                            <input type="text" class="form-control" id={key} name="FirstName" placeholder={key} value={val?.val} disabled />
                        </div> : val?.type === 'REDIO' ? <div class="form-group col-md-4">
                            <label for="inputState" className=' pr-2'>{val.desc}:</label><Switch checked={cmdValue[key] == 1 ? true : false} onChange={(e) => (setCmdvalue({ ...cmdValue, [key]: e == true ? 1 : 0 }))} />
                        </div> : <div class="form-group col-md-4">
                            <label for="inputState">{val.desc}:</label><br />
                            <input type="text" class="form-control" id={key} name="FirstName" placeholder={key} onChange={(e) => (setCmdvalue({ ...cmdValue, [e.target.id]: e.target.value }))} value={cmdValue[key]} />
                        </div>
                    ))}
                    {/* console.log(key,val) */}
                    {/* <div class="form-group col-md-6">
						<label for="inputState">Modem Name:</label>
						<input type="text" class="form-control" id="" name="FirstName" placeholder="Enter Modem Name" />
					</div> */}

                    {status[CommandType]?.CMD_TYPE == "METER_ASN" ? <p className='text-danger'>*If Meter ASN Set, will need to perform  configure again, and any existing data will be archived and can be accessed under "Extra->Old Table History". </p> : ''}
                </div>
                <div class="text-left mt-2">
                    <input type="submit" class="btn btn-danger" value="Submit" name="filter_customer_form" onClick={onSubmitCommand} />

                </div>
            </Modal>
            <Modal title="Command" visible={isModalVisible1} footer="" onCancel={handleCancel1} width={800}>

                <div class="form-row d-flex" style={{ flexDirection: 'column' }}>
                    <div className=''>
                        <span style={{ float: 'right' }}>
                            Last Updates {ShowLetestData[CommandStaticmsgJson?.CMDID] && ShowLetestData[CommandStaticmsgJson?.CMDID][0]?.SCR_TS_ENTRY ? moment.utc(ShowLetestData[CommandStaticmsgJson?.CMDID][0]?.SCR_TS_ENTRY).add(-moment().utcOffset(), "m").fromNow('ss:hh:mm') : '0'} <a class={`btn btn-icon icon-left btn-primary ${cmdRefreshLoader == true ? 'disabled btn-progress' : ''}`} onClick={onloadRefreshData}><i class="fas fa-refresh"></i></a></span>
                    </div>
                    {GetCmd && Object.entries(GetCmd).map(([key, val]) => (
                        val?.type === 'INT' ? <div class="form-group col-md-4">
                            <label for="inputState">{val.desc}:</label>
                            <input type="number" class="form-control" id={key} name="FirstName" placeholder={key} onChange={(e) => (setCmdvalue({ ...cmdValue, [e.target.id]: e.target.value ? parseInt(e.target.value) : 0 }))} value={cmdValue[key]} />
                        </div> : val?.type === 'DATETIME' ? <div class="form-group col-md-4">
                            <label for="inputState">{val.desc}:</label><br />
                            <DatePicker onChange={(e, dateString) => (setCmdvalue({ ...cmdValue, [key]: dateString ? parseInt(moment(dateString, 'DD-MM-YYYY').format("X")) : 0 }))} format='DD-MM-YYYY'
                                disabledDate={(current) => {
                                    return current.isAfter(moment())
                                }} style={{ width: '100%', padding: '8px' }} />
                        </div> : val?.type === 'REDIO' ? <div class="form-group col-md-4">
                            <label for="inputState" className=' pr-2'>{val.desc}:</label><Switch checked={cmdValue[key] == 1 ? true : false} onChange={(e) => (setCmdvalue({ ...cmdValue, [key]: e == true ? 1 : 0 }))} />
                        </div> : <div class="form-group col-md-4">
                            <label for="inputState">{val.desc}:</label><br />
                            <input type="text" class="form-control" id={key} name="FirstName" placeholder={key} onChange={(e) => (setCmdvalue({ ...cmdValue, [e.target.id]: e.target.value }))} value={cmdValue[key]} />
                        </div>
                    ))}
                    {/* console.log(key,val) */}
                    {/* <div class="form-group col-md-6">
						<label for="inputState">Modem Name:</label>
						<input type="text" class="form-control" id="" name="FirstName" placeholder="Enter Modem Name" />
					</div> */}
                    {console.log("CommandStaticmsgJson", CommandJson)}
                    {CommandJson?.CMD_TYPE == "METER_ASN" ? <p className='text-danger'>*If Meter ASN Set, will need to perform  configure again, and any existing data will be archived and can be accessed under "Extra->Old Table History". </p> : ''}
                </div>
                <div class="text-left mt-2">
                    <input type="submit" class="btn btn-danger" value="Submit" name="filter_customer_form" onClick={onSubmitCommand} />

                </div>
            </Modal>
            <Modal title={modelTitle} visible={modalDetails} className="my-modal-class" footer='' maskClosable={false} onCancel={() => (setmodalDetails(false))} width={1500}>
                <div className="table-responsive ">
                    <div id="table-1_wrapper" class="dataTables_wrapper no-footer">
                        <div class="dataTables_length" id="table_id_length">
                            <label>
                                Show{" "}
                                <select name="table_id_length" aria-controls="table_id" class="" onChange={(e) => (setPageSize(e.target.value))} value={PageSize}>
                                    <option value={10}>10</option>
                                    <option value={25}>25</option>
                                    <option value={50}>50</option>
                                    <option value={100}>100</option>
                                </select>{" "}
                                entries
                            </label>
                        </div>

                        <table id="table_id" className="tableCustom table table-striped">
                            <thead>
                                <tr>
                                    {ColumnName?.Modem_Data?.gsmmodemconfig?.length > 0 && ColumnName?.Modem_Data?.gsmmodemconfig?.map((col) => (col?.Display == 'Yes' ? <th className="" style={{ minWidth: col?.Type == 'datetime' ? "110px" : '' }}><BootstrapTooltip title={col?.Column}><span>{col?.Header}</span></BootstrapTooltip></th> : ''))}
                                </tr>
                            </thead>
                            <tbody>
                                {DetailConfig.length > 0 && DetailConfig.map((item, id) => (
                                    <tr className="trheigth" >
                                        {ColumnName?.Modem_Data?.gsmmodemconfig?.length > 0 && ColumnName?.Modem_Data?.gsmmodemconfig?.map((col) => (col?.Display == 'No' ? '' : col?.Type == 'datetime' ? <td style={{ minWidth: "100px" }}>{item[col.Column] ? moment.utc(item[col.Column]).format("DD-MM-YYYY hh:mm:ss A") : ''}</td> : <td style={col.Column == 'UNITS' ? { minWidth: '400px' } : col.Column == 'ERRORBIN' ? { maxWidth: '400px' } : {}}>{item[col.Column]}</td>))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                <><div className='d-flex justify-content-between'>
                    <div>Total Number Of Row {numberofPage}</div><Pagination
                        className="pagination-bar"
                        currentPage={currentPage}
                        totalCount={numberofPage || 1}
                        pageSize={PageSize}
                        onPageChange={(page) => setCurrentPage(page)}
                    /></div></>
            </Modal>
            <Modal title={modelTitle + '(' + (BuffernumberofPage ? BuffernumberofPage : 0) + ')'} visible={modalBufferDetails} className="my-modal-class" footer='' maskClosable={false} onCancel={() => {
                setmodalBufferDetails(false)
                setBufferPenddingData([])
                setBuffernumberofPage(0)
            }} width={1500}>
                <div className="table-responsive tw-h-screen tw-max-h-screen">
                    <div id="table-1_wrapper" class="dataTables_wrapper no-footer">
                        <div class="dataTables_length" id="table_id_length">
                            <label>
                                Show{" "}
                                <select name="table_id_length" aria-controls="table_id" class="" onChange={(e) => (setBufferPageSize(e.target.value))} value={PageSize}>
                                    <option value={10}>10</option>
                                    <option value={25}>25</option>
                                    <option value={50}>50</option>
                                    <option value={100}>100</option>
                                </select>{" "}
                                entries
                            </label>
                        </div>
                        <table id="table_id" className="tableCustom text-center table table-striped font-10">
                            <thead className='tw-sticky tw-z-50 tw-top-0 tw-bg-gray-100' s>
                                <tr>
                                    {ColumnName?.Modem_Data?.gsmbuffer?.length > 0 && ColumnName?.Modem_Data?.gsmbuffer?.map((col) => (col?.Display == "Yes" ? <th className="" style={{ minWidth: col?.Type == 'datetime' ? '110px' : '' }}><BootstrapTooltip title={col?.Column}><span>{col?.Header}</span></BootstrapTooltip></th> : ''))}
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {BufferPenddingData.length > 0 && BufferPenddingData.map((item, id) => (
                                    <tr className="trheigth">
                                        {ColumnName?.Modem_Data?.gsmbuffer?.length > 0 && ColumnName?.Modem_Data?.gsmbuffer?.map((col) => (col?.Display == "No" ? '' : col?.Type == 'datetime' ? col?.Column == 'METER_STARTTS' || col?.Column == 'METER_ENDTS' ? <td style={{ minWidth: "100px" }}>{item[col.Column] ? moment.unix(item[col.Column]).subtract(5.5, 'hours').format("DD-MM-YYYY hh:mm:ss A") : ''}</td> : <td style={{ minWidth: "100px" }}>{item[col.Column] ? moment.utc(item[col.Column]).format("DD-MM-YYYY hh:mm:ss A") : ''}</td> : col.Column == 'STATUS' ? <td>{item?.STATUS == 1 ? <div class="badge badge-success badge-shadow">Success</div> : item?.RETRYCOUNT < 0 && item?.STATUS == 0 ? <div class="badge badge-warning badge-shadow">Waiting For Downlink</div> : item?.STATUS == 2 ? <div class="badge badge-info badge-shadow">Canceled By User</div> : item?.STATUS == -1 ? <div class="badge badge-danger badge-shadow">Timeout</div> : item?.RETRYCOUNT >= 0 && item?.STATUS == 0 ? <div class="badge badge-primary text-white badge-shadow">Waiting For Uplink</div> : item?.STATUS == 3 ? <div class="badge badge-secondary text-white badge-shadow">CRC ERROR</div> : ''}</td> : col.Column == 'TRIGGERSOURCE' ? <td>{item?.TRIGGERSOURCE == 0 ? <div class="badge badge-warning badge-shadow">EVC Script</div> : item?.TRIGGERSOURCE == 1 ? <div class="badge badge-success badge-shadow">User</div> : ''}</td> : <td>{item[col.Column]}</td>))}

                                    </tr>
                                ))}

                            </tbody>
                        </table>
                        <><div className='d-flex justify-content-between'>
                            <div>Total Number Of Row {BuffernumberofPage || 0}</div>
                            < Pagination
                                className="pagination-bar"
                                currentPage={BuffercurrentPage}
                                totalCount={BuffernumberofPage || 0}
                                pageSize={BufferPageSize}
                                onPageChange={(page) => setBufferCurrentPage(page)}
                            /></div></>
                    </div>
                </div>

            </Modal>
            <section className="card-diff-section">
                <div className="section-body">
                    <div class="card" style={{ marginTop: "-20px" }}>
                        <h5 class="card-header expandable" style={{ padding: "2px 10px" }}>
                            <div class="col-md-6 col-12">
                                Update Firmware Version <span className={Lastfirmwarversion?.status===-1?'badge badge-warning':props?.GSMdata?.HEARTBEAT_LAST_DATA?.VERSTR == props?.GSMdata?.MODEM_FIRMWARE_NAME ? 'badge badge-success' : 'badge badge-warning'}>{Lastfirmwarversion?.status===-1?'Command is Queue':props?.GSMdata?.HEARTBEAT_LAST_DATA?.VERSTR == props?.GSMdata?.MODEM_FIRMWARE_NAME ? 'Update Success' : 'Update Pending'}</span>
                                
                            </div>
                            <div class="col-md-6 col-12">
                                <div class="pull-right">
                                    <button type="button" className='btn btn-info !tw-text-white' disabled={props?.GSMdata?.HEARTBEAT_LAST_DATA?.VERSTR == props?.GSMdata?.MODEM_FIRMWARE_NAME||Lastfirmwarversion?.status===-1} onClick={onSetQueueCommand}>Update</button>
                                </div>
                            </div>
                        </h5>
                        <div className='card-body tw-flex' style={{padding: '0px 25px 0px 25px',gap:'10px'}}>
                        <span>Current version : <b> {props?.GSMdata?.HEARTBEAT_LAST_DATA?.VERSTR||'-'}</b></span><span>-></span>
                        <span>Latest version : <b>{props?.GSMdata?.MODEM_FIRMWARE_NAME||'-'}</b></span>
                        </div>
                    </div>
                    {Command.length > 0 && Command.map((item, id) => (
                        <div class="card" style={{ marginTop: "-20px" }}>
                            <h5 class="card-header expandable" style={{ padding: "2px 10px" }}>
                                <div class="col-md-6 col-12">
                                    <a data-toggle="collapse" href={`#collapse-example-modem-${id + 1}`} aria-controls="collapse-example-modem-4" id="heading-example" class="d-block" onClick={() => (ongetConfigretionLetestData(item.CMDID, item.CMD_TYPE))}>
                                        <div class="row ">
                                            <div class="col">
                                                <h4 style={{ display: "inline" }}>{id + 1}. {item.CMD_TITLE}
                                                </h4>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div class="col-md-6 col-12">
                                    <div class="pull-right">
                                        <div class="buttons " style={{ marginTop: "10px" }}>
                                            {item.buttons && Object.entries(item.buttons).map(([key, val], id) => (
                                                val ? <button type="button" className='btn' id="mqtt_get_cmd_402" style={{ background: item?.Color ? item?.Color[id] : '' }} onClick={() => onSubmitGetCommand(item, key, val, key == 'Get' || key == 'Operate' ? true : false)}>{key}</button> : <button type="button" id="mqtt_get_cmd_402" className='btn' style={{ background: item?.Color ? item?.Color[id] : '' }} onClick={() => onSubmitCommandDirectBuffer(item.CMD_TITLE, key, val)}>{key}</button>
                                            ))}
                                            <button type="button" id="mqtt_get_cmd_402" className='btn btn-warning' onClick={() => { onBufferPenddingData(item.CMD_TITLE, item.CMDID, item?.CMD_TYPE) }}>Queue({BufferQueue[item?.CMD_TYPE + item?.CMDID]})</button>
                                            {/*<button type="button" id="mqtt_get_ajax_loader_402" class="btn btn-info text-white" style={{display:"inline"}}><i class="fa fa-spinner fa-spin"></i> Loading..</button> */}
                                            {/* <button type="button" id="mqtt_get_cmd_402" class="btn btn-warning" >Set</button> */}
                                            {/* <button type="button" id="mqtt_get_ajax_loader_402" class="btn btn-warning text-white" style={{display:"inline"}}><i class="fa fa-spinner fa-spin"></i> Loading..</button> */}

                                            <a class="btn btn-success" onClick={() => (onClickDataInConfig(item.CMD_TITLE, item.CMD_TYPE, item.CMDID))}><span><i class="fas fa-stopwatch text-white"></i></span></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="clearfix"></div>
                            </h5>
                            <div id={`collapse-example-modem-${id + 1}`} class="collapse" aria-labelledby="heading-example" >
                                <div class="card-body">
                                    {ShowLetestData[item?.CMD_TYPE + item?.CMDID]?.length > 0 ? <div className="table-responsive ">
                                        <table id="table_id" className="tableCustom table table-striped">
                                            <thead>
                                                <tr>
                                                    {ColumnName?.Modem_Data?.gsmmodemconfig?.length > 0 && ColumnName?.Modem_Data?.gsmmodemconfig?.map((col) => (col?.Display == 'Yes' ? <th className="" style={{ minWidth: col?.Type == 'datetime' ? "110px" : '' }}><BootstrapTooltip title={col?.Column}><span>{col?.Header}</span></BootstrapTooltip></th> : ''))}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {ShowLetestData[item?.CMD_TYPE + item?.CMDID]?.length > 0 && ShowLetestData[item?.CMD_TYPE + item?.CMDID]?.map((item, id) => (
                                                    <tr className="trheigth" >
                                                        {ColumnName?.Modem_Data?.gsmmodemconfig?.length > 0 && ColumnName?.Modem_Data?.gsmmodemconfig?.map((col) => (col?.Display == 'No' ? '' : col?.Type == 'datetime' ? <td style={{ minWidth: "100px" }}>{item[col.Column] ? moment.utc(item[col.Column]).format("DD-MM-YYYY hh:mm:ss A") : ''}</td> : <td style={col.Column == 'UNITS' ? { minWidth: '400px' } : col.Column == 'ERRORBIN' ? { maxWidth: '400px' } : {}}>{item[col.Column]}</td>))}
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div> : <div class="col-12 latest-data-space-4"><b>No Data Available</b></div>}

                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </section>
        </>
    )
}
