import { useEffect } from "react"
import { getRolesById } from "../../Commoncomponet/Utils"

function BlankHeader(props) {
    const userInfo = JSON.parse(window.localStorage.getItem('user_info') || '{}')


    const onHandaleRoles = async () => {
        props?.setIsRouteLoading(true)
        const data = await getRolesById(userInfo?.ROLE)
        if (data.success === true) {
            const obj = data.data.data[0]
            const Roles = { ...obj, ROLES_PERMISSIONS_CATEGORY0: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY0), ROLES_PERMISSIONS_CATEGORY5: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY5), ROLES_PERMISSIONS_CATEGORY2: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY2), ROLES_PERMISSIONS_CATEGORY3: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY3), ROLES_PERMISSIONS_CATEGORY1: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY1), ROLES_PERMISSIONS_CATEGORY4: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY4), ROLES_OTHER_PERMISSIONS: JSON.parse(obj.ROLES_OTHER_PERMISSIONS) }
            props.setmanuPermission(JSON.parse(obj.ROLES_MENU_PERMISSIONS||'{}'))
            props?.setIsRouteLoading(false)
        } else {
            console.log('something want wrong')
            props?.setIsRouteLoading(false)
        }
    }

    useEffect(()=>{
        onHandaleRoles()
    },[])

    return (<>
    </>)
}

export default BlankHeader