import React from 'react';
import Minutes from '../minutes-select';
import Hour from '../hour-select';

const WeeklyCron = (props) => {
    
    const onAtHourChange = (e) => {
        if(props.disabled) { return }
        let val = props.value;
        val[0] = '0';
        val[2] = `${e.target.value}`;
        props.onChange(val);
    }

    const onAtMinuteChange = (e) => {
        if(props.disabled) { return }
        let val = props.value;
        val[0] = '0';
        val[1] = `${e.target.value}`;
        props.onChange(val);
    }

    const onCheck = (e) => {
        if(props.disabled) { return }
        let val = props.value;
        val[0] = '0';
        if(e.target.checked) {
            onDayChecked(val, e);
        } else {
            onDayUnChecked(val, e);           
        }
        props.onChange(val);
    }

    const onDayChecked = (val, e) => {
        val[2] = (`${val[2]}`.split('/').length > 1) ? '0' : val[2].toString();
        val[3] = '?';
        val[4] = '*';
        if (val[5] === '*' || val[5] === '?' || val[5] === 'MON-FRI') {
            val[5] = e.target.value;
        } else {
            val[5] = val[5] + '!' + e.target.value;
        }
    }

    const onDayUnChecked = (val, e) =>  {
        let valFive = val[5].split('!');
        if (valFive.length > 1) {
            valFive.splice(valFive.indexOf(e.target.value), 1);
            valFive = valFive.toString().replace(/,/g, '!');
        }
        else {
            valFive = '*';
        }
        val[5] = valFive;
    }

    const translateFn = props.translate;

    return (
        <div className="container-fluid">
            <div className="well well-small row">
                <div className="span6 col-sm-6">
                    <div className="text_align_left">
                        <input 
                            className='min_height_auto' 
                            type="checkbox" 
                            value="MON" 
                            onChange={onCheck} 
                            checked={props.value[5].search('MON') !== -1}  
                            disabled={props.disabled}
                        />{translateFn('Monday')}<br/>
                         <input 
                            className='min_height_auto' 
                            type="checkbox" 
                            value="TUE" 
                            onChange={onCheck} 
                            checked={props.value[5].search('TUE') !== -1} 
                            disabled={props.disabled}
                        />{translateFn('Tuesday')}<br />
                        <input 
                            className='min_height_auto' 
                            type="checkbox" 
                            value="WED" 
                            onChange={onCheck} 
                            checked={props.value[5].search('WED') !== -1}   
                            disabled={props.disabled}
                        />{translateFn('Wednesday')}<br/>
                         <input 
                            className='min_height_auto' 
                            type="checkbox" 
                            value="THU" 
                            onChange={onCheck} 
                            checked={props.value[5].search('THU') !== -1} 
                            disabled={props.disabled}
                        />{translateFn('Thursday')}<br />
                        <input 
                            className='min_height_auto' 
                            type="checkbox" 
                            value="FRI" 
                            onChange={onCheck} 
                            checked={props.value[5].search('FRI') !== -1} 
                            disabled={props.disabled}
                        />{translateFn('Friday')}<br/>
                        <input 
                            className='min_height_auto' 
                            type="checkbox" 
                            value="SAT" 
                            onChange={onCheck} 
                            checked={props.value[5].search('SAT') !== -1} 
                            disabled={props.disabled}
                        />{translateFn('Saturday')}<br/>
                        <input 
                            className='min_height_auto' 
                            type="checkbox" 
                            value="SUN" 
                            onChange={onCheck} 
                            checked={props.value[5].search('SUN') !== -1} 
                            disabled={props.disabled}
                        />{translateFn('Sunday')}
                        
                    </div>
                </div>
                
            </div>
            {translateFn('Start time')}
            <Hour onChange={onAtHourChange} value={props.value[2]}  disabled={props.disabled}/>
            <Minutes onChange={onAtMinuteChange} value={props.value[1]} disabled={props.disabled}/>
        </div>
    );
}

export default WeeklyCron;
