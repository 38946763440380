import React, { useState } from 'react'
import { ValidateReportToken, geturls3 } from '../../Commoncomponet/Utils';
import { toast } from 'react-toastify';
import { dataFormateForHCR, exportCSVFile, fetchData, generateExcelByData, summeryReportDataFormatr } from '../../Commoncomponet/common';
import ExcelGenerationModal from './ReportsModal';

export default function DownloadGSMReport() {

    const [isLoading, setIsLoading] = useState(false);
	const [progress, setProgress] = useState(0);
	const [fileType, setFileType] = useState(null);

    React.useEffect(() => {
        const params = new URLSearchParams(window.location.search) 
		console.log(params)
        let report_token = params.get('token')  
        onValidToken(report_token)
    }, [])

    const onValidToken=async(report_token)=>{
        const data=await ValidateReportToken(report_token)
        if(data.success==true){
            const {file_name,report_extention,report_type}=data.data.data
            onDownloadFiles(file_name,report_extention=='csv'?'csv':'Excel',report_type)
        }else{
            toast.error('Invalid Token')
        }
    }


    
	const onDownloadFiles = async (name, type, reportType) => {

		try {
			setFileType(name.split('.')[0]+'.'+type)
			setProgress(0)
			setIsLoading(true)
			var fillBgLookup = {};

			if(reportType === 'RTI5656754456'){
				fillBgLookup['No'] = {
					bgColor:"FFFFC7CE",patternType:"solid"
				}
			}
			const data1 = await new Promise((resolve, reject) => {
				let geturlsProgress = 0;
				const geturlsInterval = setInterval(() => {
					geturlsProgress += 5; // Simulate progress
					if (geturlsProgress > 45) {
						clearInterval(geturlsInterval);
					}
					setProgress(geturlsProgress);
				}, 100);

				geturls3({
					bucketName: 'gums.memighty.com',
					objectKey: `GSM_CONSUMER_REPORT/${name}`,
					expiration: 60
				}).then(result => {
					clearInterval(geturlsInterval);
					setProgress(10); // Set to 50% after geturls3 completes
					resolve(result);
				}).catch(error => {
					clearInterval(geturlsInterval);
					reject(error);
				});
			});
			const responseData = await fetchData(data1?.data?.data, progress => {
				setProgress((progress * 0.50) + 10);
			});
			console.log(responseData)


			if (type === 'Excel') {
				const finalData = processData(responseData, reportType)
				console.log(finalData)
				generateExcelByData({ data: finalData, reportName: name.split('.')[0], reportType: reportType === 'RTI3243454563' || responseData[0]?.header ? "array" : 'JSON', headerConfig :responseData[0]?.header , fillBgLookup:fillBgLookup, cancelToken :false,setProgress, 
				setIsLoading,download_type:'MAIL_DOWNLOAD'})
			}

			if (type === 'csv') {
				if (responseData[0]?.data.length > 0) {
					var finalData = responseData[0]?.data
					if(responseData[0]?.header){
						finalData	= processData(responseData, reportType)
					}
					exportCSVFile(responseData[0]?.header ? null : Object.keys(responseData[0]?.data[0]), finalData, name.split('.')[0],false)
					setProgress(100)
					setIsLoading(false)
					toast.success(`${name.split('.')[0]}.csv generated successfully`);
					window.close()
				} else {
					toast.error('Data Not available')
				}
			}
			 
		
		} catch (error) {
			toast.error(error.message);
			setIsLoading(false);

		}



	};

	function processData(rawData, reportType) {
		if (reportType === 'RTI3243454563') {
			return summeryReportDataFormatr(rawData[0]?.data);
		}
		if (rawData[0]?.header) {
			console.log(rawData)
			return dataFormateForHCR(rawData);
		}
		return rawData[0]?.data;
	}
  return (
   <>
   <ExcelGenerationModal
				fileType={fileType}
				progress={progress}
				isLoading={isLoading}
				// onCancel={handleCancel}
			/>
   </>
  )
}
