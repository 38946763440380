import React, { useEffect, useRef, useState } from 'react'
import ChartLoaders from '../../Commoncomponet/Loaders/ChartLoader'
import Chart from "react-apexcharts";
import moment from 'moment';
import { BootstrapTooltip } from '../../Commoncomponet/common';
import { GetTableNameWiseChartDataByDate, GetTableNameWiseChartDataByStartDateEndDate, getColumnWiseMeterDataInConsumer, getSalesSummaryTableData, getStartAndEndDateByTable } from '../../Commoncomponet/Utils';
import { DatePicker, Modal, Space } from 'antd';
import { Bar } from 'react-chartjs-2';
import { toast } from 'react-toastify';
import loaderGif from '../../assets/img/loading_chart.gif'

export const GSMMeterDashboard = (props) => {
    const { GSMdata } = props

    const colors = ["#67b7dcd9", "#6794dcd9", "#6771dcd9", "#8067dcd9", "#a367dcd9", "#c767dcd9", "#dc67ced9", "#6771dcd9"]
    const [DailyConsumptionChart, setDailyConsumptionChart] = useState({ date: [], total: [] })
    const [HourlyConsumptionChart, setHourlyConsumptionChart] = useState({ date: [], total: [] })
    const [commonChart, setcommonChart] = useState({ date: [], total: [] })
    const [ModelHeader,setModelHeader]=useState('')
    const [expireDate,setExpireDate]=useState({})
    const [dates,setDates]=useState({start:'',end:''})
    const [isModalVisible,setIsModalVisible]=useState(false)
    const [ChartLoader,setChartLoader]=useState(false)
    const [salesData,setsalesData]=useState({})
    const [salesLoader,setsalesLoader]=useState(false)
    const [salesDate,setsalesDate]=useState(moment().add(-1, 'days').format('DD-MM-YYYY'))

    const onGetdailyConsumptionData = async () => {
		setDailyConsumptionChart({total:[],date:[],res_code:''})
        var fild=''
        if (GSMdata.SCRIPTDECODE_ID=='MODUID1671705090942_METUID1665810585135') {
            fild='READING_RECORD'
        }else if( GSMdata.SCRIPTDECODE_ID=='MODUID1655982881372_METUID1660278946950'||GSMdata.SCRIPTDECODE_ID=='MODUID1655982881372_METUID1660279003632'||GSMdata.SCRIPTDECODE_ID=='MODUID1655982881372_METUID1675315671021'||GSMdata.SCRIPTDECODE_ID=='MODUID1655982881372_METEID1681294010406'||GSMdata.SCRIPTDECODE_ID=='MODUID1655982881372_METEID1712827870326'){
            fild='VB_DIFF_DAILY_VBD_D'
        }else if(GSMdata.SCRIPTDECODE_ID=='MODUID1655982881372_METUID1660276730566'){
            fild='DVB_HC'
        }else if(GSMdata.SCRIPTDECODE_ID=='MODUID1655982881372_METEID1676466785467'||GSMdata.SCRIPTDECODE_ID=='MODUID1655982881372_METUID1662788927078'){
            fild='DVB_SUM'
        }else if(GSMdata.SCRIPTDECODE_ID=='MODUID1655982881372_METEID1680324563157'||GSMdata.SCRIPTDECODE_ID=='MODUID1655982881372_METEID1684236582202'||GSMdata.SCRIPTDECODE_ID=='MODUID1655982881372_METEID1684236463648'){
            fild='COR_VOLUME_M3'
        }else if(GSMdata?.SCRIPTDECODE_ID=='MODUID1655982881372_METEID1714730047327'){
            fild='DVB'
        }else{
            fild='CONC_CONS'
        }
		const data = await GetTableNameWiseChartDataByDate({ date: 7, tableName: GSMdata?.UNIQUE_TABLE_NAME?.dailydata, scriptid: GSMdata?.SCRIPTDECODE_ID, col: 'METER_ENDTS', fild: fild })
		if (data.success === true) {
			setDailyConsumptionChart({ total: data.data.data.map((item) => (Number(item.lengths).toFixed(2))), date: data.data.data.map((item) => (moment(item.date, "YYYY-MM-DD").add(-1,'days').format("DD-MM-YYYY"))),res_code:'' })
		} else {
			console.log('something want wrong')
			setDailyConsumptionChart({total:[],date:[],res_code:data?.err?.response?.status})
		}
	}

    const ongetSalesSummaryTableData=async(info)=>{
        setsalesLoader(true)
        var fild=''
        if (GSMdata.SCRIPTDECODE_ID=='MODUID1671705090942_METUID1665810585135') {
            fild='READING_RECORD'
        }else if( GSMdata.SCRIPTDECODE_ID=='MODUID1655982881372_METUID1660278946950'||GSMdata.SCRIPTDECODE_ID=='MODUID1655982881372_METUID1660279003632'||GSMdata.SCRIPTDECODE_ID=='MODUID1655982881372_METUID1675315671021'||GSMdata.SCRIPTDECODE_ID=='MODUID1655982881372_METEID1681294010406'||GSMdata.SCRIPTDECODE_ID=='MODUID1655982881372_METEID1712827870326'){
            fild='VB_DIFF_DAILY_VBD_D'
        }else if(GSMdata.SCRIPTDECODE_ID=='MODUID1655982881372_METUID1660276730566'){
            fild='DVB_HC'
        }else if(GSMdata.SCRIPTDECODE_ID=='MODUID1655982881372_METEID1676466785467'||GSMdata.SCRIPTDECODE_ID=='MODUID1655982881372_METUID1662788927078'){
            fild='DVB_SUM'
        }else if(GSMdata.SCRIPTDECODE_ID=='MODUID1655982881372_METEID1680324563157'||GSMdata.SCRIPTDECODE_ID=='MODUID1655982881372_METEID1684236582202'||GSMdata.SCRIPTDECODE_ID=='MODUID1655982881372_METEID1684236463648'){
            fild='COR_VOLUME_M3'
        }else if(GSMdata?.SCRIPTDECODE_ID=='MODUID1655982881372_METEID1714730047327'){
            fild='DVB'
        }else{
            fild='CONC_CONS'
        }
        const data=await getSalesSummaryTableData({ date: 7, tableName: GSMdata?.UNIQUE_TABLE_NAME?.dailydata, scriptid: GSMdata?.SCRIPTDECODE_ID, col: 'METER_ENDTS', fild: fild,...info })
        if(data.success==true){
            setsalesData(data.data.data)
        setsalesLoader(false)
        }else{
            console.log('Something wait wrong')
        setsalesLoader(false)
        }
    }

    const onGetHourlyConsumptionData = async () => {
		setHourlyConsumptionChart({total:[],date:[],res_code:''})
        var fild=''
        if (GSMdata.SCRIPTDECODE_ID=='MODUID1671705090942_METUID1665810585135') {
            fild='HOURLY_READING_RECORD'
        }else if( GSMdata.SCRIPTDECODE_ID=='MODUID1655982881372_METUID1660278946950'||GSMdata.SCRIPTDECODE_ID=='MODUID1655982881372_METUID1660279003632'||GSMdata.SCRIPTDECODE_ID=='MODUID1655982881372_METUID1675315671021'||GSMdata.SCRIPTDECODE_ID=='MODUID1655982881372_METEID1681294010406'||GSMdata.SCRIPTDECODE_ID=='MODUID1655982881372_METEID1712827870326'){
            fild='VB_DIFF_HOURLY_VBD_H'
        }else if(GSMdata.SCRIPTDECODE_ID=='MODUID1655982881372_METUID1660276730566'){
            fild='DVB_HC'
        }else if(GSMdata.SCRIPTDECODE_ID=='MODUID1655982881372_METEID1676466785467'||GSMdata.SCRIPTDECODE_ID=='MODUID1655982881372_METUID1662788927078'){
            fild='DVB_SUM'
        }else if(GSMdata.SCRIPTDECODE_ID=='MODUID1655982881372_METEID1680324563157'||GSMdata.SCRIPTDECODE_ID=='MODUID1655982881372_METEID1684236582202'||GSMdata.SCRIPTDECODE_ID=='MODUID1655982881372_METEID1684236463648'){
            fild='COR_VOLUME_INCREMENTAL_M3'
        }else if(GSMdata?.SCRIPTDECODE_ID=='MODUID1655982881372_METEID1714730047327'){
            fild='DVB'
        }else{
            fild='CONC_CONS'
        }
		const data = await getColumnWiseMeterDataInConsumer({ date: 7, tableName: GSMdata?.UNIQUE_TABLE_NAME?.hourlydata, scriptid: GSMdata?.SCRIPTDECODE_ID, col: 'METER_ENDTS', fild: fild })
		if (data.success === true) {
			setHourlyConsumptionChart({ total: data.data.data.map((item) => (Number(item.lengths).toFixed(2))), date: data.data.data.map((item) => (moment.utc(item.date).format("DD-MM-YYYY hh:mm:ss A"))),res_code:'' })
		} else {
			console.log('something want wrong')
			setHourlyConsumptionChart({total:[],date:[],res_code:data?.err?.response?.status})
		}
	}

    useEffect(()=>{
        onGetdailyConsumptionData()
        onGetHourlyConsumptionData()
        ongetSalesSummaryTableData({start:moment(moment(salesDate,'DD-MM-YYYY').add(1,'day').format('DD-MM-YYYY'),'DD-MM-YYYY').add(-15,'day').format('DD-MM-YYYY'),end:moment(salesDate,"DD-MM-YYYY").add(1,'day').format('DD-MM-YYYY')})
    },[])

    const Hardbeatstate = {
        options: {
            legend: {
                show: false,

            },

            plotOptions: {
                bar: {
                    distributed: true,
                    borderRadius: 5,
                    borderRadiusApplication: 'end',
                    dataLabels: {
                        position: 'top',
                        offsetY: 30,
                        fontSize: '10px'
                    },

                }
            },
            dataLabels: {
                offsetY: 10
            },
            xaxis: {
                floating: false,
                categories: DailyConsumptionChart.date.map((item) => ([item, `(${moment(item, 'DD-MM-YYYY').format('ddd')})`])),
                labels: {
                    show: true,
                    rotate: -90,
                    rotateAlways: true,
                    offsetX: 0,
                    offsetY: 3,
                },
                // tickPlacement: 'on'
            },
            fill: {
                type: 'bar',
                gradient: {
                    shade: 'dark',
                    type: "vertical",
                    shadeIntensity: 0.5,
                    gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
                    inverseColors: true,
                    opacityFrom: 1,
                    opacityTo: 1,
                    stops: [0, 50, 100],
                    colorStops: []
                }
            },
            chart: {
                toolbar: {
                    show: false
                }
            },
            noData: {
                text: "Loading...",
                align: 'center',
                verticalAlign: 'middle',
                offsetX: 0,
                offsetY: 0,
                style: {
                    color: "#000000",
                    fontSize: '14px',
                    fontFamily: "Helvetica"
                },
            },
            colors: colors
            // colors: [
            //     "#99ccdd", "#e9cbff", "#ffcfcb", "#99ccee", "#99bbee", "#aabbee", "#aaddee", "#a4daef"
            // ]
        },

        series: [
            {
                name: "SCM",
                data: DailyConsumptionChart.total
            }
        ]
    };

    const Hourlystate = {
        options: {
            legend: {
                show: false,
            },
            plotOptions: {
                bar: {
                    distributed: true,
                    borderRadius: 5,
                    borderRadiusApplication: 'end',
                    dataLabels: {
                        position: 'top',
                        offsetY: 30,
                        fontSize: '10px'
                    },
                }
            },
            dataLabels: {
                offsetY: 9
            },
            xaxis: {
                floating: false,
                categories: HourlyConsumptionChart.date.map((item) => ([moment(item, 'DD-MM-YYYY hh:mm:ss A').format('DD-MM-YYYY'),moment(item, 'DD-MM-YYYY hh:mm:ss A').format('hh:mm:ss A (ddd)')])),
                labels: {
                    show: true,
                    rotate: -90,
                    rotateAlways: true,
                    offsetX: 0,
                    offsetY: -5,
                },
                style:{
                    fontSize: '8px'
                }
                // tickPlacement: 'on'
            },
            fill: {
                type: 'bar',
                gradient: {
                    shade: 'dark',
                    type: "vertical",
                    shadeIntensity: 0.5,
                    gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
                    inverseColors: true,
                    opacityFrom: 1,
                    opacityTo: 1,
                    stops: [0, 50, 100],
                    colorStops: []
                }
            },
            chart: {
                toolbar: {
                    show: false
                }
            },
            noData: {
                text: "Loading...",
                align: 'center',
                verticalAlign: 'middle',
                offsetX: 0,
                offsetY: 0,
                style: {
                    color: "#000000",
                    fontSize: '14px',
                    fontFamily: "Helvetica"
                },
            },
            colors: colors
            // colors: [
            //     "#99ccdd", "#e9cbff", "#ffcfcb", "#99ccee", "#99bbee", "#aabbee", "#aaddee", "#a4daef"
            // ]
        },

        series: [
            {
                name: "SCM",
                data: HourlyConsumptionChart.total
            }
        ]
    };
	const onChange = (date, dateString) => {
		setDates({ ...dates, start: dateString })
	};
	const onChanges = (date, dateString) => {
		setDates({ ...dates, end: dateString })
	};
    const onOpenModal = (tableName, col, header) => {
		// onGetTableNameWiseChartDataBy15Date()
		ongetStartAndEndDateByTable(tableName, col)
		setModelHeader(header)
	}

    const ongetStartAndEndDateByTable = async (tableName, col, modal) => {
		document.body.style.cursor = 'want'
		const data = await getStartAndEndDateByTable({ tableName: tableName, col: col })
		if (data.success === true) {
			setExpireDate(data.data)
			if (!modal) {
				setIsModalVisible(true)
			}
			document.body.style.cursor = 'default'
		} else {
			console.log("something want wrong")
			document.body.style.cursor = 'default'
		}
	}

    const handleCancel = () => {
		setIsModalVisible(false);
		setcommonChart({ total: [], date: [] })
		setChartLoader(false)
	};
    const chartRef = useRef()
	const myChart = chartRef.current;
	const zoomIn = () => {
		if (myChart != undefined || myChart != null) {
			myChart.zoom(1.2);
		}
	}
	const zoomOut = () => {
		if (myChart != undefined || myChart != null) {
			myChart.zoom(0.8);
		}
	}

	const panLeft = () => {
		if (myChart != undefined || myChart != null) {
			myChart.pan({ x: 400 }, undefined, 'default');
		}
	}
	const panRight = () => {
		if (myChart != undefined || myChart != null) {
			myChart.pan({ x: -400 }, undefined, 'default');
		}
	}
	setTimeout(() => {
		if (myChart != undefined) {
			myChart.zoom(1.49);
			myChart.pan({
				x: 500
			}, undefined, 'default');

			// myChart.pan({
			// 	x: - (Number.MAX_SAFE_INTEGER)
			// }, undefined, 'default');

		}

	}, 10)

    const expandChartoptions = {
		responsive: true,
		maintainAspectRatio: false,
		scales: {
			y: {
				grid: {
					color: 'rgba(128,151,177, 0.3)',
					borderDash: [3, 3],
					drawTicks: false,
					borderColor: '#424b5f',
				},
				ticks: {
					align: "center",
					padding: 10,
				},
			},
			x: {
				grid: {
					color: 'rgba(128,151,177, 0.3)',
					borderDash: [3, 5],
					drawTicks: false,
					borderColor: '#424b5f'
				},
				ticks: {
					align: "center",
					padding: 10,
				}
			}
		},
		plugins: {
			legend: {
				position: 'bottom',
				labels: {
					pointStyleWidth: 10,
					usePointStyle: true,
					pointStyle: 'rectRounded'
				}
			},
			zoom: {
				zoom: {
					wheel: {
						enabled: true // SET SCROOL ZOOM TO TRUE
					},
					mode: "x",
				},
				pan: {
					enabled: true,
					mode: "x",
				}
			},
			datalabels: {
				align: 'end',
				anchor: 'end',
				clamp: true,
				color: "black",
				offset: 5,
				padding: {
					top: 0,
					bottom: 0
				},
				

			}
		},
		layout: {
			padding: {
				left: 50,
				right: 50,
				top: 20
			}
		},
	};

    const expandChartData = {
		labels: commonChart.date,
		datasets: [
			{
				label: ModelHeader,
				backgroundColor: ["rgba(103, 183, 220, 0.85)", "rgba(103, 148, 220, 0.85)", "rgba(103, 113, 220, 0.85)", "rgba(128, 103, 220, 0.85)", "rgba(163, 103, 220, 0.85)", "rgba(199, 103, 220, 0.85)", "rgba(220, 103, 206, 0.85)", "rgba(103, 113, 220, 0.85)"],
				data: commonChart.total,
			},
		],
	};
    const onGetDateWiseChart=async()=>{
        const x = new Date(moment(dates.start,'DD-MM-YYYY').format('YYYY-MM-DD'));
const y = new Date(moment(dates.end,'DD-MM-YYYY').format('YYYY-MM-DD'));
console.log(x>y,x,y);
            if(x>y){
            return	toast.warn('"Start Date" Must be Less than or Equal to "End Date"')
            }
		setChartLoader(true)
        var tableName=''
        var col=''
        var fild=''
        if(ModelHeader=='Daily Consumption (in SCM)'){
            tableName=GSMdata.UNIQUE_TABLE_NAME.dailydata
         col='METER_ENDTS'
        if (GSMdata.SCRIPTDECODE_ID=='MODUID1671705090942_METUID1665810585135') {
            fild='READING_RECORD'
        }else if( GSMdata.SCRIPTDECODE_ID=='MODUID1655982881372_METUID1660278946950'||GSMdata.SCRIPTDECODE_ID=='MODUID1655982881372_METUID1660279003632'||GSMdata.SCRIPTDECODE_ID=='MODUID1655982881372_METUID1675315671021'||GSMdata.SCRIPTDECODE_ID=='MODUID1655982881372_METEID1681294010406'||GSMdata.SCRIPTDECODE_ID=='MODUID1655982881372_METEID1712827870326'){
            fild='VB_DIFF_DAILY_VBD_D'
        }else if(GSMdata.SCRIPTDECODE_ID=='MODUID1655982881372_METUID1660276730566'){
            fild='DVB_HC'
        }else if(GSMdata.SCRIPTDECODE_ID=='MODUID1655982881372_METEID1676466785467'||GSMdata.SCRIPTDECODE_ID=='MODUID1655982881372_METUID1662788927078'){
            fild='DVB_SUM'
        }else if(GSMdata.SCRIPTDECODE_ID=='MODUID1655982881372_METEID1680324563157'||GSMdata.SCRIPTDECODE_ID=='MODUID1655982881372_METEID1684236582202'||GSMdata.SCRIPTDECODE_ID=='MODUID1655982881372_METEID1684236463648'){
            fild='COR_VOLUME_M3'
        }else if(GSMdata?.SCRIPTDECODE_ID=='MODUID1655982881372_METEID1714730047327'){
            fild='DVB'
        }else{
            fild='CONC_CONS'
        }

        
		const data=await GetTableNameWiseChartDataByStartDateEndDate({ startDate:  moment(dates.start, "DD-MM-YYYY").format("YYYY-MM-DD"), endDate:  moment(dates.end, "DD-MM-YYYY").format("YYYY-MM-DD"), fild: fild, col: col, scriptid: GSMdata?.SCRIPTDECODE_ID, tableName: tableName, TYPE: '' })
        if(data.success==true){
            setcommonChart({total:data.data.data.map((item) => (Number(item.lengths).toFixed(2))),date:data.data.data.map((item) => ([moment(item.date, "YYYY-MM-DD").format("DD-MM-YYYY"),`(${moment(item.date, "YYYY-MM-DD").format("ddd")})`]))})
		    setChartLoader(false)
        }else{
            setcommonChart({})
		    setChartLoader(false)
        }
    }else if(ModelHeader=='Hourly Consumption (in SCM)'){
        tableName=GSMdata.UNIQUE_TABLE_NAME.hourlydata
         col='METER_ENDTS'
        if (GSMdata.SCRIPTDECODE_ID=='MODUID1671705090942_METUID1665810585135') {
            fild='HOURLY_READING_RECORD'
        }else if( GSMdata.SCRIPTDECODE_ID=='MODUID1655982881372_METUID1660278946950'||GSMdata.SCRIPTDECODE_ID=='MODUID1655982881372_METUID1660279003632'||GSMdata.SCRIPTDECODE_ID=='MODUID1655982881372_METUID1675315671021'||GSMdata.SCRIPTDECODE_ID=='MODUID1655982881372_METEID1681294010406'||GSMdata.SCRIPTDECODE_ID=='MODUID1655982881372_METEID1712827870326'){
            fild='VB_DIFF_HOURLY_VBD_H'
        }else if(GSMdata.SCRIPTDECODE_ID=='MODUID1655982881372_METUID1660276730566'){
            fild='DVB_HC'
        }else if(GSMdata.SCRIPTDECODE_ID=='MODUID1655982881372_METEID1676466785467'||GSMdata.SCRIPTDECODE_ID=='MODUID1655982881372_METUID1662788927078'){
            fild='DVB_SUM'
        }else if(GSMdata.SCRIPTDECODE_ID=='MODUID1655982881372_METEID1680324563157'||GSMdata.SCRIPTDECODE_ID=='MODUID1655982881372_METEID1684236582202'||GSMdata.SCRIPTDECODE_ID=='MODUID1655982881372_METEID1684236463648'){
            fild='COR_VOLUME_INCREMENTAL_M3'
        }else if(GSMdata?.SCRIPTDECODE_ID=='MODUID1655982881372_METEID1714730047327'){
            fild='DVB'
        }else{
            fild='CONC_CONS'
        }
        const data=await getColumnWiseMeterDataInConsumer({ start:  moment(dates.start, "DD-MM-YYYY HH").format("YYYY-MM-DD HH"), end:  moment(dates.end, "DD-MM-YYYY").format("YYYY-MM-DD HH"), fild: fild, col: col, scriptid: GSMdata?.SCRIPTDECODE_ID, tableName: tableName, type: 'expand' })
        if(data.success==true){
            setcommonChart({total:data.data.data.map((item) => (Number(item.lengths).toFixed(2))),date:data.data.data.map((item) => ([moment(item.date, "YYYY-MM-DD HH:mm:ss").format("DD-MM-YYYY"),`(${moment(item.date, "YYYY-MM-DD HH:mm:ss").format("hh:mm:ss (ddd)")})`]))})
		    setChartLoader(false)
        }else{
            setcommonChart({})
		    setChartLoader(false)
        }
    }
	}
    return (
        <>
<Modal title={ModelHeader} visible={isModalVisible} footer='' maskClosable={false} onCancel={handleCancel} width={1500}>
				<div className="d-flex tw-justify-between tw-items-center">
					<div className="d-flex tw-justify-center tw-items-end">
                    {ModelHeader=='Hourly Consumption (in SCM)'?
                    <Space direction="horizontal">
                    <div className="d-flex flex-column">Start In
                        <DatePicker onChange={onChange} format='DD-MM-YYYY HH' showTime name='start' disabledDate={(current) => {
                            return (moment(expireDate.start_date).add(-moment().utcOffset(), "m").add(-1, 'days') >= current) || (moment(expireDate.end_date).add(-moment().utcOffset(), "m").add(1, 'days') <= current);
                        }} /></div>
                    <div className="d-flex flex-column" >End In
                        <DatePicker onChange={onChanges} name='end' format='DD-MM-YYYY HH' showTime disabledDate={(current) => {
                            return (moment(expireDate.start_date).add(-moment().utcOffset(), "m").add(-1, 'days') >= current) || (moment(expireDate.end_date).add(-moment().utcOffset(), "m").add(1, 'days') <= current);
                        }} /></div>
                </Space>:<Space direction="horizontal">
							<div className="d-flex flex-column">Start In
								<DatePicker onChange={onChange} format='DD-MM-YYYY' name='start' disabledDate={(current) => {
									return (moment(expireDate.start_date).add(-moment().utcOffset(), "m").add(-1, 'days') >= current) || (moment(expireDate.end_date).add(-moment().utcOffset(), "m").add(1, 'days') <= current);
								}} /></div>
							<div className="d-flex flex-column" >End In
								<DatePicker onChange={onChanges} name='end' format='DD-MM-YYYY' disabledDate={(current) => {
									return (moment(expireDate.start_date).add(-moment().utcOffset(), "m").add(-1, 'days') >= current) || (moment(expireDate.end_date).add(-moment().utcOffset(), "m").add(1, 'days') <= current);
								}} /></div>
						</Space>}
						<a className={`btn btn-danger text-white ml-3 mt-3 ${ChartLoader ? 'btn-progress disabled' : ''}`} onClick={() => (onGetDateWiseChart())}>Submit</a>
					</div>
					{commonChart.total.length > 0 && commonChart.date.length > 0 && <div>
						<div>
							<div className="tw-mb-1">
								<small className='tw-text-red-300'><sup className="tw-text-sm !tw-top-0">*</sup> User can also do Zoom and Scroll using their mouse</small>
							</div>
							<div className="tw-flex tw-justify-end tw-items-center">
								<button className={`tw-rounded-lg tw-bg-indigo-500 tw-border-transparent text-white tw-mx-1 tw-p-1`} onClick={zoomIn}><BootstrapTooltip title="ZoomIn">
									<div className="tw-flex tw-items-center fa-lg">
										<i class="fas fa-search-plus" aria-hidden="true"></i>
									</div>
								</BootstrapTooltip>
								</button>
								<button className={`tw-rounded-lg tw-bg-indigo-500 tw-border-transparent text-white tw-mx-1 tw-p-1`} onClick={zoomOut}>
									<BootstrapTooltip title='ZoomOut'>
										<div className="tw-flex tw-items-center fa-lg">
											<i class="fas fa-search-minus" aria-hidden="true"></i>
										</div>
									</BootstrapTooltip>
								</button>

								<button className="tw-rounded-lg tw-bg-indigo-500 tw-border-transparent text-white tw-mx-1 tw-py-1 tw-px-2" onClick={panLeft}>
									<BootstrapTooltip title='Left scroll'>
										<div className="tw-flex tw-items-center fa-lg">
											<i class="fas fa-caret-left" aria-hidden="true"></i>
										</div>
									</BootstrapTooltip>
								</button>
								<button className="tw-rounded-lg tw-bg-indigo-500 tw-border-transparent text-white tw-mx-1 tw-py-1 tw-px-2" onClick={panRight}>
									<BootstrapTooltip title='Right scroll'>
										<div className="tw-flex tw-items-center fa-lg">
											<i class="fas fa-caret-right" aria-hidden="true"></i>
										</div>
									</BootstrapTooltip>
								</button>
							</div>

						</div>

					</div>}
				</div>
				{commonChart.total.length > 0 && commonChart.date.length > 0 ? <div className="tw-mt-2 tw-h-[560px] tw-max-h-screen tw-px-5"><Bar ref={chartRef} options={expandChartoptions} data={expandChartData} /> </div> : ''}
			</Modal>
            <div class="row">
                <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div class="card card-info">
                        <div class="card-header" style={{ padding: "10px 15px" }}>
                            <h4><span className="mr-2">Daily Consumption (in SCM)</span> <BootstrapTooltip title='Find Data In dailydata table METER_ENDTS Wise' placement='top'><span><i className='fas fa-info-circle info_circle'></i></span></BootstrapTooltip></h4>

                            <div class="card-header-action">
                                <a class="btn btn-info text-white" data-toggle="tooltip" data-placement="Top" title="Expand" onClick={() => onOpenModal(GSMdata.UNIQUE_TABLE_NAME.dailydata, 'METER_ENDTS', 'Daily Consumption (in SCM)')}>Expand</a>
                            </div>
                        </div>
                        <div class="card-body" style={{ padding: "0px" }}>
                            {!GSMdata?.SCRIPTDECODE_ID?<div className="tw-flex tw-justify-center tw-items-center tw-min-h-[315px]">Script Decode Id Not Found</div>:DailyConsumptionChart.date.length > 0 && DailyConsumptionChart.total.length > 0 ? <Chart options={Hardbeatstate.options} type="bar" series={Hardbeatstate.series} height={"300"} /> : DailyConsumptionChart.res_code == 503 ? <div className="tw-flex tw-justify-center tw-items-center tw-min-h-[315px]">Table Not Found</div> : <div className="tw-flex tw-justify-center tw-items-center tw-min-h-[315px]"><ChartLoaders /></div>}
                        </div>
                        <p class="text-right" style={{ marginRight: "20px", lineHeight: "10px" }}>Bar chart</p>
                    </div>
                </div>
                {props?.GSMdata?.SCRIPTDECODE_ID!='MODUID1655982881372_METEID1714730047327'&&<div class="col-xl-8 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div class="card card-info">
                        <div class="card-header" style={{ padding: "10px 15px" }}>
                            <h4><span className="mr-2">Hourly Consumption (in SCM)</span> <BootstrapTooltip title='Find Data In hourlydata table METER_ENDTS Wise' placement='top'><span><i className='fas fa-info-circle info_circle'></i></span></BootstrapTooltip></h4>

                            <div class="card-header-action">
                                <a class="btn btn-info text-white" data-toggle="tooltip" data-placement="Top" title="Expand" onClick={() => onOpenModal(GSMdata.UNIQUE_TABLE_NAME.hourlydata, 'METER_ENDTS', 'Hourly Consumption (in SCM)')}>Expand</a>
                            </div>
                        </div>
                        <div class="card-body" style={{ padding: "0px" }}>
                            {!GSMdata?.SCRIPTDECODE_ID?<div className="tw-flex tw-justify-center tw-items-center tw-min-h-[315px]">Script Decode Id Not Found</div>:HourlyConsumptionChart.date.length > 0 && HourlyConsumptionChart.total.length > 0 ? <Chart options={Hourlystate.options} type="bar" series={Hourlystate.series} height={"300"} /> : HourlyConsumptionChart.res_code == 503 ? <div className="tw-flex tw-justify-center tw-items-center tw-min-h-[315px]">Table Not Found</div> : <div className="tw-flex tw-justify-center tw-items-center tw-min-h-[315px]"><ChartLoaders /></div>}
                        </div>
                        <p class="text-right" style={{ marginRight: "20px", lineHeight: "10px" }}>Bar chart</p>
                    </div>
                </div>}
                <div class="col-4">
							<div class="card card-danger">
								<div class="row">
									<div class="col-12 ">
										<div class="card-header"  >
											<h4><span className='mr-2'>Sales Summary Table</span><BootstrapTooltip title='Find Data In daily table' placement='top'><span><i className='fas fa-info-circle info_circle'></i></span></BootstrapTooltip></h4>
										</div>
										<div className="card-body">
										<div class="tw-w-full">
												<div class="tw-flex  tw-items-center tw-justify-center">
													<button class="tw-bg-transparent tw-transition tw-border-none hover:tw-bg-indigo-100 tw-rounded-full tw-p-0.5 tw-text-indigo-500 hover:tw-text-indigo-600" onClick={() => {
														setsalesDate(moment(salesDate, 'DD-MM-YYYY').add(-1, 'days').format('DD-MM-YYYY'))
														ongetSalesSummaryTableData({ start:moment(moment(salesDate,"DD-MM-YYYY").format('DD-MM-YYYY'),'DD-MM-YYYY').add(-15,'day').format('DD-MM-YYYY'),end:moment(salesDate,"DD-MM-YYYY").format('DD-MM-YYYY')})
													}}>
														<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-chevron-left">
															<path d="m15 18-6-6 6-6"></path>
														</svg>
													</button>
													<div class="tw-w-fit tw-px-3">
														<div class="tw-flex">
															<span class="tw-font-semibold"><DatePicker bordered={false} onChange={(date, dateString) => {
																setsalesDate(dateString)
																ongetSalesSummaryTableData({ start:moment(moment(dateString).add(1,'day').format('DD-MM-YYYY'),'DD-MM-YYYY').add(-15,'day').format('DD-MM-YYYY'),end:moment(dateString).add(1,'day').format('DD-MM-YYYY')})
															}} value={moment(salesDate || moment().format('DD-MM-YYYY'), 'DD-MM-YYYY')} format='DD-MM-YYYY' /></span>
														</div>
													</div>
													<button class="tw-bg-transparent tw-border-none tw-transition hover:tw-bg-indigo-100 tw-rounded-full tw-p-0.5 tw-text-indigo-500 hover:tw-text-indigo-600" onClick={() => {
														setsalesDate(moment(salesDate, 'DD-MM-YYYY').add(1, 'days').format('DD-MM-YYYY'))
														ongetSalesSummaryTableData({ start:moment(moment(salesDate,"DD-MM-YYYY").add(2,'day').format('DD-MM-YYYY'),'DD-MM-YYYY').add(-15,'day').format('DD-MM-YYYY'),end:moment(salesDate,"DD-MM-YYYY").add(2,'day').format('DD-MM-YYYY') })
													}}>
														<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-chevron-right">
															<path d="m9 18 6-6-6-6"></path>
														</svg>
													</button>
													<div></div>
												</div>
											</div>
											{salesLoader==false?<>
											<div class="tw-flex tw-gap-2 tw-items-center tw-text-green-600 hover:tw-bg-green-50 hover:tw-rounded tw-border-b tw-p-2" style={{borderBottomStyle:'dashed'}}>
												
												<label class="m-0 tw-font-semibold peer-disabled:tw-cursor-not-allowed peer-disabled:tw-opacity-70">Est. Sales (Last 15 Days Avg) (in SCM)</label>
												<div class="tw-ml-auto tw-bg-green-500 tw-text-white tw-inline-flex tw-items-center tw-px-2.5 tw-py-1 tw-text-sm tw-font-bold tw-transition-colors focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-ring focus:tw-ring-offset-2 tw-rounded-full tw-border-transparent tw-gap-1">{(salesData?.sales/15).toFixed(2)}</div>
											</div>
											<div class="tw-flex tw-gap-2 tw-items-center tw-text-indigo-600 hover:tw-bg-indigo-50 hover:tw-rounded tw-border-b tw-p-2" style={{borderBottomStyle:'dashed'}}>
												
												<label class="m-0 tw-font-semibold peer-disabled:tw-cursor-not-allowed peer-disabled:tw-opacity-70">Actual Sale (on Selected Date) (in SCM)</label>
												<div class="tw-ml-auto tw-bg-indigo-500 tw-text-white tw-inline-flex tw-items-center tw-px-2.5 tw-py-1 tw-text-sm tw-font-bold tw-transition-colors focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-ring focus:tw-ring-offset-2 tw-rounded-full tw-border-transparent tw-gap-1">{parseFloat(salesData?.actual_sale).toFixed(2)}</div>
											</div></>:<div className="d-flex" style={{ justifyContent: 'center' }}><img class="loader_center text-center" src={loaderGif} /></div>}
										</div>
									</div>
								</div>
							</div>
						</div>
            </div>
        </>
    )
}
