

import React, { useEffect, useRef, useState } from 'react'
import moment from 'moment';
import { Bar, getElementAtEvent } from 'react-chartjs-2';
import { DatePicker, Modal, Space, Spin } from 'antd';
import { BootstrapTooltip } from '../../../Commoncomponet/common';
import { getScheduleAnalyticsOnClick ,getScheduleAnalyticsByDate, RunScheduleMonitoringscript, scheduleApiPushmonitoringScriptStatus} from '../../../Commoncomponet/Utils';
import loaderGif from '../../../assets/img/loading_chart.gif'
import { toast } from 'react-toastify';
import swal from 'sweetalert2';
window.Swal = swal;

export default function  ScheduleDashboard (props) {
    const chartRef = useRef();
    const expandChartRef = useRef();

    const [loader, setLoader] = useState(true);
    const [chartLoader, setChartLoader] = useState(true);
    const [scheduleAnalyticsData, setScheduleAnalyticsData] = useState([]);

    const [onClickData, setOnClickData] = useState(null);
    const [onClickModel, setOnClickModel] = useState(false);
    const [onClickLoader, setOnClickLoader] = useState(false);
    const [isExpandedModalOpen, setisExpandedModalOpen] = useState(false)
    const [expandChartData, setexpandChartData] = useState(null);
    const [expandLoader, setExpandLoader] = useState(false);
    const [scheduleAnalyticsPercentageData, setScheduleAnalyticsPercentageData] = useState([]);
    const [dates, setDates] = useState({ start: '', end: '' });
    const [Status,setStatus]= useState('');

   
    const onExpandButton = (header) => {
        setisExpandedModalOpen(true);
        setexpandChartData((prev) => ({ ...prev, title: header }));
    }
    const onExpandSubmitClick = async () => {
        console.log("Dates", dates);
        if (dates && dates.start && dates.end) {
            setExpandLoader(true);
            const startDate = moment(dates.start, 'DD-MM-YYYY').format('YYYY-MM-DD');
            const endDate = moment(dates.end, 'DD-MM-YYYY').format('YYYY-MM-DD');

            const data = await getScheduleAnalyticsByDate({ start_date: startDate, end_date: endDate,schedule_table_name:JSON.parse(props?.credentialdetails?.TABLE_JSON||'{}')?.schedule });
            if (data.success === true) {
                const ChartArray = data.data.data;
                const obj = { unique_id: ChartArray.map((item) => item.unique_id), labels: ChartArray.map((item) => moment.utc(item.created_at).format("DD-MM-YYYY")), data: { "matched_consumers": ChartArray.map((item) => item.matched_consumers_length ?? 0), "unmatched_consumers": ChartArray.map((item) => item.unmatched_consumers_length ?? 0), "daily_data_found_true": ChartArray.map((item) => item.daily_data_found_true_length ?? 0), "daily_data_found_false": ChartArray.map((item) => item.daily_data_found_false_length ?? 0), "script_decode_id_found_true": ChartArray.map((item) => item.script_decode_id_found_true_length ?? 0), "script_decode_id_found_false": ChartArray.map((item) => item.script_decode_id_found_false_length ?? 0) } };
                setexpandChartData((prev) => ({ ...prev, data: obj }));
                setExpandLoader(false);

            } else {
                console.log("Something Went Wrong");
                setExpandLoader(false);
            }
        }
    }

    useEffect(() => {
        
        
        ongetScheduleAnalyticsDataByDate();
        onscheduleApiPushmonitoringScriptStatus()
    }, [])

    const ongetScheduleAnalyticsDataByDate = async (info) => {
        const end_date = moment().format('YYYY-MM-DD');
        const start_date = moment().subtract(7, 'days').format('YYYY-MM-DD');
        info={ end_date, start_date }
        setChartLoader(true);
        setLoader(false);
        const data = await getScheduleAnalyticsByDate(info || {});
        if (data.success === true) {
            const ChartArray = data.data.data;
            const obj = { unique_id: ChartArray.map((item) => item.unique_id), labels: ChartArray.map((item) => moment.utc(item.created_at).format("DD-MM-YYYY")), data: { "matched_consumers": ChartArray.map((item) => item.matched_consumers_length ?? 0), "unmatched_consumers": ChartArray.map((item) => item.unmatched_consumers_length ?? 0), "daily_data_found_true": ChartArray.map((item) => item.daily_data_found_true_length ?? 0), "daily_data_found_false": ChartArray.map((item) => item.daily_data_found_false_length ?? 0), "script_decode_id_found_true": ChartArray.map((item) => item.script_decode_id_found_true_length ?? 0), "script_decode_id_found_false": ChartArray.map((item) => item.script_decode_id_found_false_length ?? 0) } };
            const obj1 = {
                labels: ChartArray.map((item) => moment.utc(item.created_at).format("DD-MM-YYYY")), data: ChartArray.map((item) => {
                    return parseFloat(((item.matched_consumers_length / item.all_consumers) * 100).toFixed(2)) || 0;
                })
            }
            console.log("obj1", obj1);
            // 1.2 new added
            setScheduleAnalyticsPercentageData(obj1);

            setScheduleAnalyticsData(obj)
            setStatus(ChartArray?.at(-1)?.entry_ts||'')
            setChartLoader(false);
        } else {
            console.log("Something Went Wrong");
            setLoader(false);
            setChartLoader(false);
        }
    };

    const onRunScheduleMonitoringscript=async()=>{
        swal.fire({
            title: `Are You Sure, you want to start the script`,
            icon: "warning",
            showCancelButton: true,
         }).then(async (willDelete) => {
            if (willDelete.isConfirmed) {
        const data=await RunScheduleMonitoringscript()
        if(data.success==true){
            if (data.data.status == 'RUNNING') {
                swal.fire('', 'Script Started', 'success')
                
            } else {
                swal.fire('', data.data.status, 'error')
            }
        }else{
            toast.error('Internal Server Issue')
        }
    }
    })
    }

    const onscheduleApiPushmonitoringScriptStatus = async () => {
		const data = await scheduleApiPushmonitoringScriptStatus()
		if (data.success == true) {
			if (data.data.status == 'IDLE') {
				// setStatus('')
                ongetScheduleAnalyticsDataByDate()
			} else if (data.data.status == 'RUNNING') {
				setStatus('Pending')
			} else {
				setStatus(data.data.status)

			}
		} else {

		}
	}

    const ongetOnClickData = async (col, unique_id, title) => {

        setOnClickLoader(true);
        setOnClickModel(true);
        const data = await getScheduleAnalyticsOnClick({ col: col, unique_id: unique_id });
        if (data.success === true) {
            setOnClickData({ title: `${title} Details`, data: data.data.details });
            setOnClickLoader(false);

        } else {
            console.log("Something Went Wrong");
            setOnClickLoader(false);
        }

    };
    const handleOnClickModelClose = () => {
        setOnClickModel(false);
        setOnClickData(null);
    }


    const optionsForChartJS = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {

                ticks: {
                    maxRotation: 90,
                },
            },
            y: {
                grace: "10%",
                ticks: {
                    fontSize: 70
                    }
            }
        },
        animation: false,
        // animation: {
        // 	duration: 1000,
        // 	easing: 'easeInBounce',
        // 	onComplete: () => {
        // 		delayed = true;
        // 	},
        // 	delay: (context) => {
        // 		let delay = 0;
        // 		if (context.type === 'data' && context.mode === 'default' && !delayed) {
        // 			delay = context.dataIndex * 300 + context.datasetIndex * 100;
        // 		}
        // 		return delay;
        // 	},
        // },

        plugins: {
            legend: {
                position: 'bottom',
                fullSize: false,
                labels: {
                    pointStyleWidth: 10,
                    usePointStyle: true,
                    pointStyle: 'rectRounded',
                    font: function (context) {
                        var w = context.chart.width;
                        return {
                            size: w < 512 ? 9 : 11,

                        };
                    },
                }
            },
            zoom: {
                zoom: {
                    wheel: {
                        enabled: true,
                        modifierKey: 'ctrl' // SET SCROOL ZOOM TO TRUE
                    },
                    mode: "x",
                },
                pan: {
                    enabled: true,
                    mode: "x",
                }
            },
            datalabels: {
                align: 'end',
                anchor: 'end',
                clamp: true,
                clip: true,
                color: "black",
                padding: {
                    top: 0,
                    bottom: 0
                },
                font: function (context) {
                    var w = context.chart.width;
                    return {
                        size: w < 512 ? 12 : 12,

                    };
                },
            }
        },
        layout: {
            padding: 20
        },
    };
   
    const dataForChartJs = {
        labels: scheduleAnalyticsData?.labels || [],
        datasets: [
            {
                label: "Push Data of Consumers",
                data: scheduleAnalyticsData?.data?.matched_consumers,
                barPercentage: 1.0,
                backgroundColor: 'rgb(0, 126, 51)',
                borderRadius: 5,
            },
            {
                label: "Not Push Data of Consumers",
                data: scheduleAnalyticsData?.data?.unmatched_consumers,
                barPercentage: 1.0,
                backgroundColor: 'rgb(50,225,79)',
                borderRadius: 5,
            },
            {
                label: "Daily Data Found",
                data: scheduleAnalyticsData?.data?.daily_data_found_true,
                barPercentage: 1.0,
                backgroundColor: 'rgb(0,104,138)',
                borderRadius: 5,
            },
            {
                label: "Daily Data Not Found",
                data: scheduleAnalyticsData?.data?.daily_data_found_false,
                barPercentage: 1.0,
                backgroundColor: 'rgb(85, 200, 245)',
                borderRadius: 5,
            },
            // 1.2 new added for script decode id
            {
                label: "Script Decode Id Found",
                data: scheduleAnalyticsData?.data?.script_decode_id_found_true,
                barPercentage: 1.0,
                backgroundColor: 'rgb(0,104,138)',
                borderRadius: 5,
            },
            // 1.2 new added for script decode id
            // {
            //     label: "Script Decode Id Found False",
            //     data: scheduleAnalyticsData?.data?.script_decode_id_found_false,
            //     barPercentage: 1.0,
            //     backgroundColor: 'rgb(85, 200, 245)',
            //     borderRadius: 5,
            // },

        ],
    };
   
    const expandDataForChartJs = {
        labels: expandChartData?.data?.labels || [],
        datasets: [
            {
                label: "Matched Consumers",
                data: expandChartData?.data?.data?.matched_consumers,
                barPercentage: 1.0,
                backgroundColor: 'rgb(0, 126, 51)',
                borderRadius: 5,
            },
            {
                label: "Unmatched Consumers",
                data: expandChartData?.data?.data?.unmatched_consumers,
                barPercentage: 1.0,
                backgroundColor: 'rgb(50,225,79)',
                borderRadius: 5,
            },
            {
                label: "Daily Data Found True",
                data: expandChartData?.data?.data?.daily_data_found_true,
                barPercentage: 1.0,
                backgroundColor: 'rgb(0,104,138)',
                borderRadius: 5,
            },
            {
                label: "Daily Data Found False",
                data: expandChartData?.data?.data?.daily_data_found_false,
                barPercentage: 1.0,
                backgroundColor: 'rgb(85, 200, 245)',
                borderRadius: 5,
            },
            // 1.2 new added for script decode id
            {
                label: "Script Decode Id Found True",
                data: expandChartData?.data?.data?.script_decode_id_found_true,
                barPercentage: 1.0,
                backgroundColor: 'rgb(0,104,138)',
                borderRadius: 5,
            },
            // 1.2 new added for script decode id
            {
                label: "Script Decode Id Found False",
                data: expandChartData?.data?.data?.script_decode_id_found_false,
                barPercentage: 1.0,
                backgroundColor: 'rgb(85, 200, 245)',
                borderRadius: 5,
            },

        ],
    };
    const dataForPercentageChartJs = {
        labels: scheduleAnalyticsPercentageData?.labels || [],
        datasets: [
            {
                label: "Percentage of Consumers",
                data: scheduleAnalyticsPercentageData?.data,
                barPercentage: 0.2,
                backgroundColor: 'rgb(0, 126, 51)',
                borderRadius: 5,
            
            },
        ]
    }

    const onClick = (event) => {
        const { datasetIndex, index } = getElementAtEvent(chartRef.current, event)[0] || {};
        console.log("datasetIndex", datasetIndex, "index", index);
        if (datasetIndex === 0) {
            console.log("Matched Consumers: ", scheduleAnalyticsData?.labels[index], "Unique_id", scheduleAnalyticsData?.unique_id[index]);
            ongetOnClickData("matched_consumers", scheduleAnalyticsData?.unique_id[index], "Matched Consumer");
        } else if (datasetIndex === 1) {
            console.log("Unmatched Consumers: ", scheduleAnalyticsData?.labels[index], "Unique_id", scheduleAnalyticsData?.unique_id[index]);
            ongetOnClickData("unmatched_consumers", scheduleAnalyticsData?.unique_id[index], "Unmatched Consumers");
        } else if (datasetIndex === 2) {
            console.log("Daily Data Found True: ", scheduleAnalyticsData?.labels[index], "Unique_id", scheduleAnalyticsData?.unique_id[index]);
            ongetOnClickData("daily_data_found_true", scheduleAnalyticsData?.unique_id[index], "Daily Data Found True");
        } else if (datasetIndex === 3) {
            console.log("Daily Data Found False: ", scheduleAnalyticsData?.labels[index], "Unique_id", scheduleAnalyticsData?.unique_id[index]);
            ongetOnClickData("daily_data_found_false", scheduleAnalyticsData?.unique_id[index], "Daily Data Found False");
            // 1.2 new added for script decode id
        } else if (datasetIndex === 4) {
            console.log("Script Decode Id Found True: ", scheduleAnalyticsData?.labels[index], "Unique_id", scheduleAnalyticsData?.unique_id[index]);
            ongetOnClickData("script_decode_id_found_true", scheduleAnalyticsData?.unique_id[index], "Script Decode Id Found True");
            // 1.2 new added for script decode id
        } else if (datasetIndex === 5) {
            console.log("Script Decode Id Found False: ", scheduleAnalyticsData?.labels[index], "Unique_id", scheduleAnalyticsData?.unique_id[index]);
            ongetOnClickData("script_decode_id_found_false", scheduleAnalyticsData?.unique_id[index], "Script Decode Id Found False");
        }
    }
   
    const handleExpandedModal = () => {
        setisExpandedModalOpen(false);
        setexpandChartData(null);
        setExpandLoader(false);
        setDates({ start: '', end: '' });
        // setisExpandedModalOpen(false);
        // setexpandedChartData(null);
        // setExInstallationChartData({ barData: [], date: [] })
        // setExEventChartData({ total: {}, date: [] })
        // setExMonthBillCartData({ total: {}, date: [] })
        // if (myChart !=h undefined) {
        // 	myChart.destroy()
        // }
        // setChartLoader(false)
        // setDates({ start: '', end: '' })
    }
    const onChange = (date, dateString) => {
        setDates({ ...dates, start: dateString })
    };
    const onChanges = (date, dateString) => {
        setDates({ ...dates, end: dateString })
    };


    return (
        <>
            <Modal title={expandChartData?.title ?? "Expand Modal"} visible={isExpandedModalOpen} maskClosable={false} footer='' onCancel={handleExpandedModal} width={1500}>

                <div className="d-flex tw-justify-between tw-items-center">
                    <div className="d-flex tw-justify-center tw-items-end">

                        <Space direction="horizontal">
                            <div className="d-flex flex-column">Start In
                                <DatePicker value={dates?.start !== '' ? moment(dates?.start, "DD-MM-YYYY") : null} onChange={onChange} format='DD-MM-YYYY' name='start' picker="date" /></div>
                            <div className="d-flex flex-column" >End In
                                <DatePicker value={dates?.end !== '' ? moment(dates?.end, "DD-MM-YYYY") : null} onChange={onChanges} name='end' format='DD-MM-YYYY' picker="date" /></div>
                        </Space>



                        <div>
                            <a className={`btn btn-danger text-white ml-3 ${expandLoader ? 'btn-progress disabled' : ''}`} onClick={() => (onExpandSubmitClick())}>Submit</a>
                        </div>

                    </div>

                    {/* {exInstallationChartData?.date.length || expandedChartData.date.length > 0 || exMonthBillChartData.date.length > 0 || exEventChartData.date.length > 0 ?
                        <div>
                            <div className="tw-mb-1">
                                <small className='tw-text-red-300'><sup className="tw-text-sm !tw-top-0">*</sup> You can also do Zoom and Scroll using your mouse</small>
                            </div>
                            <div className="tw-flex tw-justify-end tw-items-center">
                                <button className={`tw-rounded-lg tw-bg-indigo-500 tw-border-transparent text-white tw-mx-1 tw-p-1`} disabled={zoomInEnable} onClick={zoomIn}><BootstrapTooltip title="ZoomIn">
                                    <div className="tw-flex tw-items-center fa-lg">
                                        <i class="fas fa-search-plus" aria-hidden="true"></i>
                                    </div>
                                </BootstrapTooltip>
                                </button>
                                <button className={`tw-rounded-lg tw-bg-indigo-500 tw-border-transparent text-white tw-mx-1 tw-p-1`} disabled={zoomOutEnable} onClick={zoomOut}>
                                    <BootstrapTooltip title='ZoomOut'>
                                        <div className="tw-flex tw-items-center fa-lg">
                                            <i class="fas fa-search-minus" aria-hidden="true"></i>
                                        </div>
                                    </BootstrapTooltip>
                                </button>

                                <button className="tw-rounded-lg tw-bg-indigo-500 tw-border-transparent text-white tw-mx-1 tw-py-1 tw-px-2" onClick={panLeft}>
                                    <BootstrapTooltip title='Left scroll'>
                                        <div className="tw-flex tw-items-center fa-lg">
                                            <i class="fas fa-caret-left" aria-hidden="true"></i>
                                        </div>
                                    </BootstrapTooltip>
                                </button>
                                <button className="tw-rounded-lg tw-bg-indigo-500 tw-border-transparent text-white tw-mx-1 tw-py-1 tw-px-2" onClick={panRight}>
                                    <BootstrapTooltip title='Right scroll'>
                                        <div className="tw-flex tw-items-center fa-lg">
                                            <i class="fas fa-caret-right" aria-hidden="true"></i>
                                        </div>
                                    </BootstrapTooltip>
                                </button>
                            </div>

                        </div>
                        // : ''} */}
                </div>

                {/* {expandedChartData.date.length > 0 ? <div className=" tw-mt-2 tw-h-[560px] tw-max-h-screen tw-px-5"><Bar ref={expandChartRef} options={optionsExpanded} data={exInstantData} /> </div> : ''} */}

                {/* chart display */}
                {expandChartData?.data && expandChartData?.data?.labels?.length > 0 && !expandLoader ? <div className='tw-mt-2 tw-h-[560px] tw-max-h-screen tw-px-5 '><Bar ref={expandChartRef} options={optionsForChartJS} data={expandDataForChartJs} /></div> : ""}
                {/* no data condition */}
                {expandChartData?.data?.labels?.length === 0 && !expandLoader && <div className='tw-mt-2 tw-h-[560px] tw-flex tw-items-center tw-justify-center tw-px-5'> No Data</div>}
            </Modal>

            <Modal title={onClickData?.title ?? ''} visible={onClickModel} footer='' maskClosable={false} onCancel={handleOnClickModelClose} width={800}>

                {onClickModel &&

                    <div className='tw-max-h-[75dvh] tw-overflow-auto'>
                        {onClickLoader ? <div className=" tw-h-[75dvh] tw-flex tw-items-center tw-justify-center"><Spin /></div> :
                            <table className="table border table-borderless tw-w-full tw-border-collapse tw-border tw-border-gray-300">
                                <thead className='tw-sticky tw-top-0'>
                                    <tr className="tw-bg-gray-100">
                                        <th className="tw-border tw-border-gray-300 tw-p-2">Sr No</th>
                                        <th className="tw-border tw-border-gray-300 tw-p-2">Address Company Name</th>
                                        <th className="tw-border tw-border-gray-300 tw-p-2">DEVICE_SERIAL_NO</th>
                                        <th className="tw-border tw-border-gray-300 tw-p-2">INSTALLATION_NUMBER</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {/* 1.2 remover 3 col  */}
                                    {onClickData?.data?.map((item,id) => (
                                           <tr key={item.UNIQUE_ID} className="trheigth even:tw-bg-gray-100 hover:tw-bg-[#D8D8D8]">
                                           <td className="tw-border tw-border-gray-300 tw-p-2">{id + 1}</td>
                                           <td className="tw-border tw-border-gray-300 tw-p-2">{item.address_compony_name}</td>
                                           <td className="tw-border tw-border-gray-300 tw-p-2">{item.DEVICE_SERIAL_NO}</td>
                                           <td className="tw-border tw-border-gray-300 tw-p-2">{item.INSTALLATION_NUMBER}</td>

                                       </tr>
                                    ))}
                                </tbody>
                            </table>

                        }
                    </div>}



            </Modal>
                {loader ? (
                    <div className="main-content">
                        <div class="loader"></div>
                    </div>
                ) : (
                    ""
                )}
                
                <section class="section mt-4">
                    <div class="section-body">
                        <div class="row">
                            <div class="col-12">
                                <div class="card card-danger">
                                    <div class="row">
                                        <div class="col-12 ">
                                            <div class="card-header"  >
                                                <h4><span className="mr-2">Push Data Analytics count</span><BootstrapTooltip title='Find Data In schedule_api_push_data_analytics_history table' placement='top'><span><i className='fas fa-info-circle info_circle'></i></span></BootstrapTooltip></h4>
                                                <div class="card-header-action">
                                                <span className="mr-2">{Status == 'Pending' ? 'Script Running' : Status ? moment.utc(Status).add(-moment().utcOffset(), "m").fromNow('ss:hh:mm') : ''}</span>
												<a class="btn btn-primary text-white mr-2" data-toggle="tooltip" data-placement="Top" title="Refresh" onClick={onscheduleApiPushmonitoringScriptStatus}><i class="fas fa-refresh" aria-hidden="true"></i></a>
                                                <a class="btn btn-warning text-white mr-2" data-toggle="tooltip" data-placement="Top" title="Expand" onClick={() => { onRunScheduleMonitoringscript() }}>Run</a>
                                                    <a class="btn btn-info text-white" data-toggle="tooltip" data-placement="Top" title="Expand" onClick={() => { onExpandButton('Schedule Api Push Data Analytics') }}>Expand</a>
                                                </div>
                                            </div>

                                            <div class="card-body heartbeatspace " style={{ position: "relative", height: "395px" }}>
                                            {
                                                    chartLoader ? (
                                                        <div className="tw-flex tw-justify-center">
                                                            <img className="loader_center tw-text-center" src={loaderGif} alt="Loading..." />
                                                        </div>
                                                    ) : (
                                                        scheduleAnalyticsData?.labels?.length === 0 ? (
                                                            <div className="tw-mt-2 tw-h-full tw-flex tw-items-center tw-justify-center tw-px-5">
                                                                No Data
                                                            </div>
                                                        ) : (
                                                            <Bar
                                                                ref={chartRef}
                                                                onClick={onClick}
                                                                options={optionsForChartJS}
                                                                data={dataForChartJs}
                                                            />
                                                        )
                                                    )
                                                }
                                                {/* <Bar ref={chartRef} onClick={onClick} options={optionsForChartJS} data={dataForChartJs} /> */}
                                            </div>
                                            <p class="text-right" style={{ marginRight: "40px", lineHeight: "10px" }}>Bar chart</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="card card-danger">
                                    <div class="row">
                                        <div class="col-12 ">
                                            <div class="card-header"  >
                                                <h4><span className="mr-2">Push Data Analytics in Percentage</span><BootstrapTooltip title='Find Data In schedule_api_push_data_analytics_history table' placement='top'><span><i className='fas fa-info-circle info_circle'></i></span></BootstrapTooltip></h4>
                                                <div class="card-header-action">
                                                    <a class="btn btn-info text-white" data-toggle="tooltip" data-placement="Top" title="Expand" onClick={() => { onExpandButton('Schedule Analysis Percentage Date Wise Chart') }}>Expand *</a>
                                                </div>
                                            </div>
                                            <div class="card-body heartbeatspace !tw-p-0 " style={{ position: "relative", height: "395px" }}>
                                                {
                                                    chartLoader ? (
                                                        <div className="tw-flex tw-justify-center">
                                                            <img className="loader_center tw-text-center" src={loaderGif} alt="Loading..." />
                                                        </div>
                                                    ) : (
                                                        scheduleAnalyticsPercentageData?.labels?.length === 0 ? (
                                                            <div className="tw-mt-2 tw-h-full tw-flex tw-items-center tw-justify-center tw-px-5">
                                                                No Data
                                                            </div>
                                                        ) : (
                                                            <Bar
                                                                options={optionsForChartJS}
                                                                data={dataForPercentageChartJs}
                                                            />
                                                        )
                                                    )
                                                }
                                                {/* {scheduleAnalyticsData?.labels?.length === 0 && !chartLoader && <div className='tw-mt-2 tw-flex tw-items-center tw-justify-center tw-px-5'> No Data</div>} */}
                                            </div>
                                            <p class="text-right" style={{ marginRight: "40px", lineHeight: "10px" }}>Bar chart</p>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                </section>
        </>
    )
}