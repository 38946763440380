import React from 'react';

const CustomCron = (props) => {
    const onChange = (e) => {
        if(props.disabled) return;
        props.onChange(e.target.value.replace(/,/g, '!').split(" "));
    }
    
    const translateFn = props.translate;
    let val = props.value.toString().replace(/,/g,' ').replace(/!/g, ',');

    return (
        <div className="well">   
            {translateFn('Expression')} 
            <input 
                type="text" 
                onChange={onChange} 
                value={val} 
                disabled={props.disabled}
            />
        </div>
    );
}

export default CustomCron;