import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import socketio from "socket.io-client";
import * as XLSX from 'xlsx-js-style';
import axios from 'axios';
import {toast} from 'react-toastify'
export const socket = socketio.connect('https://gumsops.memighty.com:7001/');
// socket.emit('join', 'Hello World from client');
export const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));
  

let cancelTokenSource;


   function convertToCSV(objArray,cancelToken) {
		var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
		var str = '';
	
		for (var i = 0; i < array.length; i++) {
            if (cancelToken) return
			var line = '';
			for (var index in array[i]) {
				if (line != '') line += ','
	
				line += array[i][index];
			}
	
			str += line + '\r\n';
		}
	
		return str;
	}

	export function exportCSVFile(headers, items, fileTitle,cancelToken) {

        try {
            if (headers) {
                items.unshift(headers);
            }
        
            // Convert Object to JSON
            var jsonObject = JSON.stringify(items);
        
            var csv = convertToCSV(jsonObject,cancelToken);
        
            var exportedFilenmae = fileTitle + '.csv' || 'export.csv';
        
            if( !cancelToken){
                var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
                if (navigator.msSaveBlob && !cancelToken ) { // IE 10+
                    navigator.msSaveBlob(blob, exportedFilenmae);
                } else {
                    var link = document.createElement("a");
                    if (link.download !== undefined && !cancelToken) { // feature detection
                        // Browsers that support HTML5 download attribute
                        var url = URL.createObjectURL(blob);
                        link.setAttribute("href", url);
                        link.setAttribute("download", exportedFilenmae);
                        link.style.visibility = 'hidden';
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    }else{
                        return 
        
                    }
                }
            }else{
                return 
            }
            
        } catch (error) {
            console.error("Error in exportCSVFile:", error);
            throw error;
        }
        
    }

    async function fetchData(API_URL, setProgress) {
        try {
    
            cancelTokenSource = axios.CancelToken.source(); 
    
            const response = await axios.get(API_URL, {
                cancelToken: cancelTokenSource.token,
                onDownloadProgress: (progressEvent) => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    if(setProgress){
                        setProgress(percentCompleted);
                    }
                }
            });
            let jsonData = response?.data;
            if (!jsonData) {
                toast.error('Invalid or empty data received');
                return false
            }
    
            if (!Array.isArray(jsonData)) {
                jsonData = [jsonData];
            }
    
            return jsonData;
        } catch (error) {
            throw new Error('Error fetching data: ' + (error.message || 'Unknown error'));
        }
    }

function fitToColumn(jsonData) {
    if (!jsonData || jsonData?.length === 0) return [];

    const columnWidths = [];
    const sampleSize = Math.max(0, jsonData.length);

    const allKeys = new Set();
    jsonData.slice(0, sampleSize).forEach(row => {
        Object.keys(row).forEach(key => allKeys.add(key));
    });

    allKeys.forEach(key => {
        let maxWidth = key.length;

        for (let i = 0; i < sampleSize; i++) {
            const cellValue = jsonData[i][key];
            if (cellValue != null) {
                const cellWidth = cellValue.toString().length;
                // console.log(cellValue.toString(),cellWidth)
                maxWidth = Math.max(maxWidth, cellWidth);
            }
        }

        maxWidth = Math.max(maxWidth + 2, 10)
        columnWidths.push({ wch: maxWidth });
    });

    return columnWidths;
}

function summeryReportDataFormatr(data) {
    return ([
        ['Report Date', data?.Report_Date],
        ['Category 2', data?.Category_1],
        ['Category 1', data?.Category_2],
        ['Account', data?.Account],
        ['Conversion factor', data?.Conversion_factor],
        [],
        ['Dashboard MEMighty', 'On Today Date', 'On Report Date', '% age'],
        ['No of Customers with AMR', data?.No_of_Customers_with_AMR_todays, data?.No_of_Customers_with_AMR_report_date, data?.No_of_Customers_with_AMR_age],
        ['No of EVC customers with AMR', data?.No_of_EVC_customers_with_AMR_todays, data?.No_of_EVC_customers_with_AMR_report_date, data?.No_of_EVC_customers_with_AMR_age],
        ['No of Non-EVC customers with AMR', data?.No_of_Non_EVC_customers_with_AMR_todays, data?.No_of_Non_EVC_customers_with_AMR_report_date, data?.No_of_Non_EVC_customers_with_AMR_age],
        ['No of EVC Customer Data Available', data?.No_of_EVC_Customer_Data_Available_todays, data?.No_of_EVC_Customer_Data_Available_report_date, data?.No_of_EVC_Customer_Data_Available_age],
        ['No of Non EVC Customer Data Available', data?.No_of_Non_EVC_Customer_Data_Available_todays, data?.No_of_Non_EVC_Customer_Data_Available_report_date, data?.No_of_Non_EVC_Customer_Data_Available_age],
        ['Total No of Customer’s data available', data?.Total_No_of_Customers_data_available_todays, data?.Total_No_of_Customers_data_available_report_date, data?.Total_No_of_Customers_data_available_age],
        ['Total No. of Customers data sent to SAP through Schedule Push API - Send Data', '-', '-', data?.Schedule_Push_API_Send_Data],
        ['Total No. of Customers data sent to SAP through Schedule Push API - Response', '-', '-', data?.Schedule_Push_API_Send_Data],
        [],
        ['', 'On Report Date', '1st Fortnight', '2nd Fortnight', 'Monthly (Cumulative)'],
        ['Volume (EVC)', data?.On_Report_Date_total, data?.["1st_Fortnight_evc"], data?.["2nd_Fortnight_evc"], data?.Monthly_Cumulative_evc],
        ['Volume (Non EVC)', data?.On_Report_Date_non_evc, data?.["1st_Fortnight_non_evc"], data?.["2nd_Fortnight_non_evc"], data?.Monthly_Cumulative_non_evc],
        ['Total Volume (SCM)', data?.On_Report_Date_total, data?.["1st_Fortnight_total"], data?.["2nd_Fortnight_total"], data?.Monthly_Cumulative_total],
    ]);
}


const generateExcel = ({ data, reportName, headerConfig }) => {
    // Create a new workbook and worksheet
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet([]);

    // Calculate the maximum row span to determine where to start the data
    const maxRowSpan = Math.max(...Object.values(headerConfig).map(config => config.rowspan || 1));

    // Apply header configuration
    let colIndex = 0;
    const colspanMap = {};
    for (const [header, config] of Object.entries(headerConfig)) {
        const { rowspan = 1, colspan = 1, style } = config;

        // Set cell value and style
        const cellAddress = XLSX.utils.encode_cell({ r: 0, c: colIndex });
        ws[cellAddress] = { v: header, s: style };

        // Merge cells if necessary
        if (rowspan > 1 || colspan > 1) {
            if (!ws['!merges']) ws['!merges'] = [];
            ws['!merges'].push({
                s: { r: 0, c: colIndex },
                e: { r: rowspan - 1, c: colIndex + colspan - 1 }
            });
        }

        // Store colspan for each column
        for (let i = 0; i < colspan; i++) {
            colspanMap[colIndex + i] = colspan;
        }

        colIndex += colspan;
    }

    // Prepare data with merged cells
    const mergedData = data.map(row => {
        const mergedRow = [];
        let colIndex = 0;
        for (const value of Object.values(row)) {
            const colspan = colspanMap[colIndex] || 1;
            mergedRow.push(value);
            for (let i = 1; i < colspan; i++) {
                mergedRow.push(null);
            }
            colIndex += colspan;
        }
        return mergedRow;
    });

    // Add data to the worksheet, starting after the header
    if (mergedData.length > 0) {
        XLSX.utils.sheet_add_aoa(ws, mergedData, { origin: maxRowSpan });
    }

    // Merge cells in the body to match header colspan
    let rowIndex = maxRowSpan;
    for (const row of mergedData) {
        let colIndex = 0;
        for (let i = 0; i < row.length; i++) {
            const colspan = colspanMap[colIndex] || 1;
            if (colspan > 1 && row[i] !== null) {
                if (!ws['!merges']) ws['!merges'] = [];
                ws['!merges'].push({
                    s: { r: rowIndex, c: colIndex },
                    e: { r: rowIndex, c: colIndex + colspan - 1 }
                });
            }
            colIndex += colspan;
        }
        rowIndex++;
    }

    // Set column widths based on the colspan
    const colWidths = [];
    for (let i = 0; i < colIndex; i++) {
        colWidths[i] = { wch: 15 }; // Set a default width, adjust as needed
    }
    ws['!cols'] = colWidths;

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Generate Excel file
    XLSX.writeFile(wb, `${reportName}.xlsx`);
};

function dataFormateForHCR(data) {
    try {
        const simpleArray = [];
        const headers = Object.keys(data[0].header).filter(v => v !== 'dynamicColumns');
        simpleArray.push(headers);
        const dynamicColumns = data[0]?.header?.dynamicColumns?.column || [];
        const maxDynamicColumnsLength = Math.max(...dynamicColumns.map(col => Array.isArray(col) ? col.length : 0));
        console.log(dynamicColumns)
        dynamicColumns.forEach(colDef => {
            if (Array.isArray(colDef)) {
                colDef.forEach((v, i) => {
                    simpleArray[i] ? simpleArray[i].push(v) : simpleArray.push([v]);
                });
            }
        });

        for (let i = 1; i < maxDynamicColumnsLength; i++) {
            if (simpleArray[i]) {
                simpleArray[i].unshift(...Array(Object.keys(data[0].header).filter(v => v !== 'dynamicColumns').length).fill(' '));
            }
        }

        data[0].data.forEach(row => {
            const simpleRow = [row.SrNo, row.address_compony_name,row.Installation_Number];
            dynamicColumns.forEach(colDef => {
                const key = Array.isArray(colDef) ? colDef.join("_") : colDef;
                simpleRow.push(row[key] || "0");
            });
            simpleArray.push(simpleRow);
        });

        return simpleArray;
    } catch (error) {
        console.error("Error in dataFormate:", error);
        throw error;
    }
}

// const generateExcelByData = ({ data, reportName, reportType, headerConfig, fillBgLookup , cancelToken }) => {
//     try {
//         const workbook = XLSX.utils.book_new();
//         let worksheet;
//         console.time("excel start time")

//         if (reportType === 'array') {

//             if(headerConfig){
//                 worksheet = XLSX.utils.aoa_to_sheet([]);

//                 const maxRowSpan = Math.max(...Object.values(headerConfig || {}).map(config => config?.rowspan || 1));
    
//                 let colIndex = 0;
//                 const colspanMap = {};
//                 for (const [header, config] of Object.entries(headerConfig)) {
//                     if(cancelToken) return
//                     const { rowspan = 1, colspan = 1, style = {} } = config;
    
//                     const cellAddress = XLSX.utils.encode_cell({ r: 0, c: colIndex });
//                     worksheet[cellAddress] = { v: header, s: style };
    
//                     if (rowspan > 1 || colspan > 1) {
//                         if (!worksheet['!merges']) worksheet['!merges'] = [];
//                         worksheet['!merges'].push({
//                             s: { r: 0, c: colIndex },
//                             e: { r: rowspan - 1, c: colIndex + colspan - 1 }
//                         });
//                     }
    
//                     colspanMap[colIndex] = colspan;
//                     colIndex += colspan;
//                 }
    
//                 const mergedData = data.map(row => {
//                     const mergedRow = [];
//                     let colIndex = 0;
//                     for (const value of Object.values(row)) {
//                         const colspan = colspanMap[colIndex] || 1;
//                         mergedRow.push(value);
//                         for (let i = 1; i < colspan; i++) {
//                             if(cancelToken) return
//                             mergedRow.push(null);
//                         }
//                         colIndex += colspan;
//                     }
//                     return mergedRow;
//                 });
    
//                 if (mergedData.length > 0) {
//                     XLSX.utils.sheet_add_aoa(worksheet, mergedData, { origin: 0 }); // Start from row after header
//                 }
    
//                 let rowIndex = maxRowSpan;
//                 for (const row of mergedData) {
//                     let colIndex = 0;
//                     for (let i = 0; i < row.length; i++) {
//                         if(cancelToken) return
//                         const colspan = colspanMap[colIndex] || 1;
//                         if (colspan > 1 && row[i] !== null) {
//                             if (!worksheet['!merges']) worksheet['!merges'] = [];
//                             worksheet['!merges'].push({
//                                 s: { r: rowIndex, c: colIndex },
//                                 e: { r: rowIndex, c: colIndex + colspan - 1 }
//                             });
    
//                             colIndex += colspan;
//                         }
//                     }
//                     rowIndex++;
//                 }
//             }else{
//                 worksheet = XLSX.utils.aoa_to_sheet(data);
//             }

           
//         } else {
//             worksheet = XLSX.utils.json_to_sheet(data);
//         }

//         if (Object.keys(fillBgLookup)?.length > 0) {
//             const range = XLSX.utils.decode_range(worksheet['!ref']);
//             for (let R = range.s.r; R <= range.e.r; ++R) {
//                 for (let C = range.s.c; C <= range.e.c; ++C) {
//                     if(cancelToken) return
//                     const cellRef = XLSX.utils.encode_cell({ r: R, c: C });
//                     if (!worksheet[cellRef]) continue;
                    
//                     const cellValue = worksheet[cellRef].v;

//                     // console.log(cellValue,typeof cellValue)
                    
//                     // Set alignment for all cells
//                     if (!worksheet[cellRef].s) worksheet[cellRef].s = {};

//                     // console.log((cellValue || cellValue === 0) && (typeof cellValue === 'string'  || typeof cellValue === 'number') )
                    
//                     // Apply fill color based on lookup table
//                     if ((cellValue || cellValue === 0) && (typeof cellValue === 'string'  || typeof cellValue === 'number') ) {
//                         const lowercaseValue = typeof cellValue === 'string'  ? cellValue.trim() : cellValue;
//                         if (fillBgLookup[lowercaseValue]) {
//                             // console.log('Applying fill to cell:', cellRef, lowercaseValue);
//                             worksheet[cellRef].s.fill = {
//                                 fgColor: { rgb: fillBgLookup[lowercaseValue].bgColor },
//                                 patternType: fillBgLookup[lowercaseValue].patternType
//                             };
//                         }
//                     }
//                 }
//             }
//         }

//         const columnWidths = fitToColumn(data); // Assuming you have a fitToColumn function
//         worksheet['!cols'] = columnWidths;

//         XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
//         console.timeEnd("excel start time")

     

//         if(!cancelToken){
//             console.time("start time")
//             const blob = new Blob([XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })], 
//                 { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
//                 const url = URL.createObjectURL(blob);
//             console.timeEnd("start time")

//             const link = document.createElement("a");
//             link.href = url;
//             link.download = `${reportName}.xlsx`;
//             document.body.appendChild(link);
//             link.click();
    
//             document.body.removeChild(link);
//             window.URL.revokeObjectURL(url);
//         }else{
//           return

//         }

        
//     } catch (error) {
//         console.error("Error in generateExcelByData:", error);
//         throw error; 
//     }
// };

const generateExcelByData = ({ data, reportName, reportType, headerConfig, fillBgLookup ,cancelToken, setProgress,setIsLoading,download_type}) => {
   try{

      const worker =new Worker(new URL('./ExcelWorker.js', import.meta.url));

      const chunks = [];
        // console.log(worker)
      worker.onmessage = (e) => {
        // console.log(e)
        if (e.data.type === 'progress') {

            setProgress((e.data.progress * 0.40) + 60);

        }else if (e.data.type === 'chunk') {
          chunks.push(e.data.data);
        } else if (e.data.type === 'done') {
          const blob = new Blob(chunks, { type: 'application/octet-stream' });
          const url = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = `${e.data.reportName}.xlsx`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(url);
          setIsLoading(false)
          toast.success(`${reportName}.${reportType.toLowerCase()} generated successfully`);
          if(download_type=='MAIL_DOWNLOAD'){
          window.close()
          }
        } else if (e.data.type === 'error') {
          console.error('Excel generation error:', e.data.error);
          throw e.data.error;
        }
      };
  
      worker.postMessage({ data, reportName, reportType, headerConfig, fillBgLookup,cancelToken });
    }catch(error){
        console.log('generateExcelByData', error)
        throw error;
    }
    }
export function exportExcelDownload(data,filename){
  const worksheet = XLSX.utils.json_to_sheet(data);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  XLSX.writeFile(workbook, `${filename}.xlsx`);
}

export const MENULIST=[
  {
      key: 1,
      name: 'Dashboard',
      id:'MENU1',
      view:false,
      children: [
          {
              key: 11,
              name: 'All',
              id:'MENU1_1',
              view:false
          },
          {
              key: 12,
              name: 'GSM Modem',
              id:'MENU1_2',
              view:false
          },
          {
              key: 13,
              name: 'Lorawan Modem',
              id:'MENU1_3',
              view:false
          },
          {
              key: 14,
              name: 'GSM Smart Meter',
              id:'MENU1_4',
              view:false
          },
          {
              key: 15,
              name: 'Lorawan Smart Meter',
              id:'MENU1_5',
              view:false
          }
      ]
  },
  {
      key: 2,
      name: 'Group',
      id:'MENU2',
      view:false
  },
  {
      key: 3,
      name: 'Consumer',
      id:'MENU3',
      view:false,
      children: [
          {
              key: 16,
              name: 'All Consumer',
              id:'MENU3_1',
              view:false
          },
          {
              key: 17,
              name: 'GSM Modem',
              id:'MENU3_2',
              view:false
          },
          {
              key: 18,
              name: 'Lorawan Modem',
              id:'MENU3_3',
              view:false
          },
          {
              key: 19,
              name: 'GSM Smart Meter',
              id:'MENU3_4',
              view:false
          },
          {
              key: 20,
              name: 'Lorawan Smart Meter',
              id:'MENU3_5',
              view:false
          },

      ]
  },
  {
    key: 26,
    name: 'Roles & Users',
    id:'MENU11',
    view:false,
    children: [
        {
            key: 27,
            name: 'User',
            id:'MENU11_1',
            view:false
        },
        {
            key: 28,
            name: 'Roles',
            id:'MENU11_2',
            view:false
        },
        {
            key: 29,
            name: 'Users Group',
            id:'MENU11_3',
            view:false
        }

    ]
},
  {
      key: 4,
      name: 'Modem Triggers',
      id:'MENU4',
      view:false,
      children: [
          {
              key: 21,
              name: 'GSM Modem',
              id:'MENU4_1',
              view:false
          },
          {
              key: 22,
              name: 'Lorawan Modem',
              id:'MENU4_2',
              view:false
          },
      ]
  },
  {
      key: 5,
      name: 'Notification Manger',
      id:'MENU5',
      view:false
  },
  {
      key: 6,
      name: 'Tags',
      id:'MENU6',
      view:false
  },
  {
      key: 7,
      name:'Integration',
      id:'MENU7',
      view:false,
      children: [
          {
              key: 23,
              name: 'Api',
              id:'MENU7_1',
              view:false
          },
          {
              key: 24,
              name: 'Plugin',
              id:'MENU7_2',
              view:false
          }
      ]
  },
  {
      key: 8,
      name: 'Release Notes',
      id:'MENU8',
      view:false
  },
  {
      key: 9,
      name:'Web Tabs',
      id:'MENU9',
      view:false
  },{
      key: 10,
      name: 'Bad Data Analytics',
      id:'MENU10',
      view:false
  }]

  export { fetchData, fitToColumn, summeryReportDataFormatr, generateExcel, dataFormateForHCR ,generateExcelByData,cancelTokenSource}

  export const AttachmanyUrl='https://ccmsnew.memighty.com:6001/uploadfiles/Attachmants/'
  export const ReportUrl='https://gums.memighty.com:7001/uploadfiles/Reports/'
  export const ReporLoratUrl='https://gums.memighty.com:7001/uploadfiles/Lorareport/'
  export const UserUrl='https://ccmsnew.memighty.com:6001/uploadfiles/Users/'
  export const MeterUrl='https://ccmsnew.memighty.com:6001/uploadfiles/Meterattachment/'
  export const TaskUrl='https://ccmsnew.memighty.com:6001/uploadfiles/Task/'
