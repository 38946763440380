export default function FileHandler({pdfUrl}) {
    console.log("pdfUrl",pdfUrl)


    // Function to determine the file type based on its extension
    const getFileType = (filename) => {
        const extension = filename.split('.').pop().toLowerCase();
        // console.log("file type is",filename,extension)
        if (['png', 'jpg', 'jpeg', 'gif'].includes(extension)) {
            return 'image';
        } else if (['mp4', 'webm', 'ogg'].includes(extension)) {
            return 'video';
        }
        return 'unknown';
    };

    // Function to render different types of files
    const renderFile = () => {
        const fileType = getFileType(pdfUrl.filename);
        switch (fileType) {

            case 'image':
                return <img
                    src={pdfUrl.url}
                    alt={pdfUrl.filename}
                    style={{
                        width: '100%',
                        height: '100%',
                        maxHeight: '75dvh',
                        transition: 'filter 1s ease',
                        // opacity: loading ? 0.5 : 1,

                    }}
                    className="tw-object-contain tw-max-h-[75dvh] !tw-transition-opacity"
                    loading="Loading..."

                />;
            case 'video':
                return <video
                    src={pdfUrl.url}
                    controls
                    muted
                    style={{ width: "86%", height: "100%" }}
                    className="tw-max-h-[75dvh]"
                />;
            default:
                return <p>Unsupported file type</p>;
        }
    };

    return (
        <div className="!tw-max-w-[90vw] !tw-h-full">
            <div className="tw-w-full tw-h-full tw-flex tw-justify-around tw-items-center tw-gap-2 tw-px-4">
                {renderFile()}
            </div>
        </div>
    );
}