import React, { useEffect, useState } from 'react'
import { InsertGsmCustomDashboard, UpdateGsmCustomDashboard, getGsmCustomDashboadList, getRolesById } from '../../../Commoncomponet/Utils';
import { BootstrapTooltip } from '../../../Commoncomponet/common';
import { Modal } from 'antd';
import { toast } from 'react-toastify'
import chroma from 'chroma-js';
import GoogleLoader from '../../../Commoncomponet/Loaders/GoogleLoader';
import GSM_Deshboard from './GSM_Deshboard';

export default function GSM_Custom_Dashboard(props) {
	const {Profile,setProfile,AllCategoryJson}=props
    const [permision, setpermision] = useState({})
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [fildDisable, setfildDisable] = useState(false)
    const [TabID,setTabID]=useState(null)
    const [DashTabs,setDashTabs]=useState([])
    const [DashTabDetailById,setDashTabDetailById]=useState({})
    const [TabDetails,setTabDetails]=useState({name:'',description:''})
    const [dashboardId,setdashboardId]=useState('')
	const [loader,setLoader]=useState(false)
    const [AddLoader,setAddLoader]=useState(false)

	const userInfo = JSON.parse(window.localStorage.getItem("user_info"));
	const btnColor=['#4f5ece','#f59e0b','#fc544b','#3abaf4','#54ca68','#191d21','#7c5295','#4f5ece']
    const onHandaleRoles = async () => {
		setLoader(true)
        const data = await getRolesById(userInfo?.ROLE);
        if (data.success === true) {
            const obj = data.data.data[0];
            setpermision({
                ...obj,
                ROLES_PERMISSIONS_CATEGORY0: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY0),
                ROLES_PERMISSIONS_CATEGORY5: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY5),
                ROLES_PERMISSIONS_CATEGORY2: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY2),
                ROLES_PERMISSIONS_CATEGORY3: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY3),
                ROLES_PERMISSIONS_CATEGORY1: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY1),
                ROLES_PERMISSIONS_CATEGORY4: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY4),
                ROLES_OTHER_PERMISSIONS: JSON.parse(obj.ROLES_OTHER_PERMISSIONS)
            });
        onLoadCosutomDashboardList(JSON.parse(obj.ROLES_OTHER_PERMISSIONS))
        } else {
		setLoader(false)
        }
    }

    const onLoadCosutomDashboardList=async(info)=>{
        const data=await getGsmCustomDashboadList()
        if(data.success==true){
			const dashtab= data?.data?.data?.filter((item)=>(info?.CUSTOM_DASHBOARD?.dashboard_view?.includes(item?.unique_id)||userInfo?.ROLE == 'ROLE1650614500708'))
            setDashTabs(dashtab)
            // setDashTabDetailById(dashtab[0])
		setLoader(false)
        }else{
            console.log('something want wrong')
		setLoader(false)
        }
    }

    useEffect(() => {
        onHandaleRoles()
    }, [])

    useEffect(() => {
        setdashboardId(DashTabDetailById?.unique_id)
		setProfile({ account: "", ga: "", circle: "", division: "", subdivision: "", area: "",ins_no:[] })
    },[DashTabDetailById])

    const showModal = () => {
		setIsModalVisible(true);
	};

    const handleCancel=()=>{
		setIsModalVisible(false);
    }

    const handleOk = async () => {
		if (TabID === null) {
			if (TabDetails?.name !== "") {
				setAddLoader(true)
				const data = await InsertGsmCustomDashboard({ ...TabDetails, add_by: userInfo.UNIQUE_ID });
				if (data.success === true) {
					toast.success("Add Dashboard Success");
					setIsModalVisible(false);
                    setTabDetails({name:'',description:''})
                    onLoadCosutomDashboardList(permision?.ROLES_OTHER_PERMISSIONS)
					setAddLoader(false)
				} else {
					toast.error("Choice New Name");
					setAddLoader(false)
				}
			} else {
				toast.error("Require Enter Dashboard Name");
			}
		} else {
			if (TabDetails?.name !== "") {
				setAddLoader(true)
				const data = await UpdateGsmCustomDashboard({...TabDetails,add_by: userInfo.UNIQUE_ID }, dashboardId);
				if (data.success === true) {
					toast.success("Update Dashboard Success");
					setIsModalVisible(false);
					setTabID(null);
                    setTabDetails({name:'',description:''})
                    onLoadCosutomDashboardList(permision?.ROLES_OTHER_PERMISSIONS)
					setAddLoader(false)
				} else {
					toast.error("Choice New Name");
					setAddLoader(false)
				}
			} else {
				toast.error("Require Enter Dashboard Name");
			}
		}
	};

    const onEditTab=async (id)=>{
        setTabID(id)
        const tabDetails=DashTabs.filter((item)=>(item?.unique_id==id))[0]
        setTabDetails({name:tabDetails?.name,description:tabDetails?.description})
        setIsModalVisible(true)
    }

	
    return (
        <>
        <Modal title={<div>{!TabID?"Add New Dashboard":'Update New Dashboard'}{TabID?<div  class="btn btn-info text-white btnedit ml-2" onClick={()=>(setfildDisable(true))} style={{padding:'1px 8px 2px 8px'}}><BootstrapTooltip title='edit'><span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Edit" ><i class="fas fa-edit  text-white"></i></span></BootstrapTooltip></div>:""}{TabID?<></>:""}</div>} visible={isModalVisible} footer='' onCancel={handleCancel} >
				<form class="">
					<div class="form-group">
						<label>Dashboard Name</label>
						<div class="input-group">
							<div class="input-group-prepend">
								<div class="input-group-text">
									<i class="fas fa-table"></i>
								</div>
							</div>
							<input
								type="text"
								class="form-control"
								placeholder="Dashboard Name"
								name="tabName"
								onChange={(e) => setTabDetails({...TabDetails,name:e.target.value})}
								value={TabDetails?.name}
								disabled={TabID?(!fildDisable):false}
							/>
						</div>
					</div>
                    <div class="form-group">
						<label>Dashboard Description</label>
						<div class="input-group">
							<div class="input-group-prepend">
								<div class="input-group-text">
									<i class="fas fa-table"></i>
								</div>
							</div>
							<input
								type="text"
								class="form-control"
								placeholder="Dashboard Description"
								name="tabName"
								onChange={(e) => setTabDetails({...TabDetails,description:e.target.value})}
								value={TabDetails?.description}
								disabled={TabID?(!fildDisable):false}
							/>
						</div>
					</div>
				</form>
				<div className="mt-3">
				{fildDisable===true&&TabID?<a className={`btn btn-danger ${fildDisable===false&&TabID?'disabled':''} text-white ${AddLoader==true?'btn-progress disabled':''}`} onClick={handleOk}>{!TabID?'Submit':'Update'}</a>:''}
				{fildDisable===false&&!TabID?<a className={`btn btn-danger ${fildDisable===false&&TabID?'disabled':''} text-white ${AddLoader==true?'btn-progress disabled':''}`} onClick={handleOk}>{!TabID?'Submit':'Update'}</a>:''}
					<a className="btn btn-light  text-dark ml-2" onClick={handleCancel}>cancel</a>
					</div>
		</Modal>
		{DashTabs?.length==0&&loader==true?<div className='tw-h-screen tw-w-full tw-transition tw-ease-in-out tw-duration-300'><GoogleLoader bodyClassName={'tw-h-[70%]'} /></div>:
            <section class="section">
                <div class="section-body">
                    <div className="row">
                        <div className="col-12 col-sm-12 col-lg-12">
                            <div className="card ">
                                <div className="card-header d-flex justify-content-between">
                                    <div className="d-flex justify-content-between" style={{ width: "100%" }}>
                                        <ul
                                            class="nav nav-pills"
                                            id="myTab3"
                                            role="tablist"
                                            style={{ width: "1500px", overflowX: "scroll", flexWrap: "nowrap" }}
                                        >
											
                                        {DashTabs?.length>0 ?
											DashTabs.map((item, id) =>
												 (
													<li class="nav-item mr-2 ">
														<button
															onClick={() => {setdashboardId('')
                                                                setDashTabDetailById(item)}}
															style={{
                                                                backgroundColor: DashTabs.findIndex((data) => data?.unique_id === dashboardId) === id ? chroma(btnColor[id]).alpha(0.4).css() : '',
                                                                color: DashTabs.findIndex((data) => data?.unique_id === dashboardId) === id ? chroma(btnColor[id]).darken(1.5).css() : btnColor[id],
                                                                borderBottom: `1px solid ${btnColor[id]}`,
																width:'max-content',
                                                                boxShadow: DashTabs.findIndex((data) => data?.unique_id === dashboardId) === id ? `0 2px 8px -3px ${btnColor[id]}`  : '',
                                                            }}
                                                            className={`!tw-py-[2px] tw-font-semibold tw-transition-all tw-bg-transparent tw-border-t-0 tw-border-r-0 tw-border-l-0 ml-2 tw-px-2 tw-mx-1 tw-my-1 tw-py-1 md:tw-px-3 md:tw-m-0 md:tw-py-2 focus:tw-outline-none ${DashTabs.findIndex((data) => data?.unique_id === dashboardId) === id ? 'tw-rounded' : 'hover:tw-bg-gray-100 hover:tw-rounded'}`}
														>
															{item.name}
														</button>
													</li>
												) 
											):'No Data Found'}
                                        </ul>
                                        <div className="card-header-action d-flex">
                                        <a class="btn btn-outline-danger mr-2" style={{marginTop:'6px'}} ><i className="fas fa-redo-alt"></i></a>
                                        <a class="btn btn-outline-success mr-2" style={{marginTop:'6px'}} onClick={()=>(userInfo?.ROLE == 'ROLE1650614500708'?onEditTab(dashboardId):toast.error('You are not authorized to Edit Dashboard Details.'))}><i className="fas fa-cog"></i></a>
										<BootstrapTooltip title="Add Dashboard" placement="top">
												<span
													className={`btn btn-primary`}
													style={{ height: "30px", padding: "2px 9px", marginTop: "6px" }}
													onClick={()=>{userInfo?.ROLE == 'ROLE1650614500708'?showModal():toast.error('You are not authorized to Add Dashboard.')}}
												>
													<i
														className="fas fa-plus "
														style={{ fontSize: "16px", paddingLeft: "5px", paddingRight: "5px" }}
													></i>
												</span>
										</BootstrapTooltip>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* {dashboardId&&<GSM_Custom_view DashTabDetailById={DashTabDetailById} onLoadCosutomDashboardList={(info)=>onLoadCosutomDashboardList(info)}/>} */}
					{dashboardId&&<GSM_Deshboard Profile={Profile} setProfile={setProfile} activeTab={'Custom Dashboard'} DashTabDetailById={DashTabDetailById} onLoadCosutomDashboardList={(info)=>onLoadCosutomDashboardList(info)} AllCategoryJson={AllCategoryJson} {...props}/>}
                </div>
            </section>}
        </>
    )
}
