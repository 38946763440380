import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import {Modal as ModaAntd} from 'antd'
import { BootstrapTooltip } from '../../Commoncomponet/common'
import GoogleLoader from '../../Commoncomponet/Loaders/GoogleLoader'
import { getAllGroups, getUsersList, createGroup, updateUserGroupJunction,updateGroup, getRolesById, getUsersListByCategory0 } from '../../Commoncomponet/Utils'
import { toast } from 'react-toastify'
import Select from 'react-select';
import moment from 'moment'

const Group = () => {
    let userInfo = JSON.parse(window.localStorage.getItem("user_info"));

    const [isLoading, setIsLoading] = useState(false)
    const [GroupData, setGroupData] = useState([])
    const [isModal, setIsModal] = useState(false)
    const [tagName, setTagName] = useState("");
    const [group_desc, setgroup_desc] = useState("");
    const [preview, setPreview] = useState("");
    const [isActive, setIsActive] = useState(false)
    const [isEditModal, setIsEditModal] = useState(false)
    const [editTagId, setEditTagId] = useState("")
    const [Tag_Type, setTag_Type] = useState(2)
    const [UserList, setUserList] = useState([])
    const [UserSelect, setUserSelect] = useState([])
    const [isEditUserModal,setisEditUserModal]=useState(false)
    const [GroupStatus,setGroupStatus]=useState(1)
    const [GroupDetail,setGroupDetail]=useState({})
    const [ismodelDetails,setismodelDetails]=useState(false)
    const [LoaderModal,setLoaderModal]=useState(false)
    const [permision,setpermision]=useState({})

    const onHandaleRoles = async () => {

        const data = await getRolesById(userInfo?.ROLE)
        if (data.success === true) {
            const obj = data.data.data[0]
            const Roles = { ...obj, ROLES_PERMISSIONS_CATEGORY0: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY0), ROLES_PERMISSIONS_CATEGORY5: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY5), ROLES_PERMISSIONS_CATEGORY2: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY2), ROLES_PERMISSIONS_CATEGORY3: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY3), ROLES_PERMISSIONS_CATEGORY1: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY1), ROLES_PERMISSIONS_CATEGORY4: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY4), ROLES_OTHER_PERMISSIONS: JSON.parse(obj.ROLES_OTHER_PERMISSIONS) }
            setpermision(Roles)
        } else {
            console.log('something want wrong')
        }
    }

    const getTagsData = async () => {
        setIsLoading(true)
        const tags = await getAllGroups({}, userInfo?.UNIQUE_ID, userInfo?.ROLE,permision?.ROLES_PERMISSIONS_CATEGORY0?.CATEGORY0_view);
        if (tags.success === true) {
            setIsLoading(false)
            setGroupData(tags.data.data)
        }
        else {
            setIsLoading(false)
            console.log("Error while getting tags data", tags.err)
        }
    }
    const ongetUsersList = async () => {
        const data =  await getUsersListByCategory0({}, userInfo?.UNIQUE_ID, userInfo?.ROLE, 'Schedule_Report',permision?.ROLES_PERMISSIONS_CATEGORY0?.CATEGORY0_view)
        if (data.success === true) {
            console.log("Data = ", data.data.data)
            setUserList(data.data.data)
        } else {
            console.log('something want wrong')
        }
    }
    useEffect(() => {
        onHandaleRoles()
        
    }, [])

    useEffect(()=>{
        getTagsData()
        ongetUsersList()
    },[permision])

    const handleOpenModal = () => {
        setIsModal(true)

    }

    const handleEditModal = (id) => {
        setIsEditModal(true)
        const tag = GroupData.find((tag) => tag.UNIQUE_ID === id);
        setEditTagId(id)
        setTagName(tag.TAG_NAME)
        setgroup_desc(tag.TAG_COLOR)
        setTag_Type(tag?.TAG_TYPE||2)
        setPreview(
            <div id="tagpreviewdiv">
                <div className={`btn btn-icon icon-left`} style={{ backgroundColor: tag.TAG_COLOR, color: "white" }}>
                    <i className="fa fa-tag fa-lg" style={{ marginRight: '4px' }}></i>
                    {tag.TAG_NAME}
                </div>
            </div>
        );
    }

    const handleModalClose = () => {
        setgroup_desc("")
        setTagName("")
        setPreview("")
        setIsModal(false)
        setUserSelect([])
    }

    const handleEditModalClose = () => {
        setgroup_desc("")
        setTagName("")
        setPreview("")
        setEditTagId("")
        setIsEditModal(false)
    setUserSelect([])
    }

    const handleEditModalUsersClose = () => {
        setEditTagId("")
        setUserSelect([])
        setisEditUserModal(false)
    }

    const colors = [
        { name: "Gray", code: "#cdd3d8" },
        { name: "Orange", code: "#ffa426" },
        { name: "Blue", code: "#3abaf4" },
        { name: "Red", code: "#fc544b" },
        { name: "Green", code: "#54ca68" },
        { name: "Black", code: "#191d21" },
        { name: "White", code: "#e3eaef" },
    ];
    const handleTagNameChange = (event) => {
        setTagName(event.target.value);
        setPreview(
            <div id="tagpreviewdiv">
                {event.target.value && group_desc ? <div class={`btn btn-icon icon-left`} style={{ backgroundColor: group_desc, color: "white" }}><i class="fa fa-tag fa-lg" style={{ marginRight: '4px' }}></i>{event.target.value}</div> : "N/A"}
            </div>
        );
    };

    const handleTagColorChange = (event) => {
        setgroup_desc(event.target.value);
        if (tagName) {
            setPreview(
                <div id="tagpreviewdiv">
                    <div className={`btn btn-icon icon-left`} style={{ backgroundColor: event.target.value, color: "white" }}>
                        <i className="fa fa-tag fa-lg" style={{ marginRight: '4px' }}></i>
                        {tagName}
                    </div>
                </div>
            );
        } else {
            setPreview("N/A");
        }
    };

    const handleSubmit = async () => {
        setLoaderModal(true)
        const addTags = await createGroup({ group_name:tagName, description:group_desc, user_ids:UserSelect, created_by:userInfo?.UNIQUE_ID});
        if (addTags.success === true) {
            getTagsData()
            handleModalClose()
        setLoaderModal(false)
        } else {
            console.log("Error while adding tags", addTags.err)
        setLoaderModal(false)

        }
    };

    const updateTag = async (id) => {
        setLoaderModal(true)

        const updateTags = await updateGroup({ updated_by: userInfo?.UNIQUE_ID,group_name:tagName, description:group_desc,status:GroupStatus },editTagId);
        if (updateTags.success === true) {
            getTagsData()
            handleEditModalClose()
        setLoaderModal(false)

        }else {
            console.log("Error while updating tags", updateTags.err)
        setLoaderModal(false)
        }
    }

    const onupdateUserGroupJunction = async (id) => {
        setLoaderModal(true)
        const updateTags = await updateUserGroupJunction({ updated_by: userInfo?.UNIQUE_ID,user_ids:UserSelect},editTagId);
        if (updateTags.success === true) {
            getTagsData()
            handleEditModalUsersClose()
        setLoaderModal(false)

        }else {
            console.log("Error while updating tags", updateTags.err)
        setLoaderModal(false)
        }
    }

    const handleStatusChange = (event) => {
        console.log("value", event.target.value)
        setIsActive(event.target.value)
    }

    const onHandalUsersEdit =(item)=>{
        setisEditUserModal(true)
        setEditTagId(item?.unique_id)
        setUserSelect(item?.user_ids.split(','))
    }

    const onHandalEdit =(item)=>{
        setIsEditModal(true)
        setEditTagId(item?.unique_id)
        setgroup_desc(item?.description)
        setTagName(item?.group_name)
        setGroupStatus(item?.status)
    }

    const onChangeInfo=(item)=>{
        setGroupDetail(item)
        setismodelDetails(true)
    }


    return (
        <>
        <ModaAntd title="All Group Detail" visible={ismodelDetails} footer="" onCancel={() => setismodelDetails(false)} width={800}>
				<div class="table-responsive">
					<table class="table table-striped" id="tabledata" style={{ width: "100%" }}>
						<thead>
							<tr>
								<th style={{ width: "50%" }}>Fields</th>
								<th style={{ width: "50%" }}>Value</th>
							</tr>
						</thead>
						<tbody>
								<>
                                {Object.entries(GroupDetail).map(([key,value])=>(
                                     <tr>
                                     <td style={{ width: "50%" }}>{key}</td>
                                     <td style={{ width: "50%" }}>{value}</td>
                                 </tr>
                                ))}
                                   
									
								</>
						</tbody>
					</table>
				</div>
			</ModaAntd>
            <Modal show={isModal} onHide={handleModalClose} >
                <Modal.Header closeButton>
                    <Modal.Title>
                        New Group
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div >
                        <div class="tw-flex tw-flex-col tw-flex-wrap tw-mb-4">
                            <label for="title2" class="tw-w-3/5 col-form-label tw-font-medium">Group name<span className='text-danger'>*</span> :</label>
                            <input
                                class="form-control" name="tag" id="tag_name" type="text" placeholder="Enter Group Name" required=""
                                value={tagName}
                                onChange={handleTagNameChange}
                            />

                        </div>
                        <div class="tw-flex tw-flex-col tw-flex-wrap tw-mb-4">
                            <label for="title1" class="tw-w-3/5 col-form-label tw-font-medium">Group Description<span className='text-danger'>*</span> :</label>
                            <textarea
                                class="form-control" name="tag" id="tag_name" type="text" placeholder="Group Description" required=""
                                value={group_desc}
                                onChange={(e)=>(setgroup_desc(e.target.value))}
                            />

                        </div>
                        <div class="tw-flex tw-flex-col tw-flex-wrap tw-mb-4">
                                <label for="assign-to" class="col-form-label required"> Users<sup className='text-danger'>*</sup> : </label>
                                <div class="">
                                    <Select isClearable placeholder='Select User...' id='assign-to'  name='account' options={UserList} onChange={(e) => { setUserSelect(e.map(item=>item?.value)) }} isMulti value={UserList?.filter((items) => (UserSelect.includes(items.value) ))} />

                                </div>
                            </div>

                        <p class="text-danger">Note : * Indicates Mandatory Fields</p>

                        <button disabled={!tagName || !group_desc || UserSelect?.length==0} type="button" onClick={handleSubmit} className={`btn btn-primary mr-2 ${LoaderModal?'disabled btn-progress':''}`}>
                            Submit
                        </button>
                    </div>

                </Modal.Body>
            </Modal>

            <Modal show={isEditModal} onHide={handleEditModalClose} >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Edit Group
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div >
                    <div class="tw-flex tw-flex-col tw-flex-wrap tw-mb-4">
                            <label for="title2" class="tw-w-3/5 col-form-label tw-font-medium">Group name<span className='text-danger'>*</span> :</label>
                            <input
                                class="form-control" name="tag" id="tag_name" type="text" placeholder="Enter Group Name" required=""
                                value={tagName}
                                onChange={handleTagNameChange}
                            />

                        </div>
                        <div class="tw-flex tw-flex-col tw-flex-wrap tw-mb-4">
                            <label for="title1" class="tw-w-3/5 col-form-label tw-font-medium">Group Description<span className='text-danger'>*</span> :</label>
                            <textarea
                                class="form-control" name="tag" id="tag_name" type="text" placeholder="Group Description" required=""
                                value={group_desc}
                                onChange={(e)=>(setgroup_desc(e.target.value))}
                            />

                        </div>
                        <div class="tw-flex tw-flex-col tw-flex-wrap tw-mb-4">
                            <label for="title" class="tw-w-3/5 col-form-label tw-font-medium">Group Status<span className='text-danger'>*</span> :</label>
                            <select class="form-control form-select" value={GroupStatus} onChange={(e)=>(setGroupStatus(e.target.value))}>
                                <option value={1}>Active</option>
                                <option value={2}>Inactive</option>
                            </select>
                        </div>
                        
                        <p class="text-danger">Note : * Indicates Mandatory Fields</p>

                        <button disabled={!tagName || !group_desc} type="button" onClick={() => updateTag(editTagId)} className={`btn btn-primary mr-2 ${LoaderModal?'disabled btn-progress':''}`}>
                            Submit
                        </button>
                    </div>

                </Modal.Body>
            </Modal>

            <Modal show={isEditUserModal} onHide={handleEditModalUsersClose} >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Edit Group Users
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div >
                    <div class="tw-flex tw-flex-col tw-flex-wrap tw-mb-4">
                                <label for="assign-to" class="col-form-label required"> Users<sup className='text-danger'>*</sup> : </label>
                                <div class="">
                                    <Select isClearable placeholder='Select User...' id='assign-to'  name='account' options={UserList} onChange={(e) => { setUserSelect(e.map(item=>item?.value)) }} isMulti value={UserList?.filter((items) => (UserSelect.includes(items.value) ))} />

                                </div>
                            </div>
                        
                        <p class="text-danger">Note : * Indicates Mandatory Fields</p>

                        <button disabled={UserSelect.length==0} type="button" onClick={() => onupdateUserGroupJunction(editTagId)} className={`btn btn-primary mr-2 ${LoaderModal?'disabled btn-progress':''}`}>
                            Submit
                        </button>
                    </div>

                </Modal.Body>
            </Modal>
            <div className="main-content">
                <section className="section">
                    <div className="card !tw-rounded-none">
            {
                isLoading ? <div className='tw-h-screen tw-w-full tw-transition tw-ease-in-out tw-duration-300'>
                    <GoogleLoader bodyClassName={'tw-h-[70%]'} />
                </div> : <>
                    <div >
                        <div className="border-bottom border-top">
                            <div className='tw-px-5 tw-py-3 tw-flex tw-justify-between tw-items-center '>

                                <h4 className='tw-mb-0'>Users Group</h4>
                                <div className="">
                                    <button type="button" className="btn btn-primary mr-1" onClick={handleOpenModal} >
                                        <BootstrapTooltip title="Task tag">
                                            <div className='tw-flex tw-items-center tw-gap-1 '>
                                                <i class="fas fa-plus" aria-hidden="true"></i>
                                                <span>
                                                    New Group
                                                </span>
                                            </div>
                                        </BootstrapTooltip>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div className="table-responsive">
                                <table className="table table-striped text-center" id="table-1">
                                    <thead>
                                        <tr>
                                            <th className="text-center" style={{ maxWidth: "100px" }}>sr_no</th>
                                            <th >unique_id</th>
                                            <th >group_name</th>
                                            <th>description</th>
                                            {/* <th>created_by</th>
                                            <th>created_at</th>
                                            <th>updated_by</th>
                                            <th>updated_at</th> */}
                                            <th>users_names</th>
                                            <th style={{ maxWidth: "100px" }}>Status</th>
                                            <th>Action</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {GroupData &&
                                            GroupData.map((item, id) => (
                                                <tr style={{ height: "4rem" }} className="trheigth" key={id}>
                                                   <td className="text-center" style={{ maxWidth: "25px" }}>{item?.sr_no}</td>
                                                   <td class="tw-px-2 tw-py-3 tw-text-center tw-text-blue-600 tw-cursor-pointer tw-group" onClick={()=>onHandalEdit(item)}>
                                                            <div className='tw-flex tw-items-center  tw-gap-2 tw-mx-2'>
                                                                <span>{item?.unique_id}</span>
                                                                <div className="tw-opacity-0 tw-transition-all group-hover:tw-opacity-100" >
                                                                <i className='fas fa-edit'></i>
                                                                </div>
                                                            </div>
                                                        </td>
                                            
                                            <td >{item?.group_name}</td>
                                            <td>{item?.description}</td>
                                            {/* <td>{item?.created_by_name}</td>
                                            <td>{moment.utc(item?.created_at).format('DD-MM-YYYY hh:mm:ss A')}</td>
                                            <td>{item?.updated_by}</td>
                                            <td>{moment.utc(item?.updated_at).format('DD-MM-YYYY hh:mm:ss A')}</td> */}
                                            <td class="tw-px-2 tw-py-3 tw-text-center tw-text-blue-600 tw-cursor-pointer tw-group" onClick={()=>onHandalUsersEdit(item)}>
                                                            <div className='tw-flex tw-items-center  tw-gap-2 tw-mx-2'>
                                                                <span>{item?.user_names}</span>
                                                                <div className="tw-opacity-0 tw-transition-all group-hover:tw-opacity-100" >
                                                                <i className='fas fa-edit'></i>
                                                                </div>
                                                            </div>
                                                        </td>
                                            {/* <td onClick={()=>onHandalUsersEdit(item)} style={{cursor:'pointer'}}>{item?.user_names}</td> */}
                                            <td style={{ maxWidth: "100px" }}>{item?.status==1?'Active':'InActive'}</td>
                                            <td><a
																				onClick={() =>
																					onChangeInfo(item)
																				}
																				class="btn-sm btn-primary btn-sm "
                                                                                style={{padding:'4px',fontSize:'11px'}}
																			>
																				<i
																					class="fa fa-info-circle "
																					aria-hidden="true"
																					style={{ color: "white" }}
																				></i>
																			</a></td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </>
            }
            </div>
                </section>
            </div>
        </>
    )
}

export default Group