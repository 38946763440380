import React from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useState } from "react";

import { useEffect } from "react";
import * as $ from "jquery";
import dt from "datatables.net-dt";
import { FilterIdWiseNewFilterGenerate, getgsmReportActivityLogs, getReportBytypeName, getReportDataInConsumerDetails, GetReportType, geturls3, getUsersList, sentReportFilestoMail } from "../../Commoncomponet/Utils";
import { AttachmanyUrl, BootstrapTooltip, cancelTokenSource, exportCSVFile, ReportUrl } from "../../Commoncomponet/common";
import moment from "moment";
import fileDownload from "js-file-download";
import { Users } from "react-feather";
import { Modal, Spin } from "antd";
import Pagination from "../../Commoncomponet/Pagination";
import chroma from "chroma-js";
import { toast } from "react-toastify";
import { dataFormateForHCR, fetchData, generateExcelByData, generateExcel, summeryReportDataFormatr } from "../../Commoncomponet/common";
import { Dropdown } from "react-bootstrap";

import './Report.css'
import ExcelGenerationModal from "./ReportsModal";
$.fn.DataTable = dt;
export default function Reports() {
	const [reportData, setReportData] = useState([]);
	const userInfo = JSON.parse(window.localStorage.getItem("user_info"));
	const [loader, setLoader] = useState(false);
	const [reportDetails, setreportDetails] = useState([])
	const [ConsumerModel, setConsumerModel] = useState(false)
	const [historyDataLoader, sethistoryDataLoader] = useState(false)
	const [historyDataModale, sethistoryDataModale] = useState(false)
	const btnColor = ['#4f5ece', '#f59e0b', '#fc544b', '#3abaf4', '#54ca68', '#191d21', '#7c5295', '#4f5ece']

	const buttonArray = [
		{ Name: "All", Type: "All" },
		{ Name: "All GSM Consumer Details", Type: "allgsmconsumerlist" },
	];
	const report_type = ["All", "allgsmconsumerlist"];
	const [btnText, setButtontext] = useState("Report Info");
	const [btnSubText, setButtonSubtext] = useState("Schedule");
	const [ReportType, setReportType] = useState("All");
	const [numberofPage, setnumberofPage] = useState(1);
	const [ReportTypes, setReportTypes] = useState([])
	const [ConsumerDetails, setConsumerDetails] = useState([])
	const [modalText, setmodalText] = useState('')
	const [typeDownload, settypeDownload] = useState('Download')
	const [sentDetails, setsentDetails] = useState({})
	const [UserList, setUserList] = useState([])
	const [inputmail, setinputmail] = useState('')
	const [isLoading, setIsLoading] = useState(false);
	const [progress, setProgress] = useState(0);
	const [fileType, setFileType] = useState(null);
	const [cancelToken, setCancelToken] = useState(false);
	const model_tabes = ['Report Info', 'Sent Details']
	const model_sub_sent_tabes = ['Schedule', 'Manual']

	const onGetReportTypeGSM = async () => {
		const data = await GetReportType({ type: 'GSM' })
		if (data.success == true) {
			setReportTypes(data?.data?.data?.map((item) => ({ value: item?.BACK_TYPE_NAME, label: item?.FRONT_TYPE_NAME })))
		} else {

		}
	}

	const ongetReportUserData = async (ReportType) => {
		setLoader(true);
		const firstPageIndex = (currentPage - 1) * PageSize;
		const data = await getReportBytypeName(userInfo?.UNIQUE_ID, userInfo?.ROLE, ReportType, { start: firstPageIndex, end: PageSize });
		if (data.success === true) {
			setReportData(data.data.data);
			setnumberofPage(data.data.total_count)
			setLoader(false)

		} else {
			console.log("something want wrong");
			setLoader(false);
		}
	};

	const [PageSize, setPageSize] = useState(50);
	const [currentPage, setCurrentPage] = useState(1);
	const ongetUsersList = async () => {
		const data = await getUsersList()
		if (data.success === true) {
			setUserList(data.data.data)
		} else {
			console.log('something want wrong')
		}
	}
	useEffect(() => {
		ongetReportUserData(ReportType)
		ongetUsersList()
		// .then(() =>
		// 	setTimeout(function () {
		// 		$("#table-1").DataTable({ destroy: true, pageLength: 50, order: [[0, "desc"]] });
		// 		setLoader(false);
		// 	}, 1100)
		// );
		onGetReportTypeGSM()
	}, [ReportType]);
	useEffect(() => {
		ongetReportUserData(ReportType)

	}, [currentPage, PageSize]);
	const onHandalebtn = (text) => {
		setButtontext(text);
		setReportType(text);
	};

	const history = useHistory();

	const showHistory = (reportType) => {
		console.log("first");
		history.push({ pathname: "/reportHistory", state: reportType });
	};

	const [info, setInfo] = useState({});
	const [ismodelFilter, setismodelFilter] = useState(false);
	const [InfoType, setInfoType] = useState("");
	const [ismodelFilterObj, setismodelFilterObj] = useState(false);
	const [FilterObject, setFilterObject] = useState({});
	const [SRNOReport, setSRNOReport] = useState({});

	const onChangeInfo = (info, add_by, add_at, TYPE, item) => {
		if (info) {
			const obj = JSON.parse(info || "{}");
			ongetActivityData(btnSubText == 'Schedule' ? item?.SCHEDULE_ID : '', item?.UNIQUE_ID, btnSubText == 'Schedule' ? 'Schedule Reports' : 'Manual Sent Reports')
			setInfo({ ...item, ...obj, add_by: add_by, add_at: add_at });
			setInfoType(TYPE);
			setismodelFilter(true);
			setSRNOReport(item)
		}
	};

	const onFilterIdWiseNewFilterGenerate = async (filterObj) => {
		setismodelFilterObj(true);
		const data = await FilterIdWiseNewFilterGenerate({ Filter_Obj: filterObj });
		if (data.success == true) {
			setFilterObject(data.data.data);
		} else {
			console.log("something wait wrong");
		}
	};

	const onRefreshBtn = () => {
		ongetReportUserData(ReportType)
		// .then(() =>
		// 	setTimeout(function () {
		// 		$("#table-1").DataTable({ destroy: true, pageLength: 50, order: [[0, "desc"]] });
		// 		setLoader(false);
		// 	}, 1100)
		// );
	};

	const onGetConsumerDetails = async (col, SRNO, text) => {
		setmodalText(text)
		const data = await getReportDataInConsumerDetails({ Column: col, TableName: 'gsm_reports', SRNO: SRNO })
		if (data.success == true) {
			setConsumerDetails(JSON.parse(data?.data?.data || '{}'))
			setConsumerModel(true)
		}
	}

	const ongetActivityData = async (schedule_report_id, report_id, type) => {
		sethistoryDataLoader(true)
		const data = await getgsmReportActivityLogs({ log_type: type, schedule_report_id: schedule_report_id, report_id: report_id })
		if (data.success === true) {
			if (type == 'Schedule Reports') {
				setsentDetails(data.data.data[0])
			} else {
				setsentDetails(data.data.data)
			}
			sethistoryDataLoader(false)
			// sethistoryDataModale(true)
		} else {
			console.log('something want wrong')
			sethistoryDataLoader(false)
		}
	}

	useEffect(() => {
		ongetActivityData(btnSubText == 'Schedule' ? info?.SCHEDULE_ID : '', info?.UNIQUE_ID, btnSubText == 'Schedule' ? 'Schedule Reports' : 'Manual Sent Reports')
	}, [btnSubText])

	const onSubmitReports = async () => {
		if (typeDownload == 'Download') {
			onDownloadFiles(reportDetails?.FILENAME);
		} else {
			const emailRegex = new RegExp(/^[A-Za-z0-9_!#$%&'*+\/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/, "gm");
			const checks = emailRegex.test(inputmail)
			if (inputmail == '' || !checks) {
				return toast.error('Please Enter Valid Mail')
			}
			const data = await sentReportFilestoMail({ fileName: reportDetails?.FILENAME, report_name: reportDetails?.NAME, EMAIL: inputmail, report_id: reportDetails?.UNIQUE_ID })
			if (data.success == true) {
				setinputmail('')
				sethistoryDataModale(false)
				settypeDownload('Download')
				toast.success('Successfully Send')
			} else {
				console.log('something want wrong')

			}
		}
	}

	const headerConfig = {
		"Sr No": {
			rowspan: 3,
			colspan: 1,
			style: {
				alignment: { vertical: "middle", horizontal: "center" },
				font: { bold: true }
			}
		},
		"Name": {
			rowspan: 3,
			colspan: 1,
			style: {
				alignment: { vertical: "middle", horizontal: "center" },
				font: { bold: true }
			}
		},
		"Body": {
			rowspan: 3,
			colspan: 1,
			style: {
				alignment: { vertical: "middle", horizontal: "center" },
				font: { bold: true }
			}
		}
	};

	const onDownloadFiles = async (name, type, reportType) => {

		try {
			setFileType(name.split('.')[0]+'.'+type)
			console.log('=======>',cancelToken)
			setProgress(0)
			setIsLoading(true)
			var fillBgLookup = {};

			if(reportType === 'RTI5656754456'){
				fillBgLookup['No'] = {
					bgColor:"FFFFC7CE",patternType:"solid"
				}
			}
			const data1 = await new Promise((resolve, reject) => {
				let geturlsProgress = 0;
				const geturlsInterval = setInterval(() => {
					geturlsProgress += 5; // Simulate progress
					if (geturlsProgress > 45) {
						clearInterval(geturlsInterval);
					}
					setProgress(geturlsProgress);
				}, 100);

				geturls3({
					bucketName: 'gums.memighty.com',
					objectKey: `GSM_CONSUMER_REPORT/${name}`,
					expiration: 60
				}).then(result => {
					clearInterval(geturlsInterval);
					setProgress(10); // Set to 50% after geturls3 completes
					resolve(result);
				}).catch(error => {
					clearInterval(geturlsInterval);
					reject(error);
				});
			});
			const responseData = await fetchData(data1?.data?.data, progress => {
				setProgress((progress * 0.50) + 10);
			});
			console.log(responseData)


			if (type === 'Excel') {
				const finalData = processData(responseData, reportType)
				console.log(finalData)
				generateExcelByData({ data: finalData, reportName: name.split('.')[0], reportType: reportType === 'RTI3243454563' || responseData[0]?.header ? "array" : 'JSON', headerConfig :responseData[0]?.header , fillBgLookup:fillBgLookup, cancelToken :false,setProgress, 
				setIsLoading})
			}

			if (type === 'csv') {
				if (responseData[0]?.data.length > 0) {
					var finalData = responseData[0]?.data
					if(responseData[0]?.header){
						finalData	= processData(responseData, reportType)
					}
					exportCSVFile(responseData[0]?.header ? null : Object.keys(responseData[0]?.data[0]), finalData, name.split('.')[0],false)
					setProgress(100)
					setIsLoading(false)
					toast.success(`${name.split('.')[0]}.csv generated successfully`);
				} else {
					toast.error('Data Not available')
				}
			}
		
			// cancelToken&&cancelToken.abort();
		} catch (error) {
			toast.error(error.message);
			setIsLoading(false);
			// cancelToken&&cancelToken.abort();

		}



	};

	function processData(rawData, reportType) {
		if (reportType === 'RTI3243454563') {
			return summeryReportDataFormatr(rawData[0]?.data);
		}
		if (rawData[0]?.header) {
			console.log(rawData)
			return dataFormateForHCR(rawData);
		}
		return rawData[0]?.data;
	}

	const handleCancel = () => {
		setIsLoading(false);
		setProgress(0);
		
	};

	return (
		<>
			<Modal title="Info" visible={ismodelFilter} footer="" onCancel={() => {
				setismodelFilter(false)
				setsentDetails([])
			}} width={800}>
				{model_tabes.map((item, id) => (
					<button
						style={{
							backgroundColor: model_tabes.findIndex((data) => data === btnText) === id ? chroma(btnColor[id]).alpha(0.4).css() : '',
							color: model_tabes.findIndex((data) => data === btnText) === id ? chroma(btnColor[id]).darken(1.5).css() : btnColor[id],
							borderBottom: `1px solid ${btnColor[id]}`,
							boxShadow: model_tabes.findIndex((data) => data === btnText) === id ? `0 2px 8px -3px ${btnColor[id]}` : '',
						}}
						className={`tw-font-semibold tw-transition-all tw-bg-transparent tw-border-t-0 tw-border-r-0 tw-border-l-0 ml-2 tw-px-2 tw-mx-1 tw-my-1 tw-py-1 md:tw-px-3 md:tw-m-0 md:tw-py-2 focus:tw-outline-none ${model_tabes.findIndex((data) => data === btnText) === id ? 'tw-rounded' : 'hover:tw-bg-gray-100 hover:tw-rounded'}`}

						onClick={() => setButtontext(item)}>
						{item}
					</button>
				))}
				{btnText == 'Report Info' ? <div class="table-responsive">
					<table class="table table-striped" id="tabledata" style={{ width: "100%" }}>
						<thead>
							<tr>
								<th style={{ width: "50%" }}>Fields</th>
								<th style={{ width: "50%" }}>Value</th>
							</tr>
						</thead>
						<tbody>
							{InfoType == "allgsmconsumerlist" || InfoType == "allgsmconsumer" ? (
								<>
									<tr>
										<td style={{ width: "50%" }}>Report Type</td>
										<td style={{ width: "50%" }}>{info?.Report_Type}</td>
									</tr>
									<tr>
										<td style={{ width: "50%" }}>Created BY</td>
										<td style={{ width: "50%" }}>{info?.CREATED_BY}</td>
									</tr>
									<tr>
										<td style={{ width: "50%" }}>Created At</td>
										<td style={{ width: "50%" }}>{moment.utc(info.CREATED_AT).format("DD/MM/YYYY hh:mm:ss A")}</td>
									</tr>
									<tr>
										<td style={{ width: "50%" }}>Generated AT</td>
										<td style={{ width: "50%" }}>{moment(info.Date_Time).format("DD/MM/YYYY hh:mm:ss A")}</td>
									</tr>
								</>
							) : (
								<>
									<tr>
										<td style={{ width: "50%" }}>Report Type</td>
										<td style={{ width: "50%" }}>{info?.NAME}</td>
									</tr>
									<tr>
										<td style={{ width: "50%" }}>Start Date</td>
										<td style={{ width: "50%" }}>{moment(info.START_DATE).format("DD-MM-YYYY")}</td>
									</tr>
									<tr>
										<td style={{ width: "50%" }}>End Date</td>
										<td style={{ width: "50%" }}>{moment(info.END_DATE).format("DD-MM-YYYY")}</td>
									</tr>
									<tr>
										<td style={{ width: "50%" }}>Created Date Time</td>
										<td style={{ width: "50%" }}>{moment.utc(info.CREATED_AT).format("DD/MM/YYYY hh:mm:ss A")}</td>
									</tr>
									<tr>
										<td style={{ width: "50%" }}>Created By</td>
										<td style={{ width: "50%" }}>{info?.CREATED_BY}</td>
									</tr>
									<tr>
										<td style={{ width: "50%" }}>Generated Date Time</td>
										<td style={{ width: "50%" }}>{moment(info.Date_Time).format("DD/MM/YYYY hh:mm:ss A")}</td>
									</tr>
									<tr>
										<td style={{ width: "50%" }}>Total Selected Consumer</td>
										<td style={{ width: "50%" }}>{info?.Total_Consumer_Count}</td>
									</tr>
									<tr>
										<td style={{ width: "50%" }}>Found Data Table</td>
										<td style={{ width: "50%", cursor: 'pointer' }} onClick={() => onGetConsumerDetails('REPORT_DAILY_EXIST', SRNOReport?.SRNO, 'Found Data Table')}>{info?.Total_Daily_Consumer_Record_Exist}</td>
									</tr>
									<tr>
										<td style={{ width: "50%" }}>Not Found Data Table</td>
										<td style={{ width: "50%", cursor: 'pointer' }} onClick={() => onGetConsumerDetails('REPORT_DAILY_NOT_EXIST', SRNOReport?.SRNO, 'Not Found Data Table')}>{info?.Total_Daily_Consumer_Record_Not_Exist}</td>
									</tr>
									<tr>
										<td style={{ width: "50%" }}>Records Exist</td>
										<td style={{ width: "50%", cursor: 'pointer' }} onClick={() => onGetConsumerDetails('REPORT_DATA_FOUND', SRNOReport?.SRNO, 'Records Exist')}>{info?.Record_Found_from_Daily_Consumer}</td>
									</tr>
									<tr>
										<td style={{ width: "50%" }}>Records Not Exist</td>
										<td style={{ width: "50%", cursor: 'pointer' }} onClick={() => onGetConsumerDetails('REPORT_DATA_NOT_FOUND', SRNOReport?.SRNO, 'Records Not Exist')}>{info?.Record_Not_Found_from_Daily_Consumer}</td>
									</tr>
									<tr>
										<td style={{ width: "50%" }}>Null Value</td>
										<td style={{ width: "50%", cursor: 'pointer' }} onClick={() => onGetConsumerDetails('REPORT_DATA_FOUND_VALUE_NULL', SRNOReport?.SRNO, 'Null Value')}>{info?.Record_Found_Value_Null_from_Daily_Consumer}</td>
									</tr>
									<tr>
										<td style={{ width: "50%" }}>ScriptDecodeID Not Match</td>
										<td style={{ width: "50%", cursor: 'pointer' }} onClick={() => onGetConsumerDetails('SCRIPT_ID_NOT_MATCH', SRNOReport?.SRNO, 'ScriptDecodeID Not Match')}>{info?.script_id_not_match}</td>
									</tr>
									<tr>
										<td style={{ width: "50%" }}>Total Records Count</td>
										<td style={{ width: "50%" }}>{info?.Total_Records_Count}</td>
									</tr>
								</>
							)}
						</tbody>
					</table>
				</div> : ''}
				{btnText == 'Sent Details' ? <>
					<div className="mt-2">
						{model_sub_sent_tabes.map((item, id) => (

							<button
								style={{
									backgroundColor: model_sub_sent_tabes.findIndex((data) => data === btnSubText) === id ? chroma(btnColor[id]).alpha(0.4).css() : '',
									color: model_sub_sent_tabes.findIndex((data) => data === btnSubText) === id ? chroma(btnColor[id]).darken(1.5).css() : btnColor[id],
									borderBottom: `1px solid ${btnColor[id]}`,
									boxShadow: model_sub_sent_tabes.findIndex((data) => data === btnSubText) === id ? `0 2px 8px -3px ${btnColor[id]}` : '',
								}}
								className={`tw-font-semibold tw-transition-all tw-bg-transparent tw-border-t-0 tw-border-r-0 tw-border-l-0 ml-2 tw-px-2 tw-mx-1 tw-my-1 tw-py-1 md:tw-px-3 md:tw-m-0 md:tw-py-2 focus:tw-outline-none ${model_sub_sent_tabes.findIndex((data) => data === btnSubText) === id ? 'tw-rounded' : 'hover:tw-bg-gray-100 hover:tw-rounded'}`}

								onClick={() => setButtonSubtext(item)}>
								{item}
							</button>
						))}
					</div>
					{historyDataLoader && <Spin size="small" />}
					{btnSubText == 'Schedule' ? Object.keys(JSON.parse(sentDetails?.response_json || '{}')).length > 0 ? <div class="table-responsive mt-3">
						<table class="table table-striped" id="tabledata" style={{ width: "100%" }}>
							<thead>
								<tr>
									<th>SRNO</th>
									<th>User Name</th>
									<th>User Email</th>
									<th>Sent Status</th>
									<th>Response</th>
								</tr>
							</thead>
							<tbody>
								{console.log(JSON.parse(sentDetails?.response_json || '{}'))}
								{Object.keys(JSON.parse(sentDetails?.response_json || '{}'))?.length > 0 && Object.entries(JSON.parse(sentDetails?.response_json || '{}')).map(([keys, value], id) => (<tr>
									<td>{id + 1}</td>
									<td>{UserList.map(item => item?.value === keys ? `${item?.label}` : '')}</td>
									<td>{value?.envelope?.to}</td>
									<td>{value?.response?.error == true ? <>Fail</> : <>success</>}</td>
									<td>{JSON.stringify(value)}</td>
								</tr>
								))}
							</tbody>
						</table>
					</div> : <div className='text-center tw-w-[100%] mt-3'><h4 >No Data Found</h4></div> : sentDetails.length > 0 ? <div class="table-responsive mt-3">
						<table class="table table-striped" id="tabledata" style={{ width: "100%" }}>
							<thead>
								<tr>
									<th>SRNO</th>
									{/* <th>User Name</th> */}
									<th>User Email</th>
									<th>Sent Status</th>
									<th>Response</th>
								</tr>
							</thead>
							<tbody>

								{sentDetails?.length > 0 && sentDetails.map((item, id) => (<tr>
									<td>{id + 1}</td>
									{/* <td>{UserList.map(item=>item?.value===keys?`${item?.label}`:'')}</td> */}
									<td>{JSON.parse(item?.response_json || '{}')?.envelope?.to}</td>
									<td>{JSON.parse(item?.response_json || '{}')?.response?.error == true ? <>Fail</> : <>success</>}</td>
									<td>{item?.response_json}</td>
								</tr>
								))}
							</tbody>
						</table>
					</div> : <div className='text-center tw-w-[100%] mt-3'><h4 >No Data Found</h4></div>}
				</> : ''}
			</Modal>
			<Modal title={<><span>Unique No: {reportDetails?.SRNO}</span></>} visible={historyDataModale} footer="" onCancel={() => {
				sethistoryDataModale(false)
			}} width={300}>
				<div className="from-row">
					<div className="col-12">
						<div className="form-group">
							<input type="radio" name="login_type" className="mr-2" id='text1' onChange={(e) => (settypeDownload('Download'))} checked={typeDownload == 'Download' ? true : false}></input>
							<label for={'text1'}>Download report</label>
						</div>
					</div>
					<div className="col-12">
						<div className="form-group">
							<input type="radio" name="login_type" id='text2' className="mr-2" onChange={(e) => (settypeDownload('push report to mail'))} checked={typeDownload == 'push report to mail' ? true : false}></input>
							<label for={'text2'}>Send To Mail</label>
						</div>
					</div>
					{typeDownload === 'push report to mail' && <div className="col-12">
						<div className="form-group">
							<label for={'text2'}>Enter Mail :<spna className='text-danger'>*</spna></label>
							<input type="type" placeholder="abc@gmail.com" name="login_type" id='text2' className="mr-2 form-control" onChange={(e) => (setinputmail(e.target.value))} value={inputmail}></input>
						</div>
					</div>}
				</div>
				<div className="m-2">
					<div className="btn btn-danger mr-2 " onClick={() => (onSubmitReports())}> Submit</div>
					<div className="btn btn-secondary"> Cancel</div>
				</div>
			</Modal>
			<Modal title={modalText} visible={ConsumerModel} footer="" onCancel={() => {
				setConsumerModel(false)
				setConsumerDetails([])
			}} width={1000}>
				<div class="table-responsive">
					<table class="table table-striped" id="tabledata" style={{ width: "100%" }}>
						<thead>
							<tr>
								<th>SRNO</th>
								<th>UNIQUE ID</th>
								<th>DEVICE SERIAL NO</th>
								<th>Address Compony Name</th>
							</tr>
						</thead>
						<tbody>
							{ConsumerDetails.length > 0 && ConsumerDetails.map((item, id) => (<tr>
								<td>{id + 1}</td>
								<td>{item?.UNIQUE_ID}</td>
								<td>{item?.DEVICE_SERIAL_NO}</td>
								<td>{item?.address_compony_name}</td>
							</tr>
							))}
						</tbody>
					</table>
				</div>
			</Modal>
			<Modal
				title="Filter Object"
				visible={ismodelFilterObj}
				footer=""
				onCancel={() => {
					setismodelFilterObj(false);
					setFilterObject({});
				}}
			>
				<div>{JSON.stringify(FilterObject || "{}")}</div>
			</Modal>
			<ExcelGenerationModal
				fileType={fileType}
				progress={progress}
				isLoading={isLoading}
				onCancel={handleCancel}
			/>
			{loader ? (
				<div className="main-content">
					<div class="loader"></div>
				</div>
			) : (
				""
			)}
			{/* <div className="main-content" style={{ minHeight: "562px" }}> */}

				{/* <nav aria-label="breadcrumb">
					<ol className="breadcrumb ">
						<li className="breadcrumb-item">
							<Link to="/all-dashboard">
								<i className="fas fa-tachometer-alt"></i> Home
							</Link>
						</li>
						<li className="breadcrumb-item">
							<Link>
								<Users style={{ height: "16px" }} /> Consumers
							</Link>
						</li>

						<li className="breadcrumb-item">
							<Link to='/All-GSM'>
								GSM
							</Link>
						</li>
						<li className="breadcrumb-item">
							<Link >
								GSM Report History
							</Link>
						</li>

					</ol>
				</nav> */}

				<section class="section">
					<section class="section-body">
						{/* <div className="row">
					<div className="col-12">
						<div className="card pb-2">
							<div className="col-12" style={{ marginTop: "15px" }}>
								<div className="buttons">
									{buttonArray.map((item, id) => (
										<div
											className={`text-${item === 'Consumer Info' ? 'dark' : 'white'} btn ${item === "All" && "btn-primary"} ${item === "All GSM Consumer Details" && "btn-secondary"
												} ${item === "Configuration" && "btn-danger"} ${item === "Modem Data" && "btn-info"} ${item === "Meter Data" && "btn-success"
												}  ${item === "Buffer" && "btn-dark"}  ${item === "Extra" && "btn-purple "}  dashboard  ${buttonArray.findIndex((data) => data === btnText) === id ? "btn-lg btn-text-lg" : ""
												} mb-1 `}
											style={{ marginBottom: "8px" }}
											onClick={() => onHandalebtn(item,id)}
										>
											{item}
										</div>
									))}
									<div class="float-right text-right">
										
										<Link to="/All-GSM" class="btn btn-info text-white align-left">
											Back
										</Link>
									</div>
								</div>
							</div>
							</div>
							</div>
							</div> */}
					</section>
				</section>
				<section class="section">
					<section class="section-body">
						<div class="row">
							<div class="col-12">
								<div class="card">
									<div class="card-header p-2" style={{ justifyContent: "space-between" }}>
										<div class="form-group mb-0 mr-2">
											<select
												id="inputState"
												class="form-select form-control"
												name="modem_name"
												onChange={(e) => onHandalebtn(e.target.value)}
												value={ReportType}
												style={{ width: '300px', height: '35px', padding: '0px 15px' }}
											>
												<option value={'All'}>All</option>
												{ReportTypes?.map((item) => (<option value={item?.value}>{item?.label}</option>))}
											</select>
										</div>
										<div className="d-flex 	">
											<BootstrapTooltip title='Refresh'><div><a
												class="btn btn-info text-white mt-1"
												onClick={onRefreshBtn}
												data-toggle="tooltip"
												data-placement="bottom"
												title=""
												data-original-title="Refresh"
											// style={{height: '32px',display: 'flex',flexDirection: 'column',justifyContent: 'center',borderRadius:'2px'}}
											>
												<i class="fa fa-refresh" aria-hidden="true"></i>
											</a></div></BootstrapTooltip>
										</div>
									</div>
									<div class="card-body">
										{reportData.length > 0 ? (
											<div className="table-responsive tw-h-screen tw-max-h-screen">
												<div id="table-1_wrapper" class="dataTables_wrapper no-footer">

													<table id="table_id" className="table border text-center table-borderless table-striped font-10">
														<thead className='tw-sticky tw-z-10 tw-top-0  tw-bg-gray-100'>
															<tr>
																<th className="text-center !tw-font-semibold" style={{ maxWidth: "100px" }}>
																	Unique No
																</th>
																<th className="!tw-font-semibold text-center">Report Name</th>
																<th className="!tw-font-semibold text-center" style={{ maxWidth: "100px" }}>
																	START_DATE
																</th>
																<th className="!tw-font-semibold text-center" style={{ maxWidth: "100px" }}>
																	END_DATE
																</th>
																<th className="!tw-font-semibold text-center" style={{ maxWidth: "100px" }}>
																	Created At
																</th>
																<th className="!tw-font-semibold text-center" style={{ maxWidth: "100px" }}>
																	Created By
																</th>
																<th className="!tw-font-semibold text-center" style={{ maxWidth: "100px" }}>
																	Status
																</th>
																<th className="!tw-font-semibold text-center">Trigger Type</th>
																<th className="!tw-font-semibold text-center">Schedule Id</th>
																<th className="!tw-font-semibold text-center">Download</th>
																<th className="!tw-font-semibold text-center">Info</th>
																{/* <th className="!tw-font-semibold text-center">Sent Response</th> */}
																<th className="!tw-font-semibold text-center">Filter Object</th>
															</tr>
														</thead>
														<tbody>
															{reportData &&
																reportData.map((item, id) => (
																	<tr style={{ height: "4rem" }} className="trheigth hover:tw-bg-[#D8D8D8]" key={id}>
																		<td className="text-center">{item?.SRNO}</td>
																		<td className="text-center">{item?.NAME}</td>

																		<td className="text-center">
																			{item?.TYPE === 'RTI1715060750' || item?.TYPE === 'RTI1715060841' ? moment(item?.START_DATE, 'YYYY-MM').format("MM-YYYY") : item?.START_DATE
																				? moment.utc(item?.START_DATE).format("DD/MM/YYYY ")
																				: "N/A"}
																		</td>
																		<td className="text-center">
																			{item?.END_DATE
																				? moment.utc(item?.END_DATE).format("DD/MM/YYYY")
																				: "N/A"}
																		</td>
																		<td className="text-center">
																			{item?.CREATED_AT
																				? moment.utc(item.CREATED_AT).format("DD/MM/YYYY hh:mm:ss A")
																				: "N/A"}
																		</td>
																		<td className="text-center">
																			{item?.CREATED_BY}
																		</td>
																		<td className="text-center">
																			<div
																				className={`badge ${item?.STATUS == 1
																					? "badge-success"
																					: item?.STATUS == 2 && moment(moment.utc().add(moment().utcOffset(), "m").format()).diff(moment(moment.utc(item.CREATED_AT).format()), 'minutes') > 25
																						? "badge-danger"
																						: item?.STATUS == 2
																							? "badge-warning"
																							: "badge-danger"
																					} badge-shadow text-white`}
																			>
																				{item?.STATUS == 1
																					? "Success"
																					: item?.STATUS == 2 && moment(moment.utc().add(moment().utcOffset(), "m").format()).diff(moment(moment.utc(item.CREATED_AT).format()), 'minutes') > 25
																						? "Failed"
																						: item?.STATUS == 2
																							? "Pending Report"
																							: "Failed"}
																			</div>
																		</td>
																		<td className="text-center">
																			<div
																				className={`badge ${item?.GENERATED_BY == 'Schedule'
																					? "badge-primary" : "badge-info"
																					} badge-shadow text-white`}
																			>
																				{item?.GENERATED_BY == 'Schedule' ? "Schedule" : 'Manual'}
																			</div>
																		</td>
																		<td className="text-center">{item?.SCHEDULE_ID||'-'}</td>
																		<td className="text-center">
																			{item?.STATUS == 1 ?
																				<Dropdown className="d-inline ">
																					<Dropdown.Toggle variant="warning" className="!tw-py-0.5 !tw-px-2.5 " id="dropdown-basic">
																						<i class="fa fa-download" aria-hidden="true"></i>
																					</Dropdown.Toggle>

																					<Dropdown.Menu   >
																						<Dropdown.Item onClick={() => onDownloadFiles(item?.FILENAME, 'Excel', item?.TYPE)} className="!tw-py-2 tw-px-1 !tw-font-medium" >
																							Excel
																						</Dropdown.Item>
																						{
																							item?.TYPE !== "RTI3243454563" && <Dropdown.Item onClick={() => onDownloadFiles(item?.FILENAME, 'csv')} className="!tw-py-2 tw-px-1 !tw-font-medium" >
																								csv
																							</Dropdown.Item>
																						}


																					</Dropdown.Menu>
																				</Dropdown>


																				// <a
																				// 	class="btn-sm btn-warning text-white"
																				// 	onClick={() => {
																				// 		// onDownloadFiles(item?.FILENAME);
																				// 		sethistoryDataModale(true)
																				// 		setreportDetails(item)
																				// 	}}
																				// 	download=""
																				// 	target={"_blank"}
																				// >
																				// 	<i class="fa fa-download" aria-hidden="true"></i>
																				// </a> 
																				: ''}
																		</td>
																		<td className="text-center">
																		{item?.STATUS == 1?<a
																				onClick={() =>
																					onChangeInfo(
																						item?.INFO,
																						item?.CREATED_BY,
																						item?.CREATED_AT,
																						item?.TYPE,
																						item
																					)
																				}
																				class="btn-sm btn-primary btn-sm font-14 text-center"
																			>
																				<i
																					class="fa fa-info-circle "
																					aria-hidden="true"
																					style={{ color: "white" }}
																				></i>
																			</a>:''}
																		</td>

																		{/* {item?.GENERATED_BY=='Schedule'?<td className="text-center">
																			<a
																				onClick={() =>
																					ongetActivityData(item?.SCHEDULE_ID,item?.UNIQUE_ID)
																				}
																				class="btn-sm btn-primary btn-sm font-14 text-center"
																			>
																				<i
																					class="fa-solid fa-share-from-square"
																					aria-hidden="true"
																					style={{ color: "white" }}
																				></i>
																			</a>
																		</td>:<td></td>} */}

																		<td className="text-center">
																			<a
																				class="btn-sm btn-info btn-sm font-14 text-center"
																				onClick={() => onFilterIdWiseNewFilterGenerate(item?.REPORT_FILTERS)}
																			>
																				<i
																					class="fas fa-filter"
																					aria-hidden="true"
																					style={{ color: "white" }}
																				></i>
																			</a>
																		</td>
																	</tr>
																))}
														</tbody>
													</table>


												</div>

											</div>

										) : (
											""
										)}
										<>
											<div className="d-flex justify-content-between">
												<div>Total Number Of Row {numberofPage}</div>
												<div class="dataTables_length" id="table_id_length">
													<label>
														Show{" "}
														<select
															name="table_id_length"
															aria-controls="table_id"
															class=""
															onChange={(e) => setPageSize(e.target.value)}
															value={PageSize}
														>
															<option value={10}>10</option>
															<option value={25}>25</option>
															<option value={50}>50</option>
															<option value={100}>100</option>
														</select>{" "}
														entries
													</label>
												</div>

												<Pagination
													className="pagination-bar"
													currentPage={currentPage}
													totalCount={numberofPage || 1}
													pageSize={PageSize}
													onPageChange={(page) => setCurrentPage(page)}
												/>
											</div>
										</>
									</div>
								</div>
							</div>
						</div>
					</section>
				</section>
			{/* </div> */}
		</>
	);
}

export function ReportHistory() {
	const [reportData, setReportData] = useState([]);
	const userInfo = JSON.parse(window.localStorage.getItem("user_info"));
	const [loader, setLoader] = useState(false);
	const history = useHistory();
	const location = useLocation();
	const reportType = location.state;

	const buttonArray = ["All GSM Consumer Details"];
	const ongetReportUserData = async () => {
		setLoader(true);
		const data = await getReportBytypeName(userInfo?.UNIQUE_ID, userInfo?.ROLE, "allgsmconsumerlist");
		if (data.success === true) {
			setReportData(data.data.data);
		} else {
			console.log("something want wrong");
			setLoader(false);
		}
	};

	useEffect(() => {
		ongetReportUserData().then(() =>
			setTimeout(function () {
				$("#table-1").DataTable({ pageLength: 50, order: [[0, "desc"]] });
				setLoader(false);
			}, 1100)
		);
	}, []);

	// const onDownloadFiles = async (name) => {
	// 	const myHeaders = new Headers();

	// 	const myRequest = new Request(ReportUrl + name, {
	// 		method: "GET",
	// 		headers: myHeaders,
	// 		mode: "cors",
	// 		cache: "default",
	// 	});
	// 	const data = await fetch(myRequest);
	// 	const blob = await data.blob();

	// 	if (data.status === 200) {
	// 		await fileDownload(blob, name);
	// 	}
	// };
	return (
		<>
			<div className="main-content" style={{ minHeight: "562px" }}>
				{loader ? (
					<div className="main-content">
						<div class="loader"></div>
					</div>
				) : (
					""
				)}
			</div>
		</>
	);
}
