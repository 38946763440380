
import React, { useEffect, useRef, useState } from 'react'
import { CSVLink } from 'react-csv'
import { Users } from 'react-feather'
import { Link, useHistory, useParams } from 'react-router-dom'
import { BootstrapTooltip } from '../../Commoncomponet/common'
import * as $ from "jquery";
import * as XLSX from 'xlsx';
import dt from "datatables.net-dt";
import jsPDF from "jspdf";
import { activeandinactive, getAccount, getCategoryNameByUniqId, getGa, getProfilNameByAccountId, getRolesById } from '../../Commoncomponet/Utils'
import { Modal } from 'antd'
import moment from 'moment'
$.fn.DataTable = dt;
export default function Ga() {
	const [GaData, setGaData] = useState([])
	const [filterrolesData, setfilterRolesData] = useState([])
	const [loader, setLoader] = useState(false)
	const [permision, setpermision] = useState({})
	const [Allpermision, setAllpermision] = useState({})
	const userInfo = JSON.parse(window.localStorage.getItem('user_info'))
	const [roleFilter, setRoleFilter] = useState({ name: '', account: '', status: '' })
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [AccounData, setAccountData] = useState([])
	const [profileName,setprofileName]=useState({})
	const [editFlage,setEditFlage]=useState(false)
	const history = useHistory()
	const params=useParams()
	const ongetRolesUserData = async () => {
		setLoader(true);
		$(".loader").fadeOut("slow");
		const data = await getGa({search:true,CATEGORY0:params.accountId})
		if (data.success === true) {
			setGaData(data.data.data)
			setfilterRolesData(data.data.data)
		} else {
			console.log('somthing want wrong')
			setLoader(false)
		}
	}
	const onHandaleRoles = async () => {
		const data = await getRolesById(userInfo?.ROLE)
		if (data.success === true) {
			setpermision(JSON.parse(data.data.data[0].ROLES_PERMISSIONS_CATEGORY1))
			const obj = data.data.data[0]
			const Roles = { ...obj, ROLES_PERMISSIONS_CATEGORY0: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY0), ROLES_PERMISSIONS_CATEGORY5: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY5), ROLES_PERMISSIONS_CATEGORY2: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY2), ROLES_PERMISSIONS_CATEGORY3: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY3), ROLES_PERMISSIONS_CATEGORY1: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY1), ROLES_PERMISSIONS_CATEGORY4: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY4) }
			setAllpermision(Roles)
		} else {
			console.log('something want wrong')
		}
	}
	const onHandaleSelection = async () => {
		const data = await getAccount()
		if (data.success === true) {
			setAccountData(data.data.data)
		} else {
			console.log('somthing want wrong')
		}
	}

	const ongetCategoryNameByUniqId = async () => {
		const data = await getCategoryNameByUniqId({CATEGORY0:params.accountId})
		if (data.success === true) {
			setprofileName(data.data.data)
		} else {
			console.log('somthing want wrong')
		}
	}
const [NameObj,setNameObj]=useState({})

	const ongetProfilNameByAccountId=async()=>{
		const data=await getProfilNameByAccountId({ID:params.accountId})
		if(data.success===true){
			setNameObj(JSON.parse(data?.data?.data[0]?.PROFILE_NAME||'{}'))
		}else{
			console.log('something want wrong')
		}
	}
	useEffect(() => {
		onHandaleRoles()
		ongetCategoryNameByUniqId()
		ongetProfilNameByAccountId()
		onHandaleSelection()
		ongetRolesUserData().then(() =>
			setTimeout(function () {
				$("#table-1").DataTable({ "pageLength": 50, order: [[0, 'desc']] });
				setLoader(false);

			}, 700)
		);
	}, []);


	const UpdateRolesUser = (item) => {
		history.push({ pathname: `/add-ga/${params.accountId}`, state: { ...item, CATEGORY1_edit: permision.CATEGORY1_edit, CATEGORY1_active: permision.CATEGORY1_active,Allpermision:Allpermision } })
	}
	const handleCancel = () => {
		setIsModalVisible(false);
	};

	const onhandleOk = () => {
		setIsModalVisible(false)
		if (roleFilter.name === '' && roleFilter.status === '' && roleFilter.account === '') {
			return setGaData(filterrolesData)
		}
		const filteData = filterrolesData && filterrolesData.filter((item) => (roleFilter.name !== '' ? item?.CATEGORY1_NAME?.includes(roleFilter.name) : []) && (roleFilter.account !== '' ? item?.CATEGORY1_ACCOUNT == roleFilter.account : []) && (roleFilter.status !== '' ? item?.CATEGORY1_STATUS == roleFilter.status : []))
		setGaData(filteData)
	}

	const csvLink = useRef();
	const [csvData, setCsvData] = useState([])

	const downloadReport = async (isCsv) => {
		if (GaData.length > 0) {
			const finalCsvData = await GaData.map((item) => ({ 'SRNO': item.SRNO, 'Unique ID': item.CATEGORY1_UNIQUE_ID, 'Created Time': moment(item.CATEGORY1_CREATED_AT).format('DD-MM-YYYY'), 'Created By': item.CATEGORY1_CREATED_BY, 'Account': item.ACCOUNT_NAME, 'GA': item.CATEGORY1_NAME, 'status': (item.CATEGORY1_STATUS === 1 ? 'Active' : 'InActive') }))
			setCsvData(finalCsvData)
			if (isCsv === true) {
				if (finalCsvData.length > 0) {
					csvLink.current.link.click();
				}
			} else {
				if (finalCsvData.length > 0) {
					const worksheet = XLSX.utils.json_to_sheet(finalCsvData);
					const workbook = XLSX.utils.book_new();
					XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
					XLSX.writeFile(workbook, `GA-data.xlsx`);
				}
			}
		}
	}

	const onSubmitName=async()=>{
		const data=await activeandinactive({tableName:'category0',colName:'PROFILE_NAME',value:`'${JSON.stringify(NameObj)}'`,matchId:'UNIQUE_ID'},params.accountId)
		if(data.success===true){
			ongetProfilNameByAccountId()
			setEditFlage(false)
		}else{
		}
	}

	const TableTrMaker=(item,id)=>{
		return <tr style={{ height: "4rem" }} className="trheigth hover:tw-bg-[#D8D8D8]" key={id} onMouseEnter={()=>{
			var element = document.getElementById(item?.SRNO+'GSM');
				  element.classList.add('within-icon');
				  element.classList.remove('display-icon');
							   }} onMouseLeave={()=>{
			var element = document.getElementById(item?.SRNO+'GSM');
				  element.classList.add('display-icon');
				  element.classList.remove('within-icon');
							   }}>
								   <td className="text-center GSM_Link">{item?.SRNO}<Link id={item?.SRNO+'GSM'} className='display-icon' to={`/circle/${item?.CATEGORY0}/${item?.CATEGORY1_UNIQUE_ID}`}><i className='fas fa-external-link-alt ' ></i></Link></td>
								   <td><b>Account</b>: {item?.ACCOUNT_NAME}</td>
								   <td>{item?.CATEGORY1_NAME}</td>
								   <td>
									   <div className={`badge ${item?.CATEGORY1_STATUS == 1 ? 'badge-success' : 'badge-danger'} badge-shadow text-white`}>{item?.CATEGORY1_STATUS == 1 ? 'Active' : 'Inactive'}</div>
								   </td>
								   <td className="text-center"><div class="btn btn-info text-white btnedit mr-2" onClick={() => (UpdateRolesUser(item))}><BootstrapTooltip title='edit'>
	<span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Edit" >
	   <i class="fas fa-edit  text-white"></i>
	</span></BootstrapTooltip>
 </div></td>
							   </tr>
	}

	return (
		<>
			<Modal title="Filter ga" visible={isModalVisible} footer="" onCancel={handleCancel}>
				<div class="form-row">
					<div class="form-group col-md-4">
						<label for="inputEmail4">Account:</label>
						<select class="form-control form-select" name="accountname" id="accountname" onChange={(e) => (setRoleFilter({ ...roleFilter, account: e.target.value }))} value={roleFilter?.account} >
							<option value="">Select Account</option>
							{AccounData && AccounData.map((item) => (Allpermision?.ROLES_PERMISSIONS_CATEGORY0?.CATEGORY0_view?.filter((items) => (items === item.UNIQUE_ID)).length > 0||userInfo?.ROLE==='ROLE1650614500708'?<option value={item?.UNIQUE_ID}>{item?.CATEGORY0}</option>:''))}
						</select>
					</div>
					<div class="form-group col-md-4">
						<label for="inputState">ga Name:</label>
						<input type="text" class="form-control" id="" onChange={(e) => (setRoleFilter({ ...roleFilter, name: e.target.value }))} value={roleFilter.name} name="FirstName" placeholder="Enter ga Name" />
					</div>
					<div class="form-group col-md-4">
						<label for="inputPassword4">ga Status:</label>
						<select
							id="inputState"
							class="form-select form-control"
							name="modem_name"
							onChange={(e) => (setRoleFilter({ ...roleFilter, status: e.target.value }))}
							value={roleFilter.status}
						>
							<option value="">Select Status</option>
							<option value={1}>Active</option>
							<option value={0}>InActive</option>
						</select>
					</div>
				</div>
				<div class="text-left mt-2">
					<input type="submit" class="btn btn-danger" value="Filter" name="filter_customer_form" onClick={onhandleOk} />
					<Link class="btn btn-light text-dark ml-2" to={`/ga`}>
						Reset
					</Link>
				</div>
			</Modal>
			<div className="main-content">
				{loader ? (
					<div className="main-content">
						<div class="loader"></div>
					</div>
				) : (
					""
				)}
				<nav aria-label="breadcrumb">
					<ol className="breadcrumb">
						<li className="breadcrumb-item">
							<Link to="/group-dashboard">
								<i className="fas fa-tachometer-alt"></i> Home
							</Link>
						</li>
						<li className="breadcrumb-item">
							<Link>
								<Users style={{ height: "16px" }} /> Profile
							</Link>
						</li>
						<li className="breadcrumb-item">
							<Link to='/account'>
								{profileName?.ACCOUNT_NAME}
							</Link>
						</li>
						<li className="breadcrumb-item">
							<Link>
							{NameObj?.CATEGORY1 || 'CATEGORY1'}
							</Link>
						</li>
					</ol>
				</nav>
				<section className="section">
					<div className="section-body">
						<div className="row">
							<div className="col-12">
								<div className="card">
									<div className="card-header d-flex" style={{justifyContent:'space-between'}}>
										<div className='d-flex'>{editFlage===false?<><h4>{NameObj?.CATEGORY1 || 'CATEGORY1'}</h4>{permision?.EDIT_NAME===true||userInfo?.ROLE==="ROLE1650614500708"?<div class="btn btn-info text-white btnedit mr-2" onClick={()=>(setEditFlage(!editFlage))}><BootstrapTooltip title='edit'>
                                 <span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Edit" >
                                    <i class="fas fa-edit  text-white"></i>
                                 </span></BootstrapTooltip>
                              </div>:''}<BootstrapTooltip title={`Table Name is category1`} placement='top'><span><i className='fas fa-info-circle info_circle'></i></span></BootstrapTooltip></>:<div class="form-row "><div class="col-md-12 d-flex">
                                    <input type="text" class="form-control" name="account_name" placeholder="Enter Account Name" required="" onChange={(e)=>(setNameObj({...NameObj,CATEGORY1:e.target.value}))} value={NameObj?.CATEGORY1}/>
									<a class={`btn btn-danger text-dark`} onClick={onSubmitName}>submit</a>
                                 </div></div>}</div>
										<div className="card-header-action">
											{permision.CATEGORY1_create === true||userInfo?.ROLE==="ROLE1650614500708" ? <Link to={`/add-ga/${params.accountId}`} className="btn btn-info text-white mr-2">
												<BootstrapTooltip title="Add">
													<span>
														<i
															className="fas fa-plus text-white"
															style={{ fontSize: "16px", paddingLeft: "5px", paddingRight: "5px" }}
														></i>
													</span>
												</BootstrapTooltip>
											</Link> : ''}
											<button type="button" className="btn btn-info mr-2" onClick={() => (setIsModalVisible(true))}>
												<BootstrapTooltip title="Filter">
													<span>
														<i className="fa fa-filter text-white"></i>
													</span>
												</BootstrapTooltip>
											</button>
											<div className="dropdown d-inline mr-2">
												{permision.CATEGORY1_export === true||userInfo?.ROLE==="ROLE1650614500708" ? <button
													className="btn btn-info dropdown-toggle mr-2"
													type="button"
													id="dropdownMenuButton3"
													data-toggle="dropdown"
													aria-haspopup="true"
													aria-expanded="false"
													data-placement="bottom"
													title="Download"
												>
													<i className="fa fa-download mr-1"></i>
												</button> : ''}
												<div className="dropdown-menu">
												<a className="dropdown-item" >
														PDF
													</a>
													<a onClick={() => downloadReport(false)} className="dropdown-item" >
														Excel
													</a>
													<a onClick={() => downloadReport(true)} className="dropdown-item">
														CSV
													</a>
													<CSVLink data={csvData} filename={"GA-data.csv"} ref={csvLink} />
												</div>
											</div>
										</div>
									</div>
									<div className="card-body">
										<div className="table-responsive">
											<table className="table border stripe" id="table-1">
												<thead>
													<tr>
														<th className="text-center !tw-font-semibold" style={{ maxWidth: "100px" }}>Unique No</th>
														<th className='!tw-font-semibold'>Parent</th>
														<th className='!tw-font-semibold'>GA Name</th>
														<th className='!tw-font-semibold' style={{ maxWidth: "100px" }}>Status</th>
														<th className='!tw-font-semibold text-center'>Edit</th>
													</tr>
												</thead>
												<tbody>
													{GaData &&
														GaData.map((item, id) => (
															userInfo?.ROLE==='ROLE1650614500708'?<tr style={{ height: "4rem" }} className="trheigth hover:tw-bg-[#D8D8D8]" key={id} onMouseEnter={()=>{
 										var element = document.getElementById(item?.SRNO+'GSM');
   											element.classList.add('within-icon');
   											element.classList.remove('display-icon');
															}} onMouseLeave={()=>{
 										var element = document.getElementById(item?.SRNO+'GSM');
   											element.classList.add('display-icon');
   											element.classList.remove('within-icon');
															}} >
																<td className="text-center GSM_Link">{item?.SRNO}<Link id={item?.SRNO+'GSM'} className='display-icon' to={`/circle/${item?.CATEGORY0}/${item?.CATEGORY1_UNIQUE_ID}`}><i className='fas fa-external-link-alt ' ></i></Link></td>
																<td><b>Account</b>: {item.ACCOUNT_NAME}<br /></td>
																<td>{item?.CATEGORY1_NAME}</td>
																<td>
																	<div className={`badge ${item?.CATEGORY1_STATUS == 1 ? 'badge-success' : 'badge-danger'} badge-shadow text-white`}>{item?.CATEGORY1_STATUS == 1 ? 'Active' : 'Inactive'}</div>
																</td>
																<td className="text-center"><div class="btn btn-info text-white btnedit mr-2" onClick={() => (UpdateRolesUser(item))}><BootstrapTooltip title='edit'>
                                 <span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Edit" >
                                    <i class="fas fa-edit  text-white"></i>
                                 </span></BootstrapTooltip>
                              </div></td>
															</tr>:	permision?.CATEGORY1_view.length>0?permision?.CATEGORY1_view?.filter((items) => (items === item.CATEGORY1_UNIQUE_ID)).length > 0?(item?.CATEGORY0?.includes(params.accountId)) ?  TableTrMaker(item,id):'': '':TableTrMaker(item,id)
														))}
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		</>
	)
}
